import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
  transform(value: string): string {
    const date: Date = new Date(value);

    if (value != null) {
      return moment.utc(date).format('DD/MM/YYYY HH:mm:ss');
    } else {
      return '';
    }
  }
}
