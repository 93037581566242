import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AddressTypeaheadOptionField, GeocodeAddressCandidateType, GeocodeResultCandidateType, GeocodingResultType, ReverseGeocodingResultType } from '@shared/services/location/location.types';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, Observable, Subject, Subscription, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeltReportService } from '../felt.report.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AnimationCommon, GeolocationApiService } from 'flying-hellfish-common';
import { ControlContainer, NgForm } from '@angular/forms';
import { AddressLocation } from '../felt.report';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'ga-felt-report-location',
  templateUrl: './felt.report.location.component.html',
  styleUrl: './felt.report.location.component.css',
  animations: [
    AnimationCommon.animationCommonEnter300
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class FeltReportLocationComponent implements OnDestroy, OnInit {
  @Input({ required: true }) submitBehaviorSubject: BehaviorSubject<boolean>;
  @Output() addressLocationEmitter: Subject<AddressLocation> = new Subject<AddressLocation>;
  @Output() showErrorEmitter: Subject<boolean> = new Subject<boolean>;

  deviceLocation: AddressLocation;
  addressLocation: AddressLocation;
  geocodedAddressLocation: AddressLocation;
  reverseGeocode: ReverseGeocodingResultType;
  geocodedAddresses: GeocodingResultType;
  typeaheadObservable: Observable<GeocodeAddressCandidateType[]> = new Observable<GeocodeAddressCandidateType[]>();
  typeaheadInput: string;
  addressSuggestions: GeocodeAddressCandidateType[];
  // This is the name of the field in the GeocodeAddressCandidateType that the typeahead should use.
  addressField: AddressTypeaheadOptionField = 'a';

  subscriptions: Subscription = new Subscription();

  constructor(private feltReportService: FeltReportService, private geolocationApiService: GeolocationApiService,) {
    this.addressLocation = new AddressLocation;
    this.typeaheadObservable = this.getTypeaheadOptionsAsObservable();
    this.setDeviceLocation();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.submitBehaviorSubject.subscribe((value) => {
      if (value) {
        this.onSubmit();
      }
    }));
  }

  // Returns an observable that fires when the user types in the address input, gets new typeahead candidates
  getTypeaheadOptionsAsObservable(): Observable<GeocodeAddressCandidateType[]> {
    return new Observable<string>(obs => {
      obs.next(this.typeaheadInput);
    }).pipe(
      filter((token: string) => Boolean(token) && token.length > 3),
      map(token => token.trim().replace(/[^a-zA-Z0-9]/g, ' ')),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token) => this.feltReportService.getAddressSuggestions(token)),
      tap(response => this.addressSuggestions = response)
    );
  }

  // Fired when the user selects an address from the typeahead dropdown
  onClickTypeaheadOption(option: TypeaheadMatch): void {
    this.fillAddressFields(option.value);
  }

  // Break the address string into address, suburb, etc to populate form fields
  fillAddressFields(addressString: string): void {
    if (addressString === undefined || addressString.length === 0 || addressString.split(',').length !== 2) {
      throw new Error('Address string is malformed');
    }
    const splitAddress: string[] = addressString.split(', ');
    const address: string = splitAddress[0];
    const splitPlace: string[] = splitAddress[1].split(' ');
    const postcode: string = splitPlace.pop();
    const state: string = splitPlace.pop();
    const suburb: string = splitPlace.join(' ');

    if (this.addressSuggestions.find((suggestion: GeocodeAddressCandidateType) =>
      suggestion.a === addressString)) {
      const location: string = this.addressSuggestions.find((suggestion: GeocodeAddressCandidateType) =>
        suggestion.a === addressString)?.l;
      this.addressLocation.address = address;
      this.addressLocation.suburb = suburb;
      this.addressLocation.state = state;
      this.addressLocation.postcode = postcode;
      this.typeaheadInput = address;
      this.geocodedAddressLocation = cloneDeep(this.addressLocation);
      this.geocodedAddressLocation.location = { longitude: parseFloat(location.split(',')[0]), latitude: parseFloat(location.split(',')[1]) };
    }
  }

  // Get the users device location and set update the form.
  setDeviceLocation(): void {
    this.geolocationApiService.getUserLocation()
      .then((position) => {
        if (position.coords) {
          this.setPosition(position);
        }
      })
      .catch((e) => {
        /**
         * Since this is only used to pre-fill the form and the user can manually fill it,
         * don't show an error message to the user
         */
      });
  }

  // Sets the users current location
  setPosition(position: GeolocationPosition): void {
    this.deviceLocation = new AddressLocation();
    this.deviceLocation.location = { longitude: position.coords.longitude, latitude: position.coords.latitude };
    this.reverseGeocodeDeviceLocation();
  }

  // Call the service to determine the user's location
  reverseGeocodeDeviceLocation(): void {
    // Auto populate all the address fields if the service returns them
    this.subscriptions.add(this.feltReportService.reverseGeocode(this.deviceLocation.location).subscribe({
      next: (data) => {
        if (data) {
          this.reverseGeocode = data;
        }
      },
      error: (error) => console.error(error),
      complete: () => this.reverseGeocodeDeviceLocationComplete()
    }));
  }

  // Map the geocoded address to the appropriate variables
  reverseGeocodeDeviceLocationComplete(): void {
    if (this.reverseGeocode) {
      if (this.reverseGeocode.address !== '') {
        this.deviceLocation.address = this.reverseGeocode.address;
        this.addressLocation.address = this.deviceLocation.address;
      }

      if (this.reverseGeocode.state !== '') {
        this.deviceLocation.state = this.feltReportService.getStateFromAbbreviation(this.reverseGeocode.state) !== '' ? this.reverseGeocode.state :
          this.feltReportService.getStateAbbreviation(this.reverseGeocode.state);
        this.addressLocation.state = this.deviceLocation.state;
      }

      if (this.reverseGeocode.suburb !== '') {
        this.deviceLocation.suburb = this.reverseGeocode.suburb;
        this.addressLocation.suburb = this.deviceLocation.suburb;
      }

      if (this.reverseGeocode.postcode !== '') {
        this.deviceLocation.postcode = this.reverseGeocode.postcode;
        this.addressLocation.postcode = this.deviceLocation.postcode;
      }
    }

    this.typeaheadInput = this.addressLocation.address;
  }

  // Remove leading and trailing whitespace from input.
  trim(event: string): string {
    if (event) {
      event = event.trim();
    }
    return event;
  }

  // Geocode the addressLocation and output it to the parent component.
  onSubmit(): void {
    if (!this.addressLocation.postcode || this.addressLocation.postcode === '') {
      this.addressLocation.postcode = null;
    }
    // If a user has not allowed their location or the auto populated address has been modified, latitude/longitude needs to be found
    if (this.isLocationNotSet()) {
      if (this.hasAddressNotChangedFromDevice()) {
        this.addressLocation.location = this.deviceLocation.location;
        this.addressLocationEmitter.next(this.addressLocation);
      } else if (this.hasAddressBeenGeocoded()) {
        this.addressLocation.location = this.geocodedAddressLocation.location;
        this.addressLocationEmitter.next(this.addressLocation);
      } else {
        this.getGeocodedAddress();
      }
    } else {
      this.addressLocationEmitter.next(this.addressLocation);
    }
  }

  // Check to see if the deviceLocation matches the addressLocation
  hasAddressNotChangedFromDevice(): boolean {
    return (this.addressLocation.address === this.deviceLocation?.address) &&
      (this.addressLocation.suburb === this.deviceLocation?.suburb) && (this.addressLocation.state === this.deviceLocation?.state)
      && (this.addressLocation.postcode === this.deviceLocation?.postcode);
  }

  // Check to see if the locationAddress matches the geocoded address
  hasAddressBeenGeocoded(): boolean {
    return (this.geocodedAddressLocation?.address === this.addressLocation.address) &&
      (this.geocodedAddressLocation?.suburb === this.addressLocation.suburb) && (this.geocodedAddressLocation?.state === this.addressLocation.state)
      && (this.geocodedAddressLocation?.postcode === this.addressLocation.postcode);
  }

  // Geocode the addressLocation
  getGeocodedAddress(): void {
    this.subscriptions.add(this.feltReportService.geocodeAddress(this.addressLocation).subscribe({
      next: (data) => {
        this.geocodedAddresses = data;
      },
      error: (error) => {
        console.error(error);
        this.showErrorEmitter.next(true);
      },
      complete: () => {
        this.geocodeAddressComplete();
      }
    }));
  }

  // Get the coordinates based on the given address
  geocodeAddressComplete(): void {
    // First match the suburb, if no match, match the state if still no match then use the first location returned by geocoder
    if (this.geocodedAddresses.candidates.length > 1) {
      for (const candidate of this.geocodedAddresses.candidates) {
        if (candidate.address.toLowerCase().includes(this.addressLocation.suburb.toLowerCase())) {
          this.addressLocation.location = { longitude: candidate.location.x, latitude: candidate.location.y };
          break;
        }
      }
    }
    if (this.isLocationNotSet() && this.geocodedAddresses.candidates.length > 1) {
      for (const candidate of this.geocodedAddresses.candidates) {
        if (this.isStateInCandidate(candidate)) {
          this.addressLocation.location = { longitude: candidate.location.x, latitude: candidate.location.y };
          break;
        }
      }
    }
    if (this.isLocationNotSet() && this.geocodedAddresses.candidates.length === 1) {
      this.addressLocation.location = { longitude: this.geocodedAddresses.candidates[0].location.x, latitude: this.geocodedAddresses.candidates[0].location.y };
    }

    this.geocodedAddressLocation = cloneDeep(this.addressLocation);
    this.addressLocationEmitter.next(this.addressLocation);
  }

  // Check if the felt report location is set.
  isLocationNotSet(): boolean {
    return !(this.addressLocation.location?.longitude && this.addressLocation.location?.latitude);
  }

  // Check if the candidate's address contains the state or territory
  isStateInCandidate(candidate: GeocodeResultCandidateType): boolean {
    return (candidate.address.includes(this.addressLocation.state)
      || candidate.address.includes(this.feltReportService.getStateFromAbbreviation(this.addressLocation.state)));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
