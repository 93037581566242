import { Observable, Subscription } from 'rxjs';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FeatureJson } from '../../types/feature.json.type';
import { AppConstants } from '../../app.constants';
import * as moment from 'moment';
import { MapService } from '../../map/map.service';
import { AutoRefreshCommonService, ENVIRONMENT } from 'flying-hellfish-common';
import { TextValueType } from '../../types/recent.quake.type';

@Injectable()
export class RecentQuakesService implements OnDestroy {
  private geoserverWfsUrl: string = this.environment.serviceUrls.earthQuakeSummarySevenDaysWFS;
  private searchURL: string = this.environment.serviceUrls.earthQuakeSummaryWFS;
  features: any[] = [];
  showProgressBar: boolean = false;
  errorMessage: string = null;
  selectedPeriod: TextValueType = this.environment.recentEarthquakes.periodList[1];
  selectedMagnitude: TextValueType = this.environment.recentEarthquakes.magnitudeList[0];
  selectedRegion: string = this.environment.recentEarthquakes.regionList[0];
  subscriptions: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private mapService: MapService,
    private autoRefreshCommonService: AutoRefreshCommonService,
    @Inject(ENVIRONMENT) private environment: any
  ) {
  }

  getRecentQuakes(): Observable<FeatureJson> {
    return this.http.get<FeatureJson>(this.geoserverWfsUrl);
  }

  // Get features from Geoserver using WFS
  getFeatures(appendToUrl: string): Observable<FeatureJson> {
    return this.http.get<FeatureJson>(this.searchURL + appendToUrl);
  }

  // Get the features based on the criteria
  search(): void {
    this.features = [];
    this.showProgressBar = true;
    const criteria: string = this.criteriaBuilder();

    // Get all the features with a WFS query
    this.subscriptions.add(this.getFeatures(criteria)
      .subscribe({
        next: response => {
          this.features = response.features;
          this.showProgressBar = false;
          this.autoRefreshCommonService.removeAutoRefresh(AppConstants.RECENT_EARTHQUAKES);
          this.autoRefreshCommonService.addAutoRefresh(AppConstants.RECENT_EARTHQUAKES, () => {
            const autoRefreshCriteria: string = this.criteriaBuilder();

            this.mapService.createRecentQuakesLayer(this.environment.serviceUrls.earthQuakeSummaryWFS + autoRefreshCriteria);
          }, () => {
            if (this.mapService.recentEarthquakesVectorLayer) {
              return this.mapService.recentEarthquakesVectorLayer.getVisible();
            }
          });
        },
        error: error => this.logError(error)
      }));
  }

  // Builds the search criteria
  criteriaBuilder(): string {
    let criteria: string = '&CQL_FILTER=display_flag=\'Y\' AND ';

    // Only apply the magnitude filter for magnitudes under 10
    if (this.selectedMagnitude.value < 10) {
      criteria += 'preferred_magnitude>=' + this.selectedMagnitude.value + ' AND ';
    }

    // Calculate the date range
    criteria += 'origin_time BETWEEN ' + moment().utc().subtract(this.selectedPeriod.value, 'days').format('YYYY-MM-DD[T]HH:mm[:00Z]') + ' AND ' + moment().utc().format('YYYY-MM-DD[T]HH:mm[:00Z]');

    // Only apply the located_in_australia filter when selectedRegion is 'Australia'
    if (this.selectedRegion === 'Australia') {
      criteria += ' AND located_in_australia=\'Y\'';
    }

    return criteria + '&sortBy=origin_time D';
  }

  // Display error and hide progress bar
  logError(error: any): void {
    this.errorMessage = error.message;
    this.showProgressBar = false;
    console.error(error);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
