import { Component } from '@angular/core';

@Component({
  selector: 'ga-toolbar-legend',
  templateUrl: 'legend.component.html',
  styleUrls: ['legend.component.css'],
  preserveWhitespaces: true
})
export class LegendComponent {
}
