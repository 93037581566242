import { Component, Inject } from '@angular/core';
import { MapService } from '../../map/map.service';
import { AnimationCommon, DeviceCommonService, LayerConfigCommonType } from 'flying-hellfish-common';
import { LayersService } from './layer.service';
import { AppConstants } from '../../app.constants';
import { LayerConstants } from './layer.constants';

@Component({
  selector: 'ga-toolbar-layers',
  templateUrl: 'layers.component.html',
  styleUrls: ['layers.component.css'],
  preserveWhitespaces: true,
  animations: [
    AnimationCommon.animationCommonEnter300,
    AnimationCommon.animationCommonEnter300Leave300
  ]
})
export class LayersComponent {
  helpIcon: string = AppConstants.DEFAULT_HELP_ICON;
  showSeismicInfo: boolean = false;
  constants: typeof LayerConstants = LayerConstants;

  constructor(private mapService: MapService, public layerService: LayersService, public deviceCommonService: DeviceCommonService,
              @Inject('Window') private window: Window) {
  }

  // Change the selected base layer
  changeBaseLayer(baseLayer: LayerConfigCommonType): void {
    this.layerService.setBaseLayer(baseLayer.id);
    this.mapService.changeBaseLayer(baseLayer.id);
  }

  // Change the layers opacity when the slider is moved
  changeOpacity(layerKey: string, target: EventTarget): void {
    this.layerService.changeLayerOpacity(layerKey, +(<HTMLInputElement>target).value);
  }

  // Open the legend in a new tab
  launchLegend(layerKey: string): void {
    this.window.open(this.layerService.layers.get(layerKey).url + '/legend', '_blank');
  }
}
