<div class="emergency-info-container">
  <h4>Emergency Info</h4>
  <hr>
  <h5 class="emergency">Urgent emergency assistance in Australia</h5>
  <p>Phone: 000 (Triple Zero)</p>

  <h5 id="otherAssistance">Other assistance</h5>
  <p>State Emergency Service<br>
    Phone: <a href="tel:132 500">132 500</a></p>

  <h5>Tsunami information</h5>
  <p>Joint Australian Tsunami Warning Centre<br>
    Phone: <a href="tel:1300 TSUNAMI">1300 TSUNAMI</a> or <a href="tel:1300 878 6264">1300 878 6264</a><br>
    Web: <a href="http://www.bom.gov.au/tsunami/" target="_blank" rel="noopener">Visit JATWC Site</a></p>

  <h5>Check on someone overseas</h5>
  <p><a href="https://www.dfat.gov.au/contact-us" target="_blank" rel="noopener">Department of Foreign Affairs and Trade</a><br>
    Within Australia: <a href="tel:1300 555 135">1300 555 135</a><br>
    From overseas: <a href="tel:+61 2 6261 3305">+61 2 6261 3305</a></p>

  <h5>Earthquake information</h5>
  <p>Geoscience Australia
    Within Australia: <a href="tel:1800 655 739">1800 655 739</a><br>
    From overseas: <a href="tel:+61 2 6249 9699">+61 2 6249 9699</a><br>
    Email: <a href="mailto:earthquakes@ga.gov.au">earthquakes&#64;ga.gov.au</a></p>

  <hr>

  <h4>About Earthquakes</h4>
  <p>Geoscience Australia monitors, analyses and reports on significant earthquakes to alert the Australian Government,
    State and Territory Governments and the public about earthquakes in Australia and overseas.<br><br>The earthquake
    information
    is used to inform activities in Australia’s earthquake hazard program and tsunami warnings issued by the Joint
    Australian Tsunami Warning Centre.<br><br>This website can be used to find information on recent earthquakes as
    monitored by
    Geoscience Australia, search the earthquake catalogue, submit a report about an earthquake you have felt, and
    subscribe to notifications about earthquakes Geoscience Australia has analysed.</p>

  Further information regarding Geoscience Australia’s earthquake activities can be found here: <a
  href="https://www.ga.gov.au/scientific-topics/community-safety/earthquake" target="_blank" rel=noopener>Earthquake Information</a>
</div>
