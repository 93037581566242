import { Observable } from 'rxjs';
import { GeocodingResultType } from '@shared/services/location/location.types';

export abstract class GeocodingProvider {
  /**
   * Geocode location text to longitude/latitude coordinates.
   *
   * @param location - the location to geocode
   */
  abstract locationTextToPosition(location: string): Observable<GeocodingResultType>;
}
