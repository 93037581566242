import { Component, Inject, NgZone, OnDestroy } from '@angular/core';
import { MapService } from '../../map/map.service';
import { AnimationCommon, BoundsCommon, DeviceCommonService, DrawTypeType, ENVIRONMENT, GeometryCommonService, PagerCommonColumn, PagerCommonColumnControlType, SidePanelCommonService } from 'flying-hellfish-common';
import { Observable, Subscription } from 'rxjs';
import { SearchService } from './search.service';
import { SearchCriteria } from './search.criteria';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as FileSaver from 'file-saver';
import { ToolConstants } from '../../shared/tool-constants';
import { Coordinate } from 'ol/coordinate';
import { FeatureJson } from '../../types/feature.json.type';
import { PagerType } from '../../types/pager.type';
import { DropDownOptionType } from '../../types/dropdown.option.type';
import { RecentQuakeType } from '../../types/recent.quake.type';
import { AppConstants } from '../../app.constants';

@Component({
  selector: 'ga-toolbar-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.css'],
  preserveWhitespaces: true,
  animations: [
    AnimationCommon.animationCommonEnter500
  ]
})
export class SearchComponent implements OnDestroy {
  helpIcon: string = AppConstants.DEFAULT_HELP_ICON;
  subscriptions: Subscription = new Subscription();
  searchCriteria: SearchCriteria;
  drawType: DrawTypeType = 'Box';
  showResults: boolean = false;
  showCriteria: boolean = true;
  dateOptions: any;
  currentPage: number = 1;
  startIndex: number;
  maxResultsPerPage: number = 10;
  features: any[] = [];
  searchResultsCount: number = 0;
  showSearchProgressBar: boolean = false;
  showExportProgressBar: boolean = false;
  drawExtent: BoundsCommon[] = null;
  drawCoordinates: any[] = null;
  drawActive: boolean = false;
  drawInteractionActive: boolean = false;
  drawRadius: number = 0;
  drawBoundsText: string = null;
  selectedQuakeDetail: any = null;
  showQuakeDetail: boolean = false;
  recentQuakes: any[] = [];
  recentQuakesFormatted: any[] = [];
  recentQuakesAustraliaFormatted: any[] = [];
  recentQuakesActive: boolean = true;
  selectedRecentQuake: any;
  selectedRecentQuakeExtent: Coordinate[] = null;
  selectedRecentQuakeRadius: number;
  recentQuakesRadius: number[] = [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200];
  resultsPerPageList: number[] = [10, 50, 100, 250, 500, 1000];
  statesList: string[] = ['Please select', 'New South Wales', 'Northern Territory', 'Queensland', 'South Australia', 'Tasmania', 'Victoria', 'Western Australia'];
  selectedState: string = 'Please select';
  showDrawError: boolean = false;
  showProgressBar: boolean = false;
  errorMessage: string = null;
  getResultsSubscription: Subscription;

  radius$: Observable<number> = this.mapService.onRadiusChange.asObservable();

  resultsTableColumns: PagerCommonColumn[] = [
    {
      propertyKeys: ['properties', 'preferred_magnitude'],
      displayText: 'Magnitude',
      controlType: PagerCommonColumnControlType.FormattedText,
      formatterFunction: this.formatMagnitude
    },
    {
      propertyKeys: ['properties', 'origin_time'],
      displayText: 'UTC',
      controlType: PagerCommonColumnControlType.FormattedText,
      formatterFunction: this.formatDate
    },
    {
      propertyKeys: ['properties', 'latitude'],
      displayText: 'Latitude',
      controlType: PagerCommonColumnControlType.FormattedText,
      formatterFunction: this.formatCoordinates
    },
    {
      propertyKeys: ['properties', 'longitude'],
      displayText: 'Longitude',
      controlType: PagerCommonColumnControlType.FormattedText,
      formatterFunction: this.formatCoordinates
    },
    {
      propertyKeys: ['properties', 'depth'],
      displayText: 'Depth(Km)',
      controlType: PagerCommonColumnControlType.FormattedText,
      formatterFunction: this.formatDepth
    },
    {
      propertyKeys: ['properties', 'description'],
      displayText: 'Location'
    }
  ];

  constructor(
    private sidePanelCommonService: SidePanelCommonService,
    private mapService: MapService,
    public deviceCommonService: DeviceCommonService,
    private searchService: SearchService,
    private geometryCommonService: GeometryCommonService,
    private zone: NgZone,
    @Inject(ENVIRONMENT) private environment: any
  ) {
    // Set the date format
    this.dateOptions = { format: 'DD/MM/YYYY HH:mm' };

    // Initialise the search criteria class
    this.searchCriteria = new SearchCriteria(0.0, 9.9, moment(), moment(), 'Australia');
    this.setInitialDates();

    // Set the initial option for the drop down
    const option: DropDownOptionType = { text: 'Please select', latitude: '', longitude: '' };
    this.recentQuakesFormatted.push(option);
    this.recentQuakesAustraliaFormatted.push(option);
    this.selectedRecentQuake = option;

    // Setup the Polygon layer for Australia
    this.mapService.setAustraliaPolygon();

    // If the last active tool was Search then show the recent earthquakes layer
    this.subscriptions.add(this.sidePanelCommonService.rightMenuEmitter.subscribe((data) => {
      if (data.activeToolId === 'search') {
        mapService.showRecentEarthquakesLayer('Search', false);
      } else if (data.lastActiveToolId === 'search' || data.forceClear) {
        mapService.showRecentEarthquakesLayer('Search', true);
        this.reset();
      }

      // Get the latest list of earthquakes when the panel opens
      if (data.activeToolId === 'search') {
        this.initialiseRecentQuakes();
      }

      this.selectedRecentQuakeRadius = 100;
    }));

    // A user has clicked a search result on the map, so display the details
    this.subscriptions.add(mapService.searchFeatureEmitter.subscribe(
      feature => {
        if (this.features.length > 0) {
          for (let i: number = 0; i < this.features.length; i++) {
            if (this.features[i].properties.earthquake_id === feature.getProperties().earthquake_id) {
              this.showQuakeDetails(this.features[i]);
              break;
            }
          }
        }
      }
    ));
  }

  initialiseRecentQuakes(): void {
    this.subscriptions.add(this.searchService.getRecentQuakes()
      .subscribe({
        next: data => this.recentQuakes = data.features,
        error: error => console.log(error),
        complete: () => this.recentQuakesFeaturesReturned()
      }));
  }

  // Recent quakes service has returned
  recentQuakesFeaturesReturned(): void {
    // Reset the dropdown lists
    this.recentQuakesFormatted.splice(1);
    this.recentQuakesAustraliaFormatted.splice(1);

    for (let i: number = 0; i < this.recentQuakes.length; i++) {
      const content: string = 'Magnitude ' + this.recentQuakes[i].properties.preferred_magnitude.toFixed(1) + ' ' + this.recentQuakes[i].properties.description + ' ' +
        moment.utc(this.recentQuakes[i].properties.epicentral_time, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD/MM/YYYY HH:mm').toString();

      const recentQuake: RecentQuakeType = {
        text: content,
        latitude: this.recentQuakes[i].properties.latitude,
        longitude: this.recentQuakes[i].properties.longitude
      };
      this.recentQuakesFormatted.push(recentQuake);
      if (this.recentQuakes[i].properties.located_in_australia === 'Y') {
        this.recentQuakesAustraliaFormatted.push(recentQuake);
      }
    }

    this.recentQuakesActive = false;
  }

  // Display the selected recent quake radius on the map
  showRecentQuakeRadius(feature: any): void {
    this.selectedRecentQuake = feature;

    if (this.selectedRecentQuake.text !== 'Please select') {
      if (!this.deviceCommonService.isMobile()) {
        this.resetDraw();
        this.mapService.removeDrawExtentLayer(ToolConstants.SEARCH);
        this.mapService.flyToMapPosition(this.selectedRecentQuake.latitude, this.selectedRecentQuake.longitude, 7, 3000);
        this.mapService.removeStateLayer(ToolConstants.SEARCH);
      }

      this.selectedRecentQuakeExtent = this.mapService.addRecentEarthquakesSearchLayer(this.selectedRecentQuake.latitude, this.selectedRecentQuake.longitude, this.selectedRecentQuakeRadius);
      this.selectedState = 'Please select';
    } else {
      this.mapService.removeRecentEarthquakesSearchLayer();
    }
  }

  // Sets the date when the dateFrom date picker is selected
  dateFromChange(date: Moment): void {
    this.searchCriteria.dateFrom = date?.isValid() ? date : moment();
    this.searchCriteria.dateFromString = this.searchCriteria.dateFrom.format(SearchCriteria.DATE_STRING_FORMAT);
  }

  // Sets the date when the dateTo date picker is selected
  dateToChange(date: Moment): void {
    this.searchCriteria.dateTo = date?.isValid() ? date : moment();
    this.searchCriteria.dateToString = this.searchCriteria.dateTo.format(SearchCriteria.DATE_STRING_FORMAT);
  }

  // Update the from date, based on the entered string.
  onDateFromStringChange(date: string): void {
    const momentDate: moment.Moment = moment(date, SearchCriteria.DATE_STRING_FORMAT, true);
    if (momentDate.isValid()) {
      this.searchCriteria.dateFrom = momentDate;
    }
  }

  // Update the to date, based on the entered string.
  onDateToStringChange(date: string): void {
    const momentDate: moment.Moment = moment(date, SearchCriteria.DATE_STRING_FORMAT, true);
    if (momentDate.isValid()) {
      this.searchCriteria.dateTo = momentDate;
    }
  }

  // Search button was fired
  search(): void {
    this.currentPage = 1;
    this.executeSearch();

    // If a user has selected draw but has not drawn on the map reset the draw object
    if (this.drawInteractionActive) {
      this.resetDraw();
    }
  }

  // Perform the search
  executeSearch(): void {
    this.showSearchProgressBar = true;

    // Calculate the start index
    if (this.currentPage === 1) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.currentPage - 1) * this.maxResultsPerPage;
    }

    // Build the criteria
    const criteria: string = this.criteriaBuilder();
    const featureUrl: string = criteria + '&count=' + this.maxResultsPerPage + '&startIndex=' +
      (this.startIndex) + '&sortBy=origin_time D';

    const featureCountUrl: string = criteria + '&version=1.1.0&resultType=hits';

    // Get a count only for the entire search results to setup the pager
    this.subscriptions.add(this.searchService.getFeatureCount(featureCountUrl)
      .subscribe({
        next: (data) => {
          let featuresArray: string[] = [];
          featuresArray = data.split(' ');

          for (const feature of featuresArray) {
            if (feature.indexOf('numberOfFeatures') !== -1) {
              this.searchResultsCount = Number(feature.split('=')[1].replace(/['"]+/g, ''));
            }
          }

          this.mapService.removeSearchFeatureLayer();

          if (this.searchResultsCount > 0) {
            // Get all the features with a WFS query
            this.getResultsSubscription = this.searchService.getFeatures(featureUrl)
              .subscribe({
                next: (response) => {
                  for (const feature of response.features) {
                    // Round magnitudes to 1 decimal place so the displayed point size matches the formatted magnitude values displayed in the form
                    feature.properties.preferred_magnitude = this.roundNumber(feature.properties.preferred_magnitude);
                    feature.properties.mb = this.roundNumber(feature.properties.mb);
                    feature.properties.md = this.roundNumber(feature.properties.md);
                    feature.properties.ml = this.roundNumber(feature.properties.ml, 5);
                    feature.properties.ms = this.roundNumber(feature.properties.ms);
                    feature.properties.mw = this.roundNumber(feature.properties.mw);
                    feature.properties.mwmwp = this.roundNumber(feature.properties.mwmwp);
                    feature.properties.mwp = this.roundNumber(feature.properties.mwp);
                    feature.properties.mww = this.roundNumber(feature.properties.mww);
                    this.features.push(feature);
                  }
                  this.mapService.addSearchFeatureLayer(this.features);
                  this.showResults = true;
                  this.showCriteria = false;
                  this.showSearchProgressBar = false;

                  // Fit the features on the map minus the panel for desktop and tablets
                  if (this.searchCriteria.location === 'Australia') {
                    if (!this.deviceCommonService.isMobile()) {
                      this.mapService.zoomToFeaturesWithPadding(this.features, 1500, [100, this.sidePanelCommonService.openPanelWidth, 100, 50]);
                    }
                  }
                },
                error: (error) => this.handleSearchError(error)
              });
          } else {
            this.showResults = true;
            this.showCriteria = false;
            this.showSearchProgressBar = false;
            this.searchResultsCount = 0;
            this.currentPage = 1;
          }
        },
        error: (error) => this.handleSearchError(error)
      }));
  }

  /**
   * Handle error during earthquake search.
   *
   * @param error - the error to handle
   */
  private handleSearchError(error: any): void {
    console.error('Error retrieving features');
    console.error(error);

    this.errorMessage = `Failed to retrieve search results. Please try again and if the problem persists contact <a href="mailto:${this.environment.urgentFeedbackEmail}?Subject=${this.environment.feedbackEmailSubject} - Search Error">${this.environment.urgentFeedbackEmail}</a>.`;
    this.showResults = true;
    this.showCriteria = false;
    this.showSearchProgressBar = false;
    this.searchResultsCount = 0;
  }

  // Round a number by default to 1 decimal place unless a fractionDigit is passed in
  roundNumber(value: number, fractionDigit: number = 1): number {
    if (value) {
      return parseFloat(value.toFixed(fractionDigit));
    } else {
      return value;
    }
  }

  // Builds the intersects filter for geoserver
  buildIntersectsFilter(): string {
    const coordinates: any[] = this.drawCoordinates ? this.drawCoordinates : this.selectedRecentQuakeExtent;
    let polygonString: string = 'POLYGON((';

    for (let i: number = 0; i < coordinates.length; i++) {
      if (i > 0) {
        polygonString += ', ';
      }

      polygonString += (coordinates[i][1] + ' ' + coordinates[i][0]);
    }
    polygonString += '))';

    return ' AND INTERSECTS(geometry, ' + polygonString + ') ';
  }

  // Builds the search criteria
  criteriaBuilder(excludeFilterDisplayFlag?: boolean): string {
    let criteria: string = '&CQL_FILTER=display_flag=\'Y\' AND ';
    if (excludeFilterDisplayFlag) {
      criteria = '';
    }

    if (this.searchCriteria.location === 'Australia') {
      criteria += 'located_in_australia=\'Y\' AND ';
    }

    if (this.searchCriteria.magnitudeMin != null) {
      criteria += 'preferred_magnitude>=' + (Number(this.searchCriteria.magnitudeMin) <= Number(0.05) ? 0 : Number(this.searchCriteria.magnitudeMin) - Number(0.05)) + ' AND ';
    }

    if (this.searchCriteria.magnitudeMax != null) {
      criteria += 'preferred_magnitude<=' + (Number(this.searchCriteria.magnitudeMax) + Number(0.04)) + ' AND ';
    }

    if (this.searchCriteria.dateFrom != null && this.searchCriteria.dateTo != null) {
      criteria += 'origin_time BETWEEN ' + moment(this.searchCriteria.dateFrom).format('YYYY-MM-DD[T]HH:mm[:00Z]') + ' AND ' + moment(this.searchCriteria.dateTo).format('YYYY-MM-DD[T]HH:mm[:00Z]');
    }

    if (this.selectedState !== 'Please select') { // State Query
      const states: string [] = this.environment.boundaries[this.selectedState].split(',');
      let reverseCoords: string = '';

      for (let i: number = 0; i < states.length; i++) {
        const coordinates: string [] = states[i].split(' ');
        reverseCoords += coordinates[1];
        reverseCoords += ' ';
        reverseCoords += coordinates[0];
        reverseCoords += ',';
      }

      criteria += ' AND INTERSECTS(geometry, POLYGON((' + reverseCoords.substr(0, reverseCoords.length - 1) + ')))';
    } else {
      if (this.drawCoordinates != null || this.selectedRecentQuakeExtent != null) { // Circle or Polygon query
        criteria += this.buildIntersectsFilter();
      } else if (this.drawExtent != null) { // Box query
        if (this.drawExtent.length === 1) {
          criteria += ' AND BBOX(geometry, ' + this.drawExtent[0].minLatitude + ', ' + this.drawExtent[0].minLongitude + ', ' +
            this.drawExtent[0].maxLatitude + ', ' + this.drawExtent[0].maxLongitude + ') ';
        } else if (this.drawExtent.length === 2) {
          criteria += ' AND (BBOX(geometry, ' + this.drawExtent[0].minLatitude + ', ' + this.drawExtent[0].minLongitude + ', ' +
            this.drawExtent[0].maxLatitude + ', ' + this.drawExtent[0].maxLongitude + ')';
          criteria += ' OR BBOX(geometry, ' + this.drawExtent[1].minLatitude + ', ' + this.drawExtent[1].minLongitude + ', ' +
            this.drawExtent[1].maxLatitude + ', ' + this.drawExtent[1].maxLongitude + ')) ';
        }
      }
    }

    if (criteria === '&CQL_FILTER=') {
      return '';
    } else {
      return criteria;
    }
  }

  // Set the default values for the to and from dates.
  setInitialDates(): void {
    this.searchCriteria.dateFrom = moment().subtract(1, 'year');
    this.searchCriteria.dateTo = moment();
    this.searchCriteria.dateFromString = this.searchCriteria.dateFrom.format(SearchCriteria.DATE_STRING_FORMAT);
    this.searchCriteria.dateToString = this.searchCriteria.dateTo.format(SearchCriteria.DATE_STRING_FORMAT);
  }

  // Clear button was fired, reset the fields
  clearForm(): void {
    this.mapService.removeSearchFeatureLayer();
    let zoomLevel: number = this.environment.initialZoomLevel.desktop;
    if (this.deviceCommonService.isMobile()) {
      zoomLevel = this.environment.initialZoomLevel.mobile;
    } else if (this.deviceCommonService.isTablet()) {
      zoomLevel = this.environment.initialZoomLevel.tablet;
    }
    this.mapService.setInitialMapPositionAnimated(zoomLevel, 1500);
    this.reset();
  }

  // Reset the map and fields
  reset(): void {
    this.showResults = false;
    this.searchCriteria = new SearchCriteria(0.0, 9.9, moment(), moment(), 'Australia');
    this.setInitialDates();
    this.features = [];
    this.drawExtent = null;
    this.drawCoordinates = null;
    this.selectedRecentQuakeExtent = null;
    this.drawType = 'Box';
    this.drawActive = false;
    this.showCriteria = true;
    this.showQuakeDetail = false;
    this.mapService.removeSearchFeatureLayer();
    this.mapService.removeDrawExtentInteraction(ToolConstants.SEARCH);
    this.mapService.removeDrawExtentLayer(ToolConstants.SEARCH);
    this.mapService.removeRecentEarthquakesSearchLayer();
    this.mapService.removeStationsLayer();
    this.selectedRecentQuake = this.recentQuakesAustraliaFormatted[0];
    this.drawRadius = 0;
    this.drawBoundsText = null;
    this.maxResultsPerPage = 10;
    this.selectedState = 'Please select';
    this.mapService.removeStateLayer(ToolConstants.SEARCH);
    this.showDrawError = false;
    this.errorMessage = null;
    this.showSearchProgressBar = false;
  }

  // Refine search button was fired, hide the results
  refineSearch(): void {
    this.features = [];
    this.currentPage = 1;
    this.searchResultsCount = 0;
    this.showCriteria = true;
    this.showResults = false;
    this.errorMessage = null;
  }

  // Activate the draw tool and change the button text
  draw(): void {
    this.drawActive = !this.drawActive;

    if (!this.drawActive) {
      this.drawExtent = null;
      this.drawCoordinates = null;
      this.selectedRecentQuakeExtent = null;
      this.mapService.removeDrawExtentLayer(ToolConstants.SEARCH);
      this.mapService.removeDrawExtentInteraction(ToolConstants.SEARCH);
      this.drawRadius = 0;
      this.drawBoundsText = null;
      this.showDrawError = false;
    } else if (this.drawActive) {
      this.resetRecentQuakes();
      this.drawInteractionActive = true;
      this.selectedState = 'Please select';
      this.mapService.removeStateLayer(ToolConstants.SEARCH);
      this.mapService.startDrawExtentInteraction(this.mapService, this.drawType, ToolConstants.SEARCH);

      // Subscribe to the draw end event
      this.subscriptions.add(this.mapService.drawEndEmitter.subscribe((data) => {
        if (data.layerId === ToolConstants.SEARCH) {
          // Circle coordinates
          if (this.drawType === 'Circle' || this.drawType === 'Polygon') {
            this.drawCoordinates = data.coordinatesEpsg4326;
            this.drawRadius = data.radius;

            if (data.extent) {
              const bounds: BoundsCommon = new BoundsCommon(data.extent[0], data.extent[1], data.extent[2], data.extent[3]);
              if (this.geometryCommonService.hasGeometryCrossedDateLine(bounds)) {
                this.showDrawError = true;
              } else {
                // Check and adjust coordiantes if they have crossed the dateline
                this.drawCoordinates = this.geometryCommonService.getCorrectedDrawExtentBounds(bounds, <[number[]]>data.coordinatesEpsg4326);

                // Check if the drawn coordinates are in Australia, if they are not change the location to World
                for (let i: number = 0; i < this.drawCoordinates.length; i++) {
                  if (!this.mapService.isCoordinateInAustralia(this.drawCoordinates[i][0], this.drawCoordinates[i][1])) {
                    this.searchCriteria.location = 'World';
                  }
                }
              }
            }
            // Box coordinates
          } else {
            const bounds: BoundsCommon = new BoundsCommon(data.extent[0], data.extent[1], data.extent[2], data.extent[3]);
            if (this.geometryCommonService.hasGeometryCrossedDateLine(bounds)) {
              // Dateline has crossed get multiple bounding boxes
              this.drawExtent = this.geometryCommonService.getCorrectedDrawExtentBoundsForBox(bounds);
            } else {
              // Dateline was not crossed get corrected coordinates
              this.drawCoordinates = this.geometryCommonService.getCorrectedDrawExtentBounds(bounds, <[number[]]>data.coordinatesEpsg4326);
            }

            // Check if the drawn coordinates are in Australia, if they are not change the location to World
            for (let i: number = 0; i < data.coordinatesEpsg4326.length; i++) {
              if (!this.mapService.isCoordinateInAustralia(data.coordinatesEpsg4326[i][0], data.coordinatesEpsg4326[i][1])) {
                this.searchCriteria.location = 'World';
              }
            }
          }

          this.drawInteractionActive = false;
          this.mapService.removeDrawExtentInteraction(ToolConstants.SEARCH);
        }
      }));
    }
  }

  // Resets the draw functionality
  resetDraw(): void {
    this.drawActive = false;
    this.drawInteractionActive = false;
    this.drawExtent = null;
    this.drawCoordinates = null;
    this.mapService.removeDrawExtentInteraction(ToolConstants.SEARCH);
    this.mapService.removeDrawExtentLayer(ToolConstants.SEARCH);
    this.drawRadius = 0;
    this.drawBoundsText = null;
    this.showDrawError = false;
  }

  // Exports a CSV file containing the search results, as well as the search criteria used.
  exportCsv(): void {
    this.showExportProgressBar = true;
    let criteria: string = this.criteriaBuilder();

    this.subscriptions.add(this.searchService.getCsvExport(criteria).subscribe({
      next: (response) => {
        criteria = this.criteriaBuilder(true);
        criteria = '"Search criteria: ' + criteria + '"\r\n\r\n';

        const parts: any[] = [];
        parts.push(criteria);
        parts.push(response);
        const complete: Blob = new Blob(parts);

        const filename: string = 'earthquakes_export.csv';
        FileSaver.saveAs(complete, filename);
      },
      error: (error) => {
        this.showExportProgressBar = false;
      },
      complete: () => {
        this.showExportProgressBar = false;
      }
    }));
  }

  // Display detailed information and hide the search results
  showQuakeDetails(feature: any): void {
    // If is required so if a user has clicked to change a page and quickly clicks on a quake detail both panels don't display
    if (!this.showSearchProgressBar) {
      this.showQuakeDetail = true;
      this.showCriteria = false;
      this.showResults = false;
      this.selectedQuakeDetail = feature.properties;
    }

    // Scroll to the top of form
    this.sidePanelCommonService.resetRightScroll();
  }

  // Return from the detailed information to the search results
  returnToResults(): void {
    this.showQuakeDetail = false;
    this.showCriteria = false;
    this.showResults = true;
    this.mapService.removeStationsLayer();
  }

  // Updates the radius of the circle for the selected recent earthquake
  updateRecentQuakeRadius(radius: number): void {
    this.selectedRecentQuakeRadius = radius;
    this.mapService.removeDrawExtentLayer(ToolConstants.SEARCH);
    this.selectedRecentQuakeExtent = this.mapService.addRecentEarthquakesSearchLayer(this.selectedRecentQuake.latitude, this.selectedRecentQuake.longitude, this.selectedRecentQuakeRadius);
  }

  // Reset the recent quakes values
  resetRecentQuakes(): void {
    this.selectedRecentQuake = this.searchCriteria.location === 'Australia' ? this.recentQuakesAustraliaFormatted[0] : this.recentQuakesFormatted[0];
    this.selectedRecentQuakeExtent = null;
    this.mapService.removeRecentEarthquakesSearchLayer();
  }

  // Called when the state dropdown is selected
  updateState(state: string): void {
    this.selectedState = state;
    if (!this.deviceCommonService.isMobile()) {
      if (this.selectedState !== 'Please select') {
        const polygon: Coordinate [] = this.mapService.addStateLayer(this.environment.boundaries[this.selectedState], ToolConstants.SEARCH);
        this.resetDraw();
        this.resetRecentQuakes();
        this.searchCriteria.location = 'Australia';
        const bounds: BoundsCommon = this.mapService.getBoundariesForCoordinates(polygon);
        this.mapService.zoomToBoundsWithPadding(bounds, 1500, [100, this.sidePanelCommonService.sidePanelRight.width + 50, 100, 50]);
      } else {
        this.mapService.removeStateLayer(ToolConstants.SEARCH);
      }
    }
  }

  // Fired when a user clicks on the paginator
  onPagerChange(pager: PagerType): void {
    this.errorMessage = null;
    this.showProgressBar = true;
    this.getResultsSubscription?.unsubscribe();
    this.getResults(pager).then((results: FeatureJson) => {
      this.showProgressBar = false;
      this.features = results.features;
      if (!this.deviceCommonService.isMobile()) {
        this.mapService.removeSearchFeatureLayer();
        this.mapService.addSearchFeatureLayer(results.features);
        this.mapService.zoomToFeaturesWithPadding(results.features, 1500, [100, this.sidePanelCommonService.openPanelWidth, 100, 50]);
      }
    }, () => {
      this.showProgressBar = false;
      this.errorMessage = 'Failed to retrieve page data. Please try again later.';
    });
  }

  // Call the service to get the search results
  getResults(pager: PagerType): Promise<FeatureJson> {
    const page: number = pager.currentPage;
    const sortColumn: string = pager.sortColumn;
    const sortOrder: 'A' | 'D' = pager.sortOrder;
    return new Promise((resolve, reject) => {
      const append: string = this.criteriaBuilder() + '&count=' + this.maxResultsPerPage + '&startIndex=' + ((page - 1) * this.maxResultsPerPage) + '&sortBy=' + this.getSortKey(sortColumn) + ' ' + sortOrder;
      this.getResultsSubscription = this.searchService.getFeatures(append).subscribe({
        next: (results: any) => {
          resolve(results);
        },
        error: error => {
          reject(error);
        }
      });
    });
  }

  // Get the key for the column based on the display text
  getSortKey(column: any): any {
    let key: any = null;
    this.resultsTableColumns.forEach(resultColumn => {
      if (resultColumn.displayText.localeCompare(column) === 0) {
        key = resultColumn.propertyKeys[resultColumn.propertyKeys.length - 1];
      }
    });
    return key;
  }

  formatCoordinates(value: number): string {
    return value.toFixed(2);
  }

  formatDate(value: number): string {
    return moment.utc(value).format('DD/MM/YYYY HH:mm:ss');
  }

  formatDepth(value: number): string {
    return value.toFixed(0);
  }

  formatMagnitude(value: number): string {
    return value.toFixed(1);
  }

  ngOnDestroy(): void {
    this.mapService.showRecentEarthquakesLayer('Search', true);
    this.subscriptions.unsubscribe();
    this.getResultsSubscription?.unsubscribe();
  }
}
