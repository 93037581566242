import { Inject, Injectable } from '@angular/core';
import { ApplicationStateCommonService, ENVIRONMENT } from 'flying-hellfish-common';
import { MapService } from '../../../map/map.service';
import { LayersService } from '../../../tools/layers/layer.service';
import { MapSaveStateType } from '../../../types/map.save.state.type';
import { RecentQuakesService } from '../../../tools/recent-quakes/recent.quakes.service';
import { LayerConstants } from '../../../tools/layers/layer.constants';

@Injectable()
export class ApplicationStateService extends ApplicationStateCommonService {
  constructor(
    private childMapService: MapService,
    private layersService: LayersService,
    private recentQuakesService: RecentQuakesService,
    @Inject(ENVIRONMENT) protected environment: any
  ) {
    super(environment, childMapService);
  }

  /**
   * Restore application state from saved state (existing state will be cleared).
   * @param state - the saved application state to restore
   */
  restoreApplicationState(state: MapSaveStateType): void {
    const centerAs4326: number[] = this.childMapService.transformCoordinate(state.center[0], state.center[1], this.environment.datumProjection, this.environment.displayProjection);
    this.childMapService.setMapPosition(centerAs4326[1], centerAs4326[0], state.zoomLevel);
    if (state.baseLayerId) {
      this.childMapService.changeBaseLayer(state.baseLayerId);
    }
    state.layers?.forEach(layer => {
      this.layersService.toggleLayer(layer.id);
      if (layer.id === LayerConstants.FELT_REPORTS_LAYER) {
        this.layersService.hoursNumberSinceReport = layer.opacity;
        this.layersService.changeFeltReportRange(layer.id);
      } else {
        this.layersService.changeLayerOpacity(layer.id, layer.opacity);
      }
    });

    if (state.baseLayerId) {
      this.layersService.setBaseLayer(state.baseLayerId);
    }

    state.layers?.filter(layer => layer.id !== LayerConstants.FELT_REPORTS_LAYER).forEach(layer => {
      this.layersService.layers.get(layer.id).opacity = layer.opacity;
    });

    if (state?.recentQuakesPeriod) {
      this.recentQuakesService.selectedPeriod = state.recentQuakesPeriod;
      this.recentQuakesService.selectedMagnitude = state.recentQuakesMagnitude;
      this.recentQuakesService.selectedRegion = state.recentQuakesRegion;
      this.recentQuakesService.search();
    }
  }

  // Return AppState object with the data needed to restore this session
  getApplicationState(): MapSaveStateType {
    const savedMapState: MapSaveStateType = {} as MapSaveStateType;
    savedMapState.zoomLevel = this.childMapService.mapInstance.getView().getZoom();
    savedMapState.center = this.childMapService.mapInstance.getView().getCenter();
    savedMapState.baseLayerId = this.childMapService.activeBaseLayerId;

    const mapStateLayers: any[] = [];
    this.layersService.layers.forEach((value: any, key: string) => {
      if (value.visible) {
        mapStateLayers.push({
          id: key,
          opacity: key === LayerConstants.FELT_REPORTS_LAYER ? this.layersService.hoursNumberSinceReport : value.opacity,
        });
      }
    });

    savedMapState.layers = mapStateLayers;
    savedMapState.recentQuakesPeriod = this.recentQuakesService.selectedPeriod;
    savedMapState.recentQuakesMagnitude = this.recentQuakesService.selectedMagnitude;
    savedMapState.recentQuakesRegion = this.recentQuakesService.selectedRegion;
    return savedMapState;
  }
}
