export class NotificationSubscription {
  email: string;
  geometry: string;
  magnitudeMin: number;
  magnitudeMax: number;
  queryType: string;
}

export enum QueryTypes {
  INSERT = 'insert',
  DELETE = 'delete'
}

