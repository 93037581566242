import { Inject, Injectable } from '@angular/core';
import { AWS_PROVIDER } from '@shared/aws.module';
import { LocationClient, SearchPlaceIndexForTextCommand } from '@aws-sdk/client-location';
import { GeocodingProvider } from '@shared/services/location/geocoding/geocoding.provider';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeocodingResultType } from '@shared/services/location/location.types';
import { ENVIRONMENT } from 'flying-hellfish-common';

@Injectable()
export class AwsGeocodingProvider extends GeocodingProvider {
  constructor(@Inject(AWS_PROVIDER.LOCATION) private locationClient: LocationClient, @Inject(ENVIRONMENT) private environment: any) {
    super();
  }

  locationTextToPosition(location: string): Observable<GeocodingResultType> {
    return from(this.locationClient.send(new SearchPlaceIndexForTextCommand({
      IndexName: this.environment.placeIndexName,
      Text: location
    }))).pipe(map((response) => {
      return {
        candidates: response.Results.map((result) => {
          return {
            address: result.Place.Label,
            location: {
              x: result.Place.Geometry.Point[0],
              y: result.Place.Geometry.Point[1]
            }
          };
        })
      };
    }));
  }
}
