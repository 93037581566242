export class LayerConstants {
  public static readonly FELT_REPORTS_LAYER: string = 'feltReportLayer';
  public static readonly HISTORIC_EARTHQUAKES_LAYER: string = 'historicEarthquakesLayer';
  public static readonly OVERLAYS_LAYER_GROUP: string = 'overlaysGroup';
  public static readonly NEOTECTONIC_LAYER: string = 'neotectonicLayer';
  public static readonly SEISMIC_HAZARD_LAYER_GROUP: string = 'seismicHazardGroup';
  public static readonly SEISMIC_HAZARD_TWO_PERCENT_LAYER: string = 'seismicHazardTwoPercentLayer';
  public static readonly SEISMIC_HAZARD_TEN_PERCENT_LAYER: string = 'seismicHazardTenPercentLayer';
  public static readonly TECTONIC_PLATE_BOUNDARIES_LAYER: string = 'tectonicPlateBoundariesLayer';
}
