import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { LayersComponent } from './layers.component';
import { LayersService } from './layer.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FlyingHellfishCommonModule } from 'flying-hellfish-common';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [
    LayersComponent
  ],
  imports: [
    SharedModule,
    BsDropdownModule.forRoot(),
    FlyingHellfishCommonModule,
    RouterLink
  ],
  exports: [
    LayersComponent
  ],
  providers: [
    LayersService
  ]
})
export class LayersModule {
}
