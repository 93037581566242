import { Injectable } from '@angular/core';
import { AwsGeocodingProvider } from '@shared/services/location/geocoding/aws.geocoding.provider';
import { Observable } from 'rxjs';
import { GeocodingResultType, ReverseGeocodingResultType } from '@shared/services/location/location.types';
import { FlyingHellfishGeocodingProvider } from '@shared/services/location/geocoding/flying.hellfish.geocoding.provider';
import { catchError } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';
import { AwsReverseGeocodingProvider } from '@shared/services/location/reverse-geocoding/aws.reverse.geocoding.provider';
import { FlyingHellfishReverseGeocodingProvider } from '@shared/services/location/reverse-geocoding/flying.hellfish.reverse.geocoding.provider';

@Injectable()
export class LocationService {
  constructor(
    private awsGeocodingProvider: AwsGeocodingProvider,
    private flyingHellfishGeocodingProvider: FlyingHellfishGeocodingProvider,
    private awsReverseGeocodingProvider: AwsReverseGeocodingProvider,
    private flyingHellfishReverseGeocodingProvider: FlyingHellfishReverseGeocodingProvider
  ) {
  }

  /**
   * Geocode location text to longitude/latitude coordinates.
   *
   * Automatically handles retrying and switching between alternative geocoding providers on failure.
   *
   * @param location - the location to geocode
   */
  locationTextToPosition(location: string): Observable<GeocodingResultType> {
    return this.flyingHellfishGeocodingProvider.locationTextToPosition(location).pipe(
      catchError(() => this.awsGeocodingProvider.locationTextToPosition(location)),
      retryBackoff({ initialInterval: 1000, maxRetries: 5 })
    );
  }

  /**
   * Reverse geocode longitude/latitude coordinates to location.
   *
   * Automatically handles retrying and switching between alternative reverse geocoding providers on failure.
   *
   * @param longitude - the longitude to reverse
   * @param latitude - the latitude to reverse
   */
  positionToLocationText(longitude: number, latitude: number): Observable<ReverseGeocodingResultType | null> {
    return this.flyingHellfishReverseGeocodingProvider.positionToLocationText(longitude, latitude).pipe(
      catchError(() => this.awsReverseGeocodingProvider.positionToLocationText(longitude, latitude)),
      retryBackoff({ initialInterval: 1000, maxRetries: 5 })
    );
  }
}
