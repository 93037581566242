import { Component, ContentChild, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { BaseChartComponent, calculateViewDimensions, ColorHelper, id, LegendOptions, LegendPosition, LegendType, Orientation, ScaleType, ViewDimensions } from '@swimlane/ngx-charts';
import { curveLinear } from 'd3-shape';
import { ScaleLinear, scaleLinear, ScalePoint, scalePoint, ScaleTime, scaleTime } from 'd3-scale';

@Component({
  selector: 'ga-felt-report-chart',
  templateUrl: './felt.report.chart.component.html',
  styleUrls: ['./felt.report.chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeltReportChartComponent extends BaseChartComponent implements OnInit, OnChanges {
  // Adapted from https://github.com/hobi4ek/double-axis-chart/
  @Input() legend: boolean;
  @Input() legendTitle: string = 'Legend';
  @Input() xAxis: boolean;
  @Input() yAxis: boolean;
  @Input() showXAxisLabel: boolean;
  @Input() yMainAxisShowLabel: boolean;
  @Input() ySecondaryAxisShowLabel: boolean;
  @Input() xAxisLabel: string;
  @Input() yMainAxisLabel: string;
  @Input() ySecondaryAxisLabel: string;
  @Input() autoScale: boolean = true;
  @Input() gradient: boolean;
  @Input() showXGridLines: boolean = true;
  @Input() showYMainGridLines: boolean = true;
  @Input() showYSecondaryGridLines: boolean = false;
  @Input() curve: any = curveLinear;
  @Input() activeEntries: any[] = [];
  @Input() schemeType: ScaleType;
  @Input() rangeFillOpacity: number;
  @Input() xAxisTickFormatting: any;
  @Input() yMainAxisTickFormatting: any;
  @Input() ySecondaryAxisTickFormatting: any;
  @Input() xAxisTicks: any[];
  @Input() yMainAxisTicks: any[];
  @Input() ySecondaryAxisTicks: any[];
  @Input() roundDomains: boolean = false;
  @Input() tooltipDisabled: boolean = false;
  @Input() showRefLines: boolean = false;
  @Input() referenceLines: any;
  @Input() showRefLabels: boolean = true;
  @Input() xScaleMin: any;
  @Input() xScaleMax: any;
  @Input() yMainScaleMin: number;
  @Input() yMainScaleMax: number;
  @Input() ySecondaryScaleMin: number;
  @Input() ySecondaryScaleMax: number;
  @Input() yDefaultAxis: string = 'left';
  @Input() chartsData: any;
  @Input() yMainAxisScaleFactor: any;
  @Input() ySecondaryAxisScaleFactor: any;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;
  @ContentChild('seriesTooltipTemplate') seriesTooltipTemplate: TemplateRef<any>;

  dims: ViewDimensions;
  xScale: any;
  yMainScale: any;
  xDomain: any;
  yDomain: any;
  transform: string;
  colors: ColorHelper;
  colorsLine: ColorHelper;
  margin: any[] = [10, 20, 10, 20];
  xAxisHeight: number = 0;
  yAxisWidth: number = 0;
  legendOptions: any;
  scaleType: ScaleType = ScaleType.Linear;
  xScaleLine: ScaleTime<number, number> | ScaleLinear<number, number> | ScalePoint<string>;
  yScaleLine: any;
  ySecondScale: ScaleLinear<number, number>;
  yDomainLine: [number, number];
  yDomainLine1: [number, number];
  seriesDomain: any[];
  scaledAxis: any;
  combinedSeries: any[];
  xSet: any[];
  filteredDomain: any;
  hoveredVertical: any;
  yOrientLeft: Orientation = Orientation.Left;
  yOrientRight: Orientation = Orientation.Right;
  legendSpacing: number = 0;
  legendType: LegendType = LegendType.ScaleLegend;
  bandwidth: number;
  barPadding: number = 10;
  lineChart: any[];
  lineChart1: any[];
  clipPathId: string;
  clipPath: string;

  comboArr: any[] = [];

  ngOnInit(): void {
  }

  dataSplit(): void {
    // eslint does not correctly handle destructuring into variables
    // eslint-disable-next-line @typescript-eslint/typedef
    [this.lineChart, this.lineChart1] = this.chartsData.reduce((acc, item) => {
      if (item.secondAxis) {
        acc[1].push(item);
      } else {
        acc[0].push(item);
      }

      return acc;
    }, [[], []]);

    if (this.lineChart1.length) {
      const [min1, max1]: [number, number] = this.getYDomainLine(this.lineChart);
      const [min2, max2]: [number, number] = this.getYDomainLine(this.lineChart1);

      this.comboArr = this.lineChart.concat(this.lineChart1.map((line) => {
        return {
          name: line.name,
          series: line.series.map((point) => {
            return {
              name: point.name,
              value: ((point.value - min2) * (max1 - min1) / (max2 - min2)) + min1
            };
          })
        };
      }));

    } else {

      this.comboArr = this.lineChart;
    }
  }

  trackBy(index: any, item: { name: string }): string {
    return item.name;
  }

  update(): void {
    this.dataSplit();

    super.update();

    const q: Parameters<typeof calculateViewDimensions>[0] = {
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.yMainAxisShowLabel,
      showLegend: this.legend,
      legendPosition: LegendPosition.Below
    };

    this.dims = calculateViewDimensions(q);

    if (this.yAxis && this.lineChart1.length) {
      this.dims.width -= 65;
    }

    // Line chart
    this.xDomain = this.getXDomainLine();

    this.xScaleLine = this.getXScale(this.xDomain, this.dims.width);
    this.seriesDomain = this.getSeriesDomain();
    this.yDomainLine = this.getYDomainLine(this.lineChart);
    this.yMainScale = this.getYScaleLine(this.yDomainLine, this.dims.height);

    if (this.lineChart1.length) {
      this.yDomainLine1 = this.getYDomainLine(this.lineChart1);
      this.ySecondScale = this.getYScaleLine(this.yDomainLine1, this.dims.height);
    }

    this.setColors();
    this.legendOptions = this.getLegendOptions();
    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;
    this.clipPathId = 'clip' + id().toString();
    this.clipPath = `url(#${this.clipPathId})`;
  }

  deactivateAll(): void {
    this.activeEntries = [...this.activeEntries];
    for (const entry of this.activeEntries) {
      this.deactivate.emit({ value: entry, entries: [] });
    }
    this.activeEntries = [];
  }

  @HostListener('mouseleave')
  hideCircles(): void {
    this.hoveredVertical = null;
    this.deactivateAll();
  }

  updateHoveredVertical(item: { value: any }): void {
    this.hoveredVertical = item.value;
    this.deactivateAll();
  }

  updateDomain(domain: any): void {
    this.filteredDomain = domain;
    this.xDomain = this.filteredDomain;
    this.xScaleLine = this.getXScale(this.xDomain, this.dims.width);
  }

  getSeriesDomain(): any[] {
    this.combinedSeries = this.lineChart.concat(this.lineChart1);

    return this.combinedSeries.map(d => d.name);
  }

  isDate(value: any): boolean {
    return value instanceof Date;
  }

  getScaleType(values: any): ScaleType {
    let date: boolean = true;
    let num: boolean = true;

    for (const value of values) {
      if (!this.isDate(value)) {
        date = false;
      }

      if (typeof value !== 'number') {
        num = false;
      }
    }

    if (date) {
      return ScaleType.Time;
    }
    if (num) {
      return ScaleType.Linear;
    }

    return ScaleType.Ordinal;
  }

  getXDomainLine(): any[] {
    let values: any[] = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (!values.includes(d.name)) {
          values.push(d.name);
        }
      }
    }

    this.scaleType = this.getScaleType(values);
    let domain: any[] = [];

    if (this.scaleType === ScaleType.Time) {
      const min: number = Math.min(...values);
      const max: number = Math.max(...values);
      domain = [min, max];
    } else if (this.scaleType === ScaleType.Linear) {
      values = values.map(v => Number(v));
      const min: number = Math.min(...values);
      const max: number = Math.max(...values);
      domain = [min, max];
    } else {
      domain = values;
    }

    this.xSet = values;

    return domain;
  }

  getYDomainLine(data: any): [number, number] {
    const domain: any[] = [];

    for (const results of data) {
      for (const d of results.series) {
        if (domain.indexOf(d.value) < 0) {
          domain.push(d.value);
        }
        if (d.min !== undefined) {
          if (domain.indexOf(d.min) < 0) {
            domain.push(d.min);
          }
        }
        if (d.max !== undefined) {
          if (domain.indexOf(d.max) < 0) {
            domain.push(d.max);
          }
        }
      }
    }

    if (!this.autoScale) {
      domain.push(0);
    }

    const min: number = this.yMainScaleMin ? this.yMainScaleMin : Math.min(...domain);
    const max: number = this.yMainScaleMax ? this.yMainScaleMax : Math.max(...domain);

    if (this.ySecondaryAxisScaleFactor) {

      const minMax: any = this.ySecondaryAxisScaleFactor(min, max);

      return [minMax.min, minMax.max];
    } else {

      return [min, max];
    }
  }

  getXScale(domain: any, width: number): ScaleTime<number, number> | ScaleLinear<number, number> | ScalePoint<string> {
    let scale: ScaleTime<number, number> | ScaleLinear<number, number> | ScalePoint<string>;
    if (this.bandwidth === undefined) {
      this.bandwidth = (this.dims.width - this.barPadding);
    }

    if (this.scaleType === ScaleType.Time) {
      scale = scaleTime()
        .range([0, width])
        .domain(domain);
    } else if (this.scaleType === ScaleType.Linear) {
      scale = scaleLinear()
        .range([0, width])
        .domain(domain);

      if (this.roundDomains) {
        scale = scale.nice();
      }
    } else if (this.scaleType === ScaleType.Ordinal) {
      scale = scalePoint()
        .range([this.bandwidth / 2, width - this.bandwidth / 2])
        .domain(domain);
    }

    return scale;
  }

  getYScaleLine(domain: any, height: number): ScaleLinear<number, number> {
    const scale: ScaleLinear<number, number> = scaleLinear()
      .range([height, 0])
      .domain(domain);

    return this.roundDomains ? scale.nice() : scale;
  }

  getYScale(yDomain: any, height: number): any {
    const scale: any = scaleLinear()
      .range([height, 0])
      .domain(yDomain);

    return this.roundDomains ? scale.nice() : scale;
  }

  onClick(data: any): void {
    this.select.emit(data);
  }

  setColors(): void {
    let domain: any;
    if (this.schemeType === ScaleType.Ordinal) {
      domain = this.xDomain;
    } else {
      domain = this.yDomain;
    }
    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    this.colorsLine = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions(): any {
    const opts: LegendOptions = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: LegendPosition.Below
    };

    if (opts.scaleType === ScaleType.Ordinal) {
      opts.domain = this.seriesDomain;
      opts.colors = this.colorsLine;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors.scale;
    }

    return opts;
  }

  updateLineWidth(width: number): void {
    this.bandwidth = width;
  }

  updateYAxisWidth({ width }: any): void {
    this.yAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({ height }: any): void {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(item: any): void {
    const idx: number = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value && d.series === item.series;
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item, ...this.activeEntries];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(item: any): void {
    const idx: number = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value && d.series === item.series;
    });

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }
}
