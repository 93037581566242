import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceCommonService, SidePanelCommonService } from 'flying-hellfish-common';
import { FeatureType } from '../../types/feature.type';
import { MapService } from '../../map/map.service';
import { RecentQuakesService } from '../recent-quakes/recent.quakes.service';
import { AppConstants } from '../../app.constants';

@Component({
  selector: 'ga-toolbar-identify',
  templateUrl: 'identify.component.html',
  styleUrls: ['identify.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: true,
})
export class IdentifyComponent implements OnInit, OnDestroy {
  helpIcon: string = AppConstants.DEFAULT_HELP_ICON;
  feature: FeatureType;
  showFeature: boolean = false;
  subscriptions: Subscription = new Subscription();

  constructor(private changeDetectorRef: ChangeDetectorRef, private sidePanelCommonService: SidePanelCommonService, public deviceCommonService: DeviceCommonService,
              private mapService: MapService, private recentQuakesService: RecentQuakesService) {
    this.subscriptions.add(this.sidePanelCommonService.rightMenuEmitter.subscribe((data) => {
      // Clean up the layers on the map
      if (data.lastActiveToolId === 'identify') {
        if (!mapService.getRecentQuakesLayerVisibility()) {
          this.mapService.removeStationsLayer();
          this.mapService.removeEarthQuakeLayerForFeature();
          if (data.activeToolId !== 'search') {
            this.mapService.showRecentEarthquakesLayer('IdentifyComponent', true);
          }
        }
      }
    }));

    // Fired when an earthquake is clicked on the map
    this.subscriptions.add(mapService.identifyFeatureEmitter.subscribe(
      feature => {
        this.identifyFeatureEmit(feature.getProperties());
      }
    ));

    // Fired when an event is passed in via the url
    this.subscriptions.add(mapService.identifyEventFeatureEmitter.subscribe(
      feature => {
        this.identifyFeatureEmit(feature.properties);
        this.subscriptions.add(this.recentQuakesService.getRecentQuakes()
          .subscribe({
            next: data => {
              let isRecentQuake: boolean = false;
              for (const recentQuake of data.features) {
                if (recentQuake.properties.event_id === feature.properties.event_id) {
                  isRecentQuake = true;
                }
              }
              if (!isRecentQuake) {
                setTimeout(() => {
                  this.mapService.showRecentEarthquakesLayer('IdentifyComponent', false);
                }, 500);
                this.mapService.createEarthQuakeLayerForFeature(this.feature);
              }
            },
            error: error => console.log(error)
          }));
      }
    ));
  }

  // Fired when an event is passed in via the url
  identifyFeatureEmit(feature: FeatureType): void {
    this.feature = feature;
    this.showFeature = true;
    this.changeDetectorRef.markForCheck();
    this.sidePanelCommonService.resetRightScroll();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.showFeature = false;
    this.mapService.resetRecentEarthquakesLayer();
  }
}
