import { ReverseGeocodingProvider } from '@shared/services/location/reverse-geocoding/reverse.geocoding.provider';
import { Observable } from 'rxjs';
import { GeocodeAddress, ReverseGeocodingResultType } from '@shared/services/location/location.types';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from 'flying-hellfish-common';

@Injectable()
export class FlyingHellfishReverseGeocodingProvider extends ReverseGeocodingProvider {
  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: any) {
    super();
  }

  /**
   * Call Flying Hellfish Geocoder api to reverse geocode an address from a longitude and latitude
   *
   * @param longitude
   * @param latitude
   */
  positionToLocationText(longitude: number, latitude: number): Observable<ReverseGeocodingResultType | null> {
    const url: string = this.environment.feltReport.reverseGeocodeUrl;
    return this.http.get<GeocodeAddress>(`${url}${longitude},${latitude}`).pipe(
      map((response) => {
        if (response.error) {
          throw new Error(response?.error?.message || 'Failed to reverse geocode position');
        }
        const splitAddress: string[] = response.candidate.a.split(', ');
        const splitPlace: string[] = splitAddress[1].split(' ');
        return {
          address: splitAddress[0],
          postcode: splitPlace.pop(),
          state: splitPlace.pop(),
          suburb: splitPlace.join(' ')
        };
      }));
  }
}
