import { Component } from '@angular/core';

@Component({
  selector: 'ga-toolbar-about',
  templateUrl: 'about.component.html',
  styleUrls: ['about.component.css'],
  preserveWhitespaces: true
})
export class AboutComponent {
}
