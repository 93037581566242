import { Component } from '@angular/core';
import { environment } from '@environment';
import { AnimationCommon } from 'flying-hellfish-common';
import { Glossary } from '@shared/glossary';

@Component({
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
  animations: [
    AnimationCommon.animationCommonEnter500
  ]
})
export class HelpComponent {
  feedbackEmail: string = environment.feedbackEmail;
  disclaimer: string = environment.disclaimer;
  iconCacheUrl: string = environment.iconsCacheUrl;
  glossary: Glossary = new Glossary();
}
