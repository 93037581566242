import { NgModule } from '@angular/core';
import { FeltQuakeComponent } from './felt.quake.component';

@NgModule({
  declarations: [
    FeltQuakeComponent
  ],
  exports: [
    FeltQuakeComponent
  ]
})
export class FeltQuakeModule {
}
