import { AfterViewInit, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AutoRefreshCommonService, DeviceCommonService, SidePanelCommonService } from 'flying-hellfish-common';
import { MapService } from './map.service';
import { Layer } from 'ol/layer';
import { debounceTime, fromEvent, Subscription } from 'rxjs';
import { AppConstants } from '../app.constants';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { Pixel } from 'ol/pixel';
import { Coordinate } from 'ol/coordinate';
import { FeatureLike } from 'ol/Feature';
import LayerRenderer from 'ol/renderer/Layer';
import { Source } from 'ol/source';

@Component({
  selector: 'ga-map',
  templateUrl: 'map.component.html',
  styleUrls: ['map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  overviewLayers: Layer[] = [];
  subscriptions: Subscription = new Subscription();

  constructor(private sidePanelCommonService: SidePanelCommonService, private mapService: MapService, private deviceCommonService: DeviceCommonService,
              private autoRefreshCommonService: AutoRefreshCommonService, private zone: NgZone) {
    this.mapService.setZone(zone);
  }

  // Handles resize events of the map
  onResize(): void {
    setTimeout(() => {
      this.mapService.mapInstance.updateSize();
      this.mapService.refreshOverviewMap();
    }, 500);
  }

  ngOnInit(): void {
    this.subscriptions.add(this.mapService.onMapClick$.subscribe((browserEvent: MapBrowserEvent<UIEvent>) => {
      this.zone.run(() => {
        this.clickListener(browserEvent);
      });
    }));
    this.overviewLayers = this.mapService.getOverviewLayers();
  }

  ngAfterViewInit(): void {
    this.mapService.initialiseMapWithOptions({});
    this.autoRefreshCommonService.addAutoRefresh(AppConstants.RECENT_EARTHQUAKES, () => {
      this.mapService.createRecentQuakesLayer();
    }, () => {
      if (this.mapService.recentEarthquakesVectorLayer) {
        return this.mapService.recentEarthquakesVectorLayer.getVisible();
      }
    });
    this.mapService.mapInstance.on('click', (event: MapBrowserEvent<UIEvent>) => {
      this.mapService.onMapClick.next(event);
    });
    this.mapService.registerDoubleClickInteraction();
    this.mapService.refreshOverviewMap();

    // This is required for when you do a browser refresh or route change, otherwise the map will not display
    setTimeout(() => {
      this.mapService.mapInstance.updateSize();
    }, 100);

    // Set the default opacity of the overlays
    for (const layer of this.mapService.mapLayers) {
      if (layer.isBaseLayer === false) {
        this.mapService.setLayerOpacityByUrl(layer.url, layer.opacity / 100);
      }
    }
    // Listens for resize events of the map
    this.subscriptions.add(fromEvent(window, 'resize').pipe(debounceTime(100)).subscribe(() => {
      this.onResize();
    }));
  }

  // Listen for click events on the map and action events
  clickListener(browserEvent: MapBrowserEvent<UIEvent>): void {
    browserEvent.stopPropagation();
    const sidePanelCommonService: SidePanelCommonService = this.sidePanelCommonService;
    const mapService: MapService = this.mapService;
    const coordinate: Coordinate = browserEvent.coordinate;
    const pixel: Pixel = browserEvent.map.getPixelFromCoordinate(coordinate);

    browserEvent.map.forEachFeatureAtPixel(pixel, (feature: FeatureLike, layer: Layer<Source, LayerRenderer<any>>) => {
      if (layer !== null) {
        if (layer.getProperties().id === 'recentQuakes') { // Emit event for identify layer
          sidePanelCommonService.mapToolClicked({
            toolId: 'identify',
            toolSide: 'right',
            panelWidth: sidePanelCommonService.getRightPanelWidthForTool('identify'),
            keepOpen: true
          });

          mapService.identifyFeatureEmitter.next(feature);
        } else if (layer.getProperties().id === 'search') { // Emit event for search layer
          mapService.searchFeatureEmitter.next(feature);
        } else if (layer.getProperties().id === 'neotectonics') { // Emit event for neotectonics layer
          mapService.neotectonicsFeatureEmitter.next({ feature: feature, pixel: pixel });
        }
      }
    });

    let currentZoom: number = mapService.mapInstance.getView().getZoom();
    mapService.mapInstance.on('moveend', () => {
      const newZoom: number = mapService.mapInstance.getView().getZoom();
      if (currentZoom !== newZoom) {
        this.mapService.onMapZoom.next(newZoom);
        currentZoom = newZoom;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
