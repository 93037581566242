import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MapService } from '../map/map.service';
import { FeltGridQueryResponseType } from '../types/felt.grid.query.response.type';
import { QuakeDetailsService } from '../tools/quake-details/quake.details.service';
import Overlay from 'ol/Overlay';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ga-felt-grid-popup',
  templateUrl: 'felt.grid.popup.component.html',
  styleUrls: ['felt.grid.popup.component.css'],
  preserveWhitespaces: true
})
export class FeltGridPopupComponent implements AfterViewInit, OnDestroy {
  @ViewChild('popup', { read: ElementRef }) popup: ElementRef;
  feltGridOverlay: Overlay;
  feltGridReponse: FeltGridQueryResponseType;
  subscriptions: Subscription = new Subscription();

  constructor(private mapService: MapService, private quakeDetailsService: QuakeDetailsService) {
    this.subscriptions.add(quakeDetailsService.onDisplayFeltGridQuery$.subscribe((response) => {
      if (response) {
        this.feltGridReponse = response;
        this.feltGridOverlay.setPosition(this.mapService.getCoordinateFromPixel(response.pixel));
      } else {
        this.closePopup();
      }
    }));

    this.subscriptions.add(mapService.onMapZoom$.subscribe(() => {
      this.closePopup();
    }));
  }

  // Setup the overlay for the felt grid popup
  ngAfterViewInit(): void {
    this.feltGridOverlay = new Overlay({
      element: this.popup.nativeElement,
      autoPan: true
    });

    this.mapService.addOverlay(this.feltGridOverlay);
  }

  // Closes the felt grid popup
  closePopup(): void {
    this.feltGridOverlay.setPosition(undefined);
    this.popup.nativeElement.blur();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
