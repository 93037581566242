import { Component } from '@angular/core';

@Component({
  selector: 'ga-toolbar-emergency-info',
  templateUrl: 'emergency.info.component.html',
  styleUrls: ['emergency.info.component.css'],
  preserveWhitespaces: true
})
export class EmergencyInfoComponent {
}
