<div class="cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left" style="background-color: white;">
  <div>
    <a href="javascript:;" class="closePanel pull-right" title="Close panel"
       (click)="closePanelClick()"><img src="assets/arrow-88-16.png" alt="Close"></a>
  </div>
  <div [hidden]="activeToolId !== 'about'" class="left-side-menu-container">
    <ga-toolbar-about></ga-toolbar-about>
  </div>
  <div [hidden]="activeToolId !== 'emergencyInfo'" class="left-side-menu-container">
    <ga-toolbar-emergency-info></ga-toolbar-emergency-info>
  </div>
  <div [hidden]="activeToolId !== 'layers'" class="left-side-menu-container">
    <ga-toolbar-layers></ga-toolbar-layers>
  </div>
  <div [hidden]="activeToolId !== 'legend'" class="left-side-menu-container">
    <ga-toolbar-legend></ga-toolbar-legend>
  </div>
  <div [hidden]="activeToolId !== 'notifications'" class="left-side-menu-container">
    <ga-toolbar-notifcations></ga-toolbar-notifcations>
  </div>
</div>
