import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { SidePanelRightCommonComponent } from 'flying-hellfish-common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ga-side-panel-right',
  templateUrl: 'side.panel.right.component.html',
  preserveWhitespaces: true
})
export class SidePanelRightComponent extends SidePanelRightCommonComponent implements AfterViewChecked, OnDestroy {
  @ViewChildren('container', { read: ElementRef }) containers: QueryList<ElementRef>;

  private scrollPending: boolean = false;
  private rightScrollSubscription: Subscription;

  @HostListener('window:resize')
  private onResize(): void {
    super.resizePanels(window.innerWidth);
  }

  ngAfterViewChecked(): void {
    if (this.scrollPending) {
      this.containers.forEach((container) => {
        container.nativeElement.scrollTop = 0;
      });

      this.scrollPending = false;
    }
  }

  ngOnDestroy(): void {
    this.rightScrollSubscription.unsubscribe();
  }
}
