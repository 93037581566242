import { NgModule } from '@angular/core';
import { SidePanelLeftComponent } from './side.panel.left.component';
import { SidePanelRightComponent } from './side.panel.right.component';
import { SharedModule } from '../shared/shared.module';
import { LayersModule } from '../tools/layers/layers.module';
import { SearchModule } from '../tools/search/search.module';
import { LegendModule } from '../tools/legend/legend.module';
import { EmergencyInfoModule } from '../tools/emergency-info/emergency.info.module';
import { NotificationsModule } from '../tools/notifications/notifications.module';
import { FeltQuakeModule } from '../tools/felt-quake/felt.quake.module';
import { FeltReportModule } from '../tools/felt-report/felt.report.module';
import { IdentifyModule } from '../tools/identify/identify.module';
import { QuakeDetailsModule } from '../tools/quake-details/quake.details.module';
import { RecentQuakesModule } from '../tools/recent-quakes/recent.quakes.module';
import { AboutModule } from '../tools/about/about.module';

@NgModule({
  declarations: [
    SidePanelLeftComponent,
    SidePanelRightComponent
  ],
  imports: [
    AboutModule,
    EmergencyInfoModule,
    SharedModule,
    FeltQuakeModule,
    FeltReportModule,
    IdentifyModule,
    LayersModule,
    LegendModule,
    NotificationsModule,
    QuakeDetailsModule,
    RecentQuakesModule,
    SearchModule
  ],
  exports: [
    SidePanelLeftComponent,
    SidePanelRightComponent
  ]
})
export class SidePanelModule {
}
