import { Component, HostListener } from '@angular/core';
import { SidePanelLeftCommonComponent } from 'flying-hellfish-common';

@Component({
  selector: 'ga-side-panel-left',
  templateUrl: 'side.panel.left.component.html',
  preserveWhitespaces: true
})
export class SidePanelLeftComponent extends SidePanelLeftCommonComponent {
  @HostListener('window:resize')
  private onResize(): void {
    super.resizePanels(window.innerWidth);
  }
}
