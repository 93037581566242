import { Component, Inject, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AnimationCommon, ENVIRONMENT } from 'flying-hellfish-common';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'ga-notification-link',
  styleUrls: ['./notification.link.component.css'],
  templateUrl: 'notification.link.component.html',
  animations: [
    AnimationCommon.animationCommonEnter300Leave300
  ]
})
export class NotificationLinkComponent {
  @Input() link: string;
  @Input() icon: string;
  @Input() type: string;

  showDisclaimer: boolean = false;
  acceptedDisclaimer: boolean = false;
  disclaimer: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(this.environment.disclaimer);

  constructor(
    private clipboardService: ClipboardService,
    private sanitizer: DomSanitizer,
    @Inject('Window') private window: Window,
    @Inject(ENVIRONMENT) private environment: any
  ) {
  }

  /**
   * Open the given URL in a new tab.
   *
   * @param url - the url to open
   */
  goToUrl(url: string): void {
    this.window.open(url, '_blank');
  }

  /**
   * Copy the given URL to the clipboard.
   *
   * @param url - the url to copy
   */
  copyToClipboard(url: string): void {
    this.clipboardService.copyFromContent(url);
  }
}
