import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { HelpComponent } from './help/help.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'event/:id', component: MainComponent },
  { path: 'help', component: HelpComponent },
  { path: 'zoom/:level', component: MainComponent },
  { path: 'zoom/:level/:longitude/:latitude', component: MainComponent },
  { path: 'restore/:restoreId', component: MainComponent },
  { path: '**', component: MainComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
