<div class="navbar navbar-default navbar-fixed-top ga-header" role="navigation">
  <div class="container ga-header-container">
    <div class="navbar-header">
      <a href="http://www.ga.gov.au" class="logo">
        <ng-container *ngIf="!deviceCommonService.isMobile()">
          <img id="ga-logo" src="assets/geoscience_inline.png" alt="Australian Government - Geoscience Australia" class="logo"
               title="Australian Government - Geoscience Australia">
        </ng-container>
        <ng-container *ngIf="deviceCommonService.isMobile()">
          <img id="ga-logo-mobile" src="assets/geoscience_inline_mobile.png" alt="Australian Government - Geoscience Australia" class="logo"
               title="Australian Government - Geoscience Australia">
        </ng-container>
      </a>
    </div>
    <div class="application-title" id="navigationBar">
      <h1 class="ng-binding">{{title}}</h1>
    </div>
    <div *ngIf="!deviceCommonService.isMobile()">
      <fh-share *ngIf="shareEnabled && showShare" class="share"></fh-share>
      <fh-auto-refresh *ngIf="autoSaveEnabled" class="auto-refresh"></fh-auto-refresh>
      <a *ngIf="helpEnabled" [routerLink]="['/help']" target="_blank" rel="noopener" class="help">
        <img class="help" src="assets/question-mark-4-32-black.png" width="28" height="28" alt="Help" title="Help"/>
      </a>
    </div>
    <div #dropdown *ngIf="deviceCommonService.isMobile()" class="dropdown mobile-menu" style="white-space: nowrap;" dropdown [autoClose]="false">
      <img class="clickable" src="{{menuIcon}}" width="20" height="20" alt="Menu" title="Menu" dropdownToggle/>
      <ul class="dropdown-menu" *dropdownMenu>
        <li *ngIf="shareEnabled && showShare">
          <fh-share [iconText]="'Share'"></fh-share>
        </li>
        <li>
          <fh-auto-refresh [iconText]="'Auto Refresh'" (menuClicked)="closeMenu($event)"></fh-auto-refresh>
        </li>
        <li>
          <div class="dropdown dropdown-help" style="white-space: nowrap;" dropdown>
            <div dropdownToggle>
              <a [routerLink]="['/help']" target="_blank" rel="noopener" class="help">
                <img class="help" src="assets/question-mark-4-32-black.png" width="28" height="28" alt="Help" title="Help"/><span class="icon-text">&nbsp;Help</span>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
