import { Moment } from 'moment-timezone';

export class SearchCriteria {
  static DATE_STRING_FORMAT: string = 'DD/MM/YYYY HH:mm';
  magnitudeMin: number;
  magnitudeMax: number;
  dateFrom: Moment;
  dateTo: Moment;
  dateFromString: string;
  dateToString: string;
  location: string;

  constructor(magnitudeMin: number, magnitudeMax: number, dateFrom: Moment, dateTo: Moment, location: string) {
    this.magnitudeMin = magnitudeMin;
    this.magnitudeMax = magnitudeMax;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.dateFromString = this.dateFrom.format(SearchCriteria.DATE_STRING_FORMAT);
    this.dateToString = this.dateTo.format(SearchCriteria.DATE_STRING_FORMAT);
    this.location = location;
  }
}
