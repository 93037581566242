import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DateTimeFormatPipe } from './date.time.format';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AwsModule } from './aws.module';
import { HelpLinkComponent } from '@shared/help-link/help-link.component';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [
    DateTimeFormatPipe,
    HelpLinkComponent
  ],
  imports: [
    AwsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    RouterLink,
  ],
  exports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BsDropdownModule,
    DateTimeFormatPipe,
    HelpLinkComponent
  ]
})
export class SharedModule {
}
