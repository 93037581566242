import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SearchComponent } from './search.component';
import { QuakeDetailsModule } from '../quake-details/quake.details.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FlyingHellfishCommonModule, PagerCommonService } from 'flying-hellfish-common';
import { ShareEventModule } from '../share-event/share.event.module';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [
    SearchComponent
  ],
  imports: [
    SharedModule,
    QuakeDetailsModule,
    ShareEventModule,
    BsDropdownModule.forRoot(),
    FlyingHellfishCommonModule,
    RouterLink
  ],
  exports: [
    SearchComponent
  ],
  providers: [
    PagerCommonService
  ]
})
export class SearchModule {
}
