import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, NgZone, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { FeatureType } from '../../types/feature.type';
import { Glossary } from '@shared/glossary';
import { QuakeDetailsService } from './quake.details.service';
import { MapService } from '../../map/map.service';
import * as FileSaver from 'file-saver';
import { AnimationCommon, BoundsCommon, DeviceCommonService, ENVIRONMENT, SidePanelCommonService } from 'flying-hellfish-common';
import { ReplaySubject, Subscription } from 'rxjs';
import { Chart, ChartSeries } from './chart';
import { ShakeMap, SHAKEMAP_DEFAULT } from '../../types/shakemap.type';
import { QuakeDetailsMapComponent } from './quake.details.map.component';
import * as moment from 'moment';
import { AppConstants } from '../../app.constants';
import { FeltReportCount } from '../../types/felt.report.count';
import * as _ from 'lodash';
import { FeltReportCalculation } from '../../types/felt.report';
import { ScaleType } from '@swimlane/ngx-charts';

export class InteractionConstants {
  public static readonly FOCAL_MECHANISM: string = 'focalMechanism';
  public static readonly FORMAL: string = 'formal';
  public static readonly MAGNITUDE: string = 'magnitude';
  public static readonly QUALITY: string = 'quality';
  public static readonly SEISMOGRAM: string = 'seismogram';
  public static readonly DOWNLOADS: string = 'downloads';
  public static readonly SOLUTION: string = 'solution';
  public static readonly STATIONS: string = 'stations';
  public static readonly FELT_CHART: string = 'feltChart';
}

@Component({
  selector: 'ga-quake-details',
  templateUrl: 'quake.details.component.html',
  styleUrls: ['quake.details.component.css'],
  preserveWhitespaces: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    AnimationCommon.animationCommonEnter500Leave500,
    AnimationCommon.animationCommonVerticalEnter500Leave500,
    AnimationCommon.animationCommonEnter300
  ]
})
export class QuakeDetailsComponent implements OnChanges, OnDestroy {
  @ViewChild(QuakeDetailsMapComponent) quakeDetailsMapComponent: QuakeDetailsMapComponent;

  glossary: Glossary;
  showExportProgressBar: boolean = false;
  stationsLoaded: boolean = false;
  stations: any;
  magnitude: any;
  stationsURL: string = this.environment.serviceUrls.earthQuakeStationsWFS;
  isFeaturePreSeiscomp: boolean = false;
  focalMechanism: any;
  skipCloudFrontUrl: string = this.environment.serviceUrls.skipCloudFrontUrl;
  interactions: Map<string, { enabled: boolean, visible: boolean }> = new Map();
  constants: typeof InteractionConstants = InteractionConstants;
  chart: Chart = new Chart();
  showFeltSpinner: boolean = false;
  shakeMap: ShakeMap = SHAKEMAP_DEFAULT;
  shakeMapDownloads: { shapeFile: string, json: string, kml: string, kmz: string };
  feltReportCount: number = 0;
  ordinalScaleType: ScaleType = ScaleType.Ordinal;
  subscription: Subscription = new Subscription();

  @Input() feature: FeatureType;
  @Input() toolId: string;

  constructor(private quakeDetailsService: QuakeDetailsService, private mapService: MapService, private ref: ChangeDetectorRef,
              private deviceCommonService: DeviceCommonService, private sidePanelCommonService: SidePanelCommonService, private zone: NgZone,
              @Inject(ENVIRONMENT) private environment: any) {
    this.mapService.setZone(zone);
    this.glossary = new Glossary();

    // Fired when an event is passed in via the url
    this.subscription.add(mapService.identifyEventFeatureEmitter.subscribe(
      feature => {
        this.feature = feature.properties;
        // Added delay to let the base map render first
        setTimeout(() => {
          this.zoomToEarthquake(feature);
          this.mapService.identifyEventFeatureEmitter = new ReplaySubject(1);
        }, 500);
        this.ngOnChanges();
      }
    ));

    this.chart.yAxisLabel = 'Felt reports per 10 minutes';
    this.chart.xAxisLabel = 'Hours after event';
    this.chart.colorScheme = {
      selectable: true,
      group: 'Ordinal',
      domain: [
        '#50abcc', '#a8385d'
      ]
    };

    this.initialiseInteractions();
  }

  initialiseInteractions(): void {
    this.interactions.set(InteractionConstants.MAGNITUDE, {
      enabled: true,
      visible: false
    });
    this.interactions.set(InteractionConstants.FORMAL, {
      enabled: true,
      visible: false
    });
    this.interactions.set(InteractionConstants.QUALITY, {
      enabled: true,
      visible: false
    });
    this.interactions.set(InteractionConstants.SOLUTION, {
      enabled: true,
      visible: false
    });
    this.interactions.set(InteractionConstants.STATIONS, {
      enabled: false,
      visible: false
    });
    this.interactions.set(InteractionConstants.FOCAL_MECHANISM, {
      enabled: false,
      visible: false
    });
    this.interactions.set(InteractionConstants.SEISMOGRAM, {
      enabled: false,
      visible: false
    });
    this.interactions.set(InteractionConstants.DOWNLOADS, {
      enabled: false,
      visible: false
    });
    this.interactions.set(InteractionConstants.FELT_CHART, {
      enabled: false,
      visible: false
    });
  }

  toggleInteraction(interaction: string): void {
    this.interactions.set(interaction, {
      enabled: this.interactions.get(interaction).enabled,
      visible: !this.interactions.get(interaction).visible
    });

    if (interaction === InteractionConstants.STATIONS) {
      this.toggleStations();
    } else if (interaction === InteractionConstants.FELT_CHART) {
      this.toggleFeltChart();
    } else if (interaction === InteractionConstants.MAGNITUDE) {
      this.toggleMagnitude();
    }
  }

  // When Magnitude interaction is visible call on service to get magnitudes from the server
  toggleMagnitude(): void {
    if (this.interactions.get(InteractionConstants.MAGNITUDE).visible) {
      // Get the stations for the selected quake
      if (this.feature) {
        this.subscription.add(this.quakeDetailsService.getMagnitude(this.feature.earthquake_id)
          .subscribe({
            next: data => {
              this.magnitude = data.features;
            },
            error: error => console.error(error),
            complete: () => {
              if (this.magnitude.length === 0) {
                this.interactions.set(InteractionConstants.MAGNITUDE, {
                  enabled: true,
                  visible: false
                });
              }
              this.ref.markForCheck();
            }
          }));
      }
    }
  }

  // Returns true if the evaluation status of the magnitude is null (existing magnitudes) or if status is not rejected or reported
  canDisplayMagnitude(magProperties: any): boolean {
    return magProperties.evaluation_status === null || (magProperties.evaluation_status !== 'rejected' && magProperties.evaluation_status !== 'reported');
  }

  toggleStations(): void {
    this.quakeDetailsMapComponent.toggleStations(this.interactions.get(InteractionConstants.STATIONS).visible);

    // Performs different layer functions depending on if we are in search or in identify
    if (this.interactions.get(InteractionConstants.STATIONS).visible) {
      // Get the stations for the selected quake
      if (this.feature) {
        this.subscription.add(this.quakeDetailsService.getStations(this.feature.event_id)
          .subscribe({
            next: data => {
              this.stations = data.features;
              this.stationsLoaded = true;
              if (this.toolId === 'search') {
                this.mapService.createStationsLayer(this.stationsURL + `&CQL_FILTER=event_id='${this.feature.event_id}'`);
              } else {
                this.mapService.createEarthQuakeLayerForFeature(this.feature);
                this.mapService.showRecentEarthquakesLayer('QuakeDetails', false);
                this.mapService.createStationsLayer(this.stationsURL + `&CQL_FILTER=event_id='${this.feature.event_id}'`);
              }
            },
            error: error => console.error(error),
            complete: () => this.stationsReturned()
          }));
      }
    } else if (this.toolId === 'search') {
      this.mapService.removeStationsLayer();
    } else {
      this.mapService.removeStationsLayer();
      this.mapService.removeEarthQuakeLayerForFeature();
      this.mapService.showRecentEarthquakesLayer('QuakeDetails', true);
    }
  }

  // Exports a CSV file containing the stations
  exportCsv(): void {
    let criteria: string = `event_id='${this.feature.event_id}'`;
    this.showExportProgressBar = true;

    this.subscription.add(this.quakeDetailsService.getCsvExport('&CQL_FILTER=' + criteria).subscribe({
      next: (response) => {
        criteria = '"Search criteria: ' + criteria + '"\r\n\r\n';

        const parts: any[] = [];
        parts.push(criteria);
        parts.push(response);
        const complete: Blob = new Blob(parts);

        const filename: string = 'earthquake_stations_export.csv';
        FileSaver.saveAs(complete, filename);
      },
      error: (error) => {
        console.error(error);
        this.showExportProgressBar = false;
        this.ref.markForCheck();
      },
      complete: () => {
        this.showExportProgressBar = false;
        this.ref.markForCheck();
      }
    }));
  }

  // Check if the value is null, and if not check that it is greater than zero
  checkNumericValue(value: number): boolean {
    if (value != null) {
      return value > 0;
    } else {
      return false;
    }
  }

  // Determine hours since event
  getHoursSinceEvent(): number {
    const minimumReportingHours: number = 2;
    let hoursSinceEvent: number;
    if (this.feature.hours_since_quake > AppConstants.HOURS_PER_DAY) {
      hoursSinceEvent = AppConstants.HOURS_PER_DAY;
    } else if (this.feature.hours_since_quake < minimumReportingHours) {
      hoursSinceEvent = minimumReportingHours;
    } else {
      hoursSinceEvent = Math.ceil(this.feature.hours_since_quake);
    }
    return hoursSinceEvent;
  }

  // Clicking on the felt report header should prepare the data
  toggleFeltChart(): void {
    if (this.interactions.get(InteractionConstants.FELT_CHART).visible) {
      this.showFeltSpinner = true;
    }

    const feltReportCalculation: FeltReportCalculation = {
      eventId: this.feature.event_id,
      originTime: this.feature.origin_time,
      hoursSinceEvent: this.getHoursSinceEvent(),
      reportCountInterval: 10
    };
    this.subscription.add(this.quakeDetailsService.getFeltReportCounts(feltReportCalculation)
      .subscribe({
        next: (feltReportCounts: FeltReportCount[]) => {
          const series: ChartSeries[] = [];
          const cumulativeSeries: ChartSeries[] = [];
          let cumulative: number = 0;
          this.chart.config = [];
          this.chart.xAxisTicks = _.range(0, feltReportCalculation.hoursSinceEvent, 1);
          for (const feltReportCount of feltReportCounts) {
            cumulative += feltReportCount.felt_reports;
            const reportsInHour: number = feltReportCount.minute / AppConstants.MINUTES_PER_HOUR;
            series.push({
              value: feltReportCount.felt_reports,
              name: reportsInHour
            });
            cumulativeSeries.push({
              value: cumulative,
              name: reportsInHour
            });
          }
          this.chart.config.push({
            name: 'Previous 10 minutes',
            series: series
          });
          this.chart.config.push({
            name: 'Cumulative',
            secondAxis: true,
            series: cumulativeSeries
          });
          this.showFeltSpinner = false;
          this.ref.markForCheck();
        },
        error: (error) => {
          console.error(error);
        }
      }));
  }

  ngOnChanges(): void {
    this.reset();

    if (!this.deviceCommonService.isMobile()) {
      // Zoom to the bounds of the feature
      const currentZoom: number = this.mapService.mapInstance.getView().getZoom();
      const bounds: BoundsCommon = new BoundsCommon(this.feature.longitude, this.feature.latitude, this.feature.longitude, this.feature.latitude);
      this.mapService.zoomToBoundsWithPadding(bounds.toEpsg3857(), 1500, [100, this.sidePanelCommonService.sidePanelRight.width + 50, 100, 50], Math.max(7, currentZoom));
    }

    // Check if the event has Shakemap
    this.subscription.add(this.quakeDetailsService.getShakeMap(this.feature.event_id).subscribe((response) => {
      this.shakeMap = response;

      if (this.shakeMap.shakemap_enabled === 'Y') {
        this.shakeMapDownloads.kmz = this.getShakeMapResourcePath(this.feature, this.shakeMap, 'kmz');
        this.shakeMapDownloads.kml = this.getShakeMapResourcePath(this.feature, this.shakeMap, 'kml');
        this.shakeMapDownloads.json = this.getShakeMapResourcePath(this.feature, this.shakeMap, 'json');
        this.shakeMapDownloads.shapeFile = `/shakemap/${this.feature.event_id}/v${this.shakeMap.shakemap_version}/${this.feature.event_id}.shp.zip`;
      }

      this.ref.markForCheck();
    }));

    // Check if magnitudes exist
    if (!(this.feature.mb == null && this.feature.ms == null && this.feature.mla == null && this.feature.mw == null && this.feature.mwp == null && this.feature.mwmwp == null)) {
      this.interactions.get(InteractionConstants.MAGNITUDE).enabled = true;
    }

    // Check if the event has a Seismogram
    this.subscription.add(this.quakeDetailsService.getSeismogram(this.feature.event_id)
      .subscribe({
        next: data => {
          this.interactions.get(InteractionConstants.SEISMOGRAM).enabled = data;
          this.ref.markForCheck();
        },
        error: error => {
          console.error(error);
          this.interactions.get(InteractionConstants.SEISMOGRAM).enabled = false;
        }
      }));

    // Check if creation date is before Seiscomp EQ@GA AWS release date
    this.isFeaturePreSeiscomp = this.quakeDetailsService.isFeaturePreSeiscomp(this.feature);

    // Get the focal mechanism for this feature
    this.subscription.add(this.quakeDetailsService.getFocalMechanism(this.feature.earthquake_id)
      .subscribe({
        next: data => {
          this.focalMechanism = data.features;

          // Check if there is a focal mechanism
          if (data.totalFeatures > 0) {
            this.interactions.set('focalMechanism', {
              enabled: true,
              visible: false
            });
          }

          this.ref.markForCheck();
        },
        error: error => console.error(error)
      }));

    // Check if the event has ShakeMap downloads
    this.subscription.add(this.quakeDetailsService.hasShakeMapDownloads(this.feature.event_id)
      .subscribe({
        next: data => {
          this.interactions.get(InteractionConstants.DOWNLOADS).enabled = data;
          this.ref.markForCheck();
        },
        error: error => console.error(error)
      }));

    // Check if the event has felt reports
    this.subscription.add(this.quakeDetailsService.getFeltReportsForEvent(this.feature.event_id).subscribe({
      next: (response) => {
        if (response.totalFeatures > 0) {
          this.interactions.get(InteractionConstants.FELT_CHART).enabled = true;
          this.feltReportCount = response.totalFeatures;
        } else {
          this.interactions.get(InteractionConstants.FELT_CHART).enabled = false;
          this.feltReportCount = 0;
        }
        this.ref.markForCheck();
      },
      error: (error) => {
        console.log(error);
      }
    }));
  }

  /**
   * Create path to ShakeMap resource, adding zip extension when the resource includes a bundled disclaimer
   *
   * @param earthquake the selected earthquake
   * @param shakeMap the associated shakemap
   * @param extension resource file extension
   */
  private getShakeMapResourcePath(earthquake: FeatureType, shakeMap: ShakeMap, extension: string): string {
    let path: string = `/shakemap/${earthquake.event_id}/v${shakeMap.shakemap_version}/${earthquake.event_id}.${extension}`;
    if (shakeMap.shakemap_disclaimer === 'Y') {
      path += '.zip';
    }
    return path;
  }

  // When the service returns update the UI
  stationsReturned(): void {
    if (this.stations.length === 0) {
      this.interactions.set(InteractionConstants.STATIONS, {
        enabled: true,
        visible: false
      });
    }
    this.ref.markForCheck();
  }

  zoomToEarthquake(earthquake: FeatureType): void {
    const features: FeatureType[] = [];
    features.push(earthquake);

    // Fit the features on the map minus the panel for desktop and tablets
    const screenWidth: number = this.deviceCommonService.getScreenWidth();
    if (screenWidth > 1400) {
      this.mapService.zoomToExtentWithOffset(features, 100, 7);
    } else if (screenWidth >= 1024 && screenWidth <= 1400) {
      this.mapService.zoomToExtentWithOffset(features, 200, 5);
    } else if (screenWidth < 1024) {
      this.mapService.zoomToExtentWithOffset(features, 400, 5);
    }
  }

  // When a user clicks on a station zoom the map to the stations coordinates
  zoomToStation(station: any): void {
    if (!this.deviceCommonService.isMobile() && !this.deviceCommonService.isTablet()) {
      if (station.properties.latitude && station.properties.longitude) {
        this.mapService.setMapPositionAnimated(station.properties.latitude, station.properties.longitude, 9, 2000);
      }
    }
  }

  // If a station name is not available use the station code
  getStationTitle(station: any): string {
    if (station.properties.station_name !== null) {
      return station.properties.station_name;
    } else {
      return station.properties.station_code;
    }
  }

  // Pre Seiscomp earthquakes use an evaluation status of final
  getReviewStatus(status: any): string {
    const reviewedStatusCodes: string[] = ['FINL', 'final'];

    if (reviewedStatusCodes.includes(status)) {
      return 'reviewed';
    }

    return status;
  }

  // Was this event before the addition of the 'final' evaluation status to Seiscomp
  private isEventPreFinalStatus(event: FeatureType): boolean {
    return moment(event.event_creation_time).isBefore(this.environment.finalStatusEffectiveDate);
  }

  // If reported felt is Y return Yes
  getReportedFelt(felt: string): string {
    if (felt === 'Y') {
      return 'Yes';
    }
  }

  // Return the visible property of the interaction
  isInteractionVisible(interaction: string): boolean {
    return this.interactions.get(interaction).visible;
  }

  // Return the enabled property of the interaction
  isInteractionEnabled(interaction: string): boolean {
    return this.interactions.get(interaction).enabled;
  }

  // Force JSON file to download rather than display in browser
  downloadJson(url: string, filename: string): void {
    this.subscription.add(this.quakeDetailsService.downloadJson(url).subscribe((file) => {
      FileSaver.saveAs(new Blob([JSON.stringify(file)], {
        type: 'application/json'
      }), filename);
    }));
  }

  // Force XML file to download rather than display in browser
  downloadXML(url: string, filename: string): void {
    this.subscription.add(this.quakeDetailsService.getDataAsTextFromUrl(url).subscribe((data) => {
      FileSaver.saveAs(new Blob([data], {
        type: 'application/xml'
      }), filename);
    }));
  }

  // Formatting function for the felt report chart xAxis.
  formatXAxisTicks(tickLabel: number): string {
    if (tickLabel % 2 === 0) {
      return tickLabel.toString();
    }
    return '';
  };

  reset(): void {
    this.initialiseInteractions();
    this.stationsLoaded = false;
    this.shakeMap = SHAKEMAP_DEFAULT;
    this.shakeMapDownloads = { shapeFile: null, json: null, kml: null, kmz: null };
    this.mapService.removeStationsLayer();
    if (this.sidePanelCommonService.sidePanelRight.activeToolId !== 'search') {
      this.mapService.resetRecentEarthquakesLayer();
    }
  }

  ngOnDestroy(): void {
    this.reset();
    this.subscription.unsubscribe();
  }
}
