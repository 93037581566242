import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MapService } from '../map/map.service';
import { NeotectonicFeature } from '../types/neotectonic.feature.type';
import Overlay from 'ol/Overlay';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ga-neotectonics',
  templateUrl: 'neotectonics.component.html',
  styleUrls: ['neotectonics.component.css'],
  preserveWhitespaces: true
})
export class NeotectonicsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('popup', { read: ElementRef }) popup: ElementRef;
  neotectonicsOverlay: Overlay;
  feature: NeotectonicFeature;
  subscriptions: Subscription = new Subscription();

  constructor(private mapService: MapService) {
    this.subscriptions.add(mapService.neotectonicsFeatureEmitter.subscribe((data: any) => {
      if (data) {
        this.feature = data.feature.getProperties();
        this.neotectonicsOverlay.setPosition(this.mapService.getCoordinateFromPixel(data.pixel));
      } else {
        this.closePopup();
      }
    }));
  }

  // Closes the neotectonics popup
  closePopup(): void {
    this.neotectonicsOverlay.setPosition(undefined);
    this.popup.nativeElement.blur();
  }

  // Open the neotectonics website with the selected feature
  viewFeatureDetails(): void {
    window.open('https://neotectonics.ga.gov.au/feature/' + this.feature.feature_id, '_blank');
    this.closePopup();
  }

  ngAfterViewInit(): void {
    // Setup the overlay for the neotectonics popup
    this.neotectonicsOverlay = new Overlay({
      element: this.popup.nativeElement,
      autoPan: true
    });

    // Add the overlay to the map
    this.mapService.addOverlay(this.neotectonicsOverlay);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
