<h4>Legend</h4>
<hr>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-4 col-xs-4 text-center legendRed"><label>Last 4 hours</label></div>
    <div class="col-sm-4 col-xs-4 text-center legendOrange"><label>Last 24 hours</label></div>
    <div class="col-sm-4 col-xs-4 text-center legendYellow"><label>Older</label></div>
  </div>
  <br>
  <br>
  <div class="row">
    <div class="col-sm-12 text-center">
      <label>Magnitude 9+</label>
      <br>
      <svg height="80" width="80">
        <circle cx="40" cy="40" r="40" stroke-width="3" fill="#000000"/>
      </svg>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 text-center">
      <label>Magnitude 8 - 8.9</label>
      <br>
      <svg height="70" width="70">
        <circle cx="35" cy="35" r="35" stroke-width="3" fill="#000000"/>
      </svg>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 text-center">
      <label>Magnitude 7 - 7.9</label>
      <br>
      <svg height="70" width="60">
        <circle cx="30" cy="30" r="30" stroke-width="3" fill="#000000"/>
      </svg>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 text-center">
      <label>Magnitude 6 - 6.9</label>
      <br>
      <svg height="50" width="50">
        <circle cx="25" cy="25" r="25" stroke-width="3" fill="#000000"/>
      </svg>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 text-center">
      <label>Magnitude 5 - 5.9</label>
      <br>
      <svg height="50" width="40">
        <circle cx="20" cy="20" r="20" stroke-width="3" fill="#000000"/>
      </svg>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 text-center">
      <label>Magnitude 4 - 4.9</label>
      <br>
      <svg height="50" width="50">
        <circle cx="25" cy="15" r="15" stroke-width="3" fill="#000000"/>
      </svg>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 text-center">
      <label>Magnitude 3 - 3.9</label>
      <br>
      <svg height="50" width="50">
        <circle cx="25" cy="10" r="10" stroke-width="3" fill="#000000"/>
      </svg>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 text-center">
      <label>Magnitude < 3</label>
      <br>
      <svg height="50" width="50">
        <circle cx="25" cy="5" r="5" stroke-width="3" fill="#000000"/>
      </svg>
    </div>
  </div>
</div>
