<div class="heading">
  <div>
    <h4 class="tool-title">Search
      <ga-help-link [linkFragment]="'search'"></ga-help-link>
    </h4>
  </div>
  <div *ngIf="showQuakeDetail">
    <ga-toolbar-share-event [feature]="selectedQuakeDetail"></ga-toolbar-share-event>
  </div>
</div>
<hr style="margin-top: 0">
<div>
  <form (ngSubmit)="search()" #searchForm="ngForm" *ngIf="showCriteria" autocomplete="off">
    <div class="search-group">
      <label id="locationMandatory">Location: (Mandatory *)</label><br>
      <label class="container radio-inline">Australia<input type="radio" name="location" value="Australia"
                                                            [(ngModel)]="searchCriteria.location" (click)="resetRecentQuakes()">
        <span class="checkmark"></span></label>
      <label class="container radio-inline">World<input type="radio" name="location" value="World"
                                                        [(ngModel)]="searchCriteria.location" (click)="resetRecentQuakes()">
        <span class="checkmark"></span></label>
    </div>
    <div class="search-group-mutli-column">
      <div class="row">
        <div class="col-xs-6">
          <label for="magnitudeMin">Magnitude Min: *</label>
        </div>
        <div class="col-xs-6">
          <label for="magnitudeMax">Magnitude Max: *</label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <input type="text" class="form-control" id="magnitudeMin" name="magnitudeMin"
                 [(ngModel)]="searchCriteria.magnitudeMin"
                 required>
        </div>
        <div class="col-xs-6">
          <input type="text" class="form-control" id="magnitudeMax" name="magnitudeMax"
                 [(ngModel)]="searchCriteria.magnitudeMax"
                 required>
        </div>
      </div>
    </div>
    <div class="search-group-mutli-column" *ngIf="!deviceCommonService.isMobileOrTablet()">
      <div class="row">
        <div class="col-xs-6">
          <label for="dateFrom">Date From (UTC): *</label>
        </div>
        <div class="col-xs-6">
          <label for="dateTo">Date To (UTC): *</label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <div class="input-group">
            <input class="form-control dateFrom" id="dateFrom"
                   fhDateTimePicker
                   [date]="searchCriteria.dateFrom"
                   [options]="dateOptions"
                   (onChange)="dateFromChange($event)"
                   required/>
            <span class="input-group-addon">
              <span class="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="input-group" id="dateTo">
            <input class="form-control dateTo"
                   fhDateTimePicker
                   [date]="searchCriteria.dateTo"
                   [options]="dateOptions"
                   (onChange)="dateToChange($event)"/>
            <span class="input-group-addon">
              <span class="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="search-group-mutli-column" *ngIf="deviceCommonService.isMobileOrTablet()">
      <div class="row">
        <div class="col-xs-6">
          <label for="dateFrom">Date From:</label>
        </div>
        <div class="col-xs-6">
          <label for="dateTo">Date To:</label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <input type="text" class="form-control" name="dateFrom"
                 [(ngModel)]="searchCriteria.dateFromString"
                 (ngModelChange)="onDateFromStringChange($event)"
                 required
                 pattern="^(0[1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d [012]{0,1}[0-9]:[0-6][0-9]$">
        </div>
        <div class="col-xs-6">
          <input type="text" class="form-control" name="dateTo"
                 [ngModel]="searchCriteria.dateToString"
                 (ngModelChange)="onDateToStringChange($event)"
                 required
                 pattern="^(0[1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d [012]{0,1}[0-9]:[0-6][0-9]$">
        </div>
      </div>
    </div>
    <div class="search-group">
      <label for="magnitudeMin">Recent Earthquakes:</label>
      <div *ngIf="searchCriteria.location === 'World'">
        <ng-container *ngIf="recentQuakesFormatted.length > 1">
          <div class="dropdown" dropdown>
            <button type="button" class="btn btn-ga btn-dropdown text-overflow-ellipsis" dropdownToggle>{{ selectedRecentQuake ? selectedRecentQuake.text : 'Please select' }}
              <span class="caret"></span></button>
            <ul class="dropdown-menu btn-dropdown" *dropdownMenu>
              <li *ngFor="let feature of recentQuakesFormatted" [class.active]="feature === selectedRecentQuake">
                <a href="javascript:void(0)" (click)="showRecentQuakeRadius(feature)">{{ feature.text }}</a>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngIf="recentQuakesFormatted.length === 1">
          <fh-alert [message]="'No recent earthquakes found.'" [type]="'info'"></fh-alert>
        </ng-container>
      </div>
      <div *ngIf="searchCriteria.location === 'Australia'">
        <ng-container *ngIf="recentQuakesAustraliaFormatted.length > 1">
          <div class="dropdown" dropdown>
            <button type="button" class="btn btn-ga btn-dropdown text-overflow-ellipsis" dropdownToggle>{{ selectedRecentQuake ? selectedRecentQuake.text : 'Please select' }}
              <span class="caret"></span></button>
            <ul class="dropdown-menu btn-dropdown" *dropdownMenu>
              <li *ngFor="let feature of recentQuakesAustraliaFormatted" [class.active]="feature === selectedRecentQuake">
                <a href="javascript:void(0)" (click)="showRecentQuakeRadius(feature)">{{ feature.text }}</a>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngIf="recentQuakesAustraliaFormatted.length === 1">
          <fh-alert [message]="'No recent earthquakes found in Australia.'" [type]="'info'"></fh-alert>
        </ng-container>
      </div>
    </div>
    <div class="search-group" *ngIf="selectedRecentQuake.text !== 'Please select'">
      <label for="magnitudeMin">Radius from Recent Earthquake:</label>
      <div>
        <div class="dropdown" dropdown>
          <button type="button" class="btn btn-ga btn-dropdown" dropdownToggle>{{ selectedRecentQuakeRadius }}
            <span class="caret"></span></button>
          <ul class="dropdown-menu btn-dropdown" *dropdownMenu>
            <li *ngFor="let radius of recentQuakesRadius" [class.active]="radius === selectedRecentQuakeRadius">
              <a href="javascript:void(0)" (click)="updateRecentQuakeRadius(radius)">{{ radius }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="search-group">
      <label for="magnitudeMin">State:</label>
      <div>
        <div class="dropdown" dropdown>
          <button type="button" class="btn btn-ga btn-dropdown" dropdownToggle>{{ selectedState }}
            <span class="caret"></span></button>
          <ul class="dropdown-menu btn-dropdown" *dropdownMenu>
            <li *ngFor="let state of statesList" [class.active]="state === selectedState">
              <a href="javascript:void(0)" (click)="updateState(state)">{{ state }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="search-group" *ngIf="!deviceCommonService.isMobileOrTablet()">
      <label>Results Per Page:</label>
      <div>
        <div class="dropdown" dropdown>
          <button type="button" class="btn btn-ga btn-dropdown" dropdownToggle>{{ maxResultsPerPage }}
            <span class="caret"></span></button>
          <ul class="dropdown-menu btn-dropdown" *dropdownMenu>
            <li *ngFor="let limit of resultsPerPageList" [class.active]="limit === maxResultsPerPage">
              <a href="javascript:void(0)" (click)="maxResultsPerPage = limit">{{ limit }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="search-group" *ngIf="!deviceCommonService.isMobileOrTablet()">
      <div style="text-align:center">
        <button id="drawClearExtentButton" type="button" class="btn btn-ga draw-btn"
                (click)="draw()">{{ drawActive ? 'Clear' : 'Draw' }}
        </button>
        <label class="container radio-inline" style="width: 50px">Box<input type="radio" name="box" value="Box"
                                                                            [(ngModel)]="drawType"
                                                                            (change)="resetDraw()"><span
          class="checkmark"></span></label>
        <label class="container radio-inline" style="width: 60px">Circle<input type="radio" name="circle"
                                                                               value="Circle" [(ngModel)]="drawType"
                                                                               (change)="resetDraw()"><span
          class="checkmark"></span></label>
        <label class="container radio-inline">Polygon<input type="radio" name="polygon" value="Polygon"
                                                            [(ngModel)]="drawType" (change)="resetDraw()"><span
          class="checkmark"></span></label>
      </div>
      <ng-container *ngIf="drawActive">
        <div class="draw-help" *ngIf="drawType === 'Circle' && drawInteractionActive && radius$ | async as radius;">
          Radius: {{ radius | number:'1.0-0' }}km
        </div>
      </ng-container>
      <div class="draw-help" *ngIf="drawInteractionActive && drawType === 'Box'">Click to start drawing, then click to complete drawing.
      </div>
      <div class="draw-help" *ngIf="drawInteractionActive && drawType === 'Circle'">Click to start drawing, then click to complete drawing.
      </div>
      <div class="draw-help" *ngIf="drawInteractionActive && drawType === 'Polygon'">Click to start drawing, then click the start point to complete drawing.
      </div>
      <div class="draw-help" *ngIf="!drawInteractionActive && drawBoundsText !== null">{{ drawBoundsText }}</div>
      <div *ngIf="showDrawError" style="margin-top: 15px">
        <fh-alert
          [message]="'International Dateline crossed. Circle and Polygon must be in the same -180 to 180 Dateline. Please select Box when drawing across Datelines.'"
          [type]="'error'"></fh-alert>
      </div>
    </div>
    <hr>
    <div class="progress" [hidden]="!showSearchProgressBar">
      <div class="progress-bar progress-bar-info progress-bar-striped active" role="progressbar"
           aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width:100%">
        Searching
      </div>
    </div>
    <div>
    </div>
    <div class="text-center map-controls">
      <button id="clearButton" class="btn btn-ga" type="button" (click)="clearForm()">Clear</button>
      <button id="searchButton" class="btn btn-ga" type="submit" [disabled]="!searchForm.form.valid || showDrawError">
        Search
      </button>
    </div>
  </form>

  <div *ngIf="showResults" [@animationCommonEnter500]>
    <fh-pager-back-end
      [defaultColumn]="resultsTableColumns[1].displayText"
      [defaultSortOrder]="'D'"
      [features]="features"
      [maxRowsPerPage]="maxResultsPerPage"
      [totalFeatures]="searchResultsCount"
      [columns]="resultsTableColumns"
      [loading]="showProgressBar"
      [errorMessage]="errorMessage"
      [title]="'Click to display Earthquake details'"
      (selectedRowEmitter)="showQuakeDetails($event)"
      (onChange)="onPagerChange($event)">
    </fh-pager-back-end>
    <div class="progress" [hidden]="!showExportProgressBar">
      <div class="progress-bar progress-bar-info progress-bar-striped active" role="progressbar"
           aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width:100%">
        Exporting CSV
      </div>
    </div>
    <div id="search-btns" class="text-center">
      <button id="refineSearchButton" class="btn btn-ga" (click)="refineSearch()">
        <img src="assets/arrow-122-16.png" alt="Refine Search" height="14" width="14"/>&nbsp;Refine Search
      </button>
      <button id="exportCsvButton" class="btn btn-ga" (click)="exportCsv()" *ngIf="searchResultsCount!==0">Export CSV
      </button>
    </div>
  </div>

  <div *ngIf="showQuakeDetail">
    <ga-quake-details [feature]="selectedQuakeDetail" [toolId]="'search'"></ga-quake-details>
    <hr>
    <div class="text-center btn-back">
      <button id="backButton" class="btn btn-ga" (click)="returnToResults()">
        <img src="assets/arrow-122-16.png" alt="Back" height="14" width="14"/>&nbsp;Back
      </button>
    </div>
  </div>
</div>
