import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { IdentifyComponent } from './identify.component';
import { QuakeDetailsModule } from '../quake-details/quake.details.module';
import { FeltQuakeModule } from '../felt-quake/felt.quake.module';
import { ShareEventModule } from '../share-event/share.event.module';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [
    IdentifyComponent
  ],
  imports: [
    SharedModule,
    QuakeDetailsModule,
    FeltQuakeModule,
    ShareEventModule,
    RouterLink
  ],
  exports: [
    IdentifyComponent
  ]
})
export class IdentifyModule {
}
