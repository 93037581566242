import JSZip from 'jszip';
import { KML } from 'ol/format';
import { Options as KMLOptions } from 'ol/format/KML';
import { ReadOptions } from 'ol/format/Feature';
import { Geometry } from 'ol/geom';
import { Feature } from 'ol';
import VectorSource, { Options as VectorSourceOptions } from 'ol/source/Vector';
import { Extent } from 'ol/extent';
import { Projection } from 'ol/proj';

export class KMZ extends KML {
  private extractedArrayBuffers: Map<string, ArrayBuffer> = new Map();

  constructor(options?: KMLOptions) {
    super({
      ...options,
      iconUrlFunction: href => this.iconUrlFunction(href)
    });
  }

  /**
   * Finds the first KML file in the map and parses it as an XMLDocument
   */
  getKMLDocument(extractedArrayBuffers: Map<string, ArrayBuffer>): XMLDocument {
    const kmlKey: string = [...extractedArrayBuffers.keys()].find(key => key.endsWith('.kml'));
    const kmlBuffer: ArrayBuffer = extractedArrayBuffers.get(kmlKey);
    const kmlData: string = new TextDecoder().decode(kmlBuffer);
    const kmlDocument: XMLDocument = new DOMParser().parseFromString(kmlData, 'text/xml');
    return kmlDocument;
  }

  /**
   * Loads a map icon from the ArrayBuffer map
   */
  iconUrlFunction(href: string): string {
    const index: number = href.lastIndexOf('/');
    if (index === -1) {
      return href;
    }
    const fileName: string = href.slice(index + 1);
    const icon: ArrayBuffer = this.extractedArrayBuffers.get(fileName);
    if (icon) {
      return URL.createObjectURL(new Blob([icon]));
    }
    return href;
  }

  /**
   * Set the ArrayBuffer map so it can be used by this.iconUrlFunction
   */
  setExtractedArrayBuffers(extractedArrayBuffers: Map<string, ArrayBuffer>): void {
    this.extractedArrayBuffers = extractedArrayBuffers;
  }

  /**
   * Overload the readFeatures function so we can convert it to an XML document before calling super.readFeatures
   */
  readFeatures(extractedArrayBuffers: Map<string, ArrayBuffer>, options?: ReadOptions): Feature<Geometry>[] {
    return super.readFeatures(this.getKMLDocument(extractedArrayBuffers), options);
  }
}

export class KMZVectorSource extends VectorSource {
  constructor(options?: VectorSourceOptions<Geometry>) {
    if (typeof options.url !== 'string') {
      throw new TypeError('Can only load .kmz file from string');
    }
    const kmzUrl: string = options.url;
    // Delete the url from the options to prevent loading of zip url in subsequent super calls
    delete options.url;
    super({
      ...options,
      loader: (extent, resolution, projection, success, failure): void => {
        this.loadKMZ(extent, resolution, projection, success, failure, kmzUrl);
      },
      format: new KMZ()
    });
  }

  /**
   * Load a KMZ file from a url, extract it, and read the features that are contained within
   */
  loadKMZ(extent: Extent, resolution: number, projection: Projection, success: (features: Feature<Geometry>[]) => void, failure: () => void, zipUrl: string): void {
    fetch(zipUrl).then(response => {
      return response.arrayBuffer();
    }).then(arrayBuffer => {
      return this.extract(arrayBuffer);
    }).then(extractedArrayBuffers => {
      const format: KMZ = this.getFormat() as KMZ;
      format.setExtractedArrayBuffers(extractedArrayBuffers);
      const features: Feature<Geometry>[] = format.readFeatures(extractedArrayBuffers, {
        featureProjection: projection,
        extent
      });
      this.addFeatures(features);
      success(features);
    }).catch(() => failure());
  }

  /**
   * Extracts files from an archive and returns the ArrayBuffers in a map with the filename as the key
   */
  async extract(buffer: ArrayBuffer): Promise<Map<string, ArrayBuffer>> {
    const extractedArrayBuffersMap: Map<string, ArrayBuffer> = new Map();
    const archive: JSZip = await JSZip.loadAsync(buffer);
    const setMap: (filename: string) => Promise<void> = async (filename) => {
      try {
        const data: ArrayBuffer = await archive.files[filename].async('arraybuffer');
        extractedArrayBuffersMap.set(filename, data);
      } catch (error) {
        throw new Error(`Failed to extract ${filename} from archive: ${error}`);
      }
    };
    await Promise.all(Object.keys(archive.files).map((filename) => setMap(filename)));
    return extractedArrayBuffersMap;
  }
}
