<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [legendType]="legendType"
  [activeEntries]="activeEntries"
  [animations]="animations"
  (legendLabelClick)="onClick($event)"
  (legendLabelActivate)="onActivate($event)"
  (legendLabelDeactivate)="onDeactivate($event)">

  <svg:defs>
    <svg:clipPath [attr.id]="clipPathId">
      <svg:rect
        [attr.width]="dims.width + 10"
        [attr.height]="dims.height + 10"
        [attr.transform]="'translate(-5, -5)'"/>
    </svg:clipPath>
  </svg:defs>
  <svg:g [attr.transform]="transform" class="line-chart chart">
    <svg:g ngx-charts-x-axis
           *ngIf="xAxis"
           [xScale]="xScaleLine"
           [dims]="dims"
           [showLabel]="showXAxisLabel"
           [labelText]="xAxisLabel"
           [tickFormatting]="xAxisTickFormatting"
           [showGridLines]="true"
           [ticks]="xAxisTicks"
           (dimensionsChanged)="updateXAxisHeight($event)">
    </svg:g>
    <svg:g ngx-charts-y-axis class="y-axis-main"
           *ngIf="yAxis"
           [yScale]="yMainScale"
           [dims]="dims"
           [yOrient]="yOrientLeft"
           [showGridLines]="showYMainGridLines"
           [showLabel]="yMainAxisShowLabel"
           [labelText]="yMainAxisLabel"
           [tickFormatting]="yMainAxisTickFormatting"
           [ticks]="yMainAxisTicks"
           [referenceLines]="referenceLines"
           [showRefLines]="showRefLines"
           [showRefLabels]="showRefLabels"
           (dimensionsChanged)="updateYAxisWidth($event)">
    </svg:g>
    <svg:g ngx-charts-y-axis class="y-axis-secondary"
           *ngIf="yAxis && lineChart1.length"
           [yScale]="ySecondScale"
           [dims]="dims"
           [yOrient]="yOrientRight"
           [showGridLines]="showYSecondaryGridLines"
           [showLabel]="ySecondaryAxisShowLabel"
           [labelText]="ySecondaryAxisLabel"
           [tickFormatting]="ySecondaryAxisTickFormatting"
           [ticks]="ySecondaryAxisTicks"
           [referenceLines]="referenceLines"
           [showRefLines]="showRefLines"
           [showRefLabels]="showRefLabels"
           (dimensionsChanged)="updateYAxisWidth($event)">
    </svg:g>

    <svg:g [attr.clip-path]="clipPath">
      <svg:g *ngFor="let series of lineChart; trackBy:trackBy">
        <svg:g ngx-charts-line-series
               [xScale]="xScaleLine"
               [yScale]="yMainScale"
               [colors]="colorsLine"
               [data]="series"
               [activeEntries]="activeEntries"
               [scaleType]="scaleType"
               [curve]="curve"
               [rangeFillOpacity]="rangeFillOpacity"
               [animations]="animations"
        />
      </svg:g>

      <svg:g *ngFor="let series1 of lineChart1; trackBy:trackBy">
        <svg:g ngx-charts-line-series
               [xScale]="xScaleLine"
               [yScale]="ySecondScale"
               [colors]="colorsLine"
               [data]="series1"
               [activeEntries]="activeEntries"
               [scaleType]="scaleType"
               [curve]="curve"
               [rangeFillOpacity]="rangeFillOpacity"
               [animations]="animations"
        />
      </svg:g>

      <svg:g ngx-charts-tooltip-area
             *ngIf="!tooltipDisabled"
             [dims]="dims"
             [xSet]="xSet"
             [xScale]="xScaleLine"
             [yScale]="yMainScale"
             [results]="combinedSeries"
             [colors]="colorsLine"
             [tooltipDisabled]="tooltipDisabled"
             (hover)="updateHoveredVertical($event)"
      />

      <svg:g *ngFor="let series of lineChart">
        <svg:g ngx-charts-circle-series
               [xScale]="xScaleLine"
               [yScale]="yMainScale"
               [colors]="colorsLine"
               [data]="series"
               [scaleType]="scaleType"
               [visibleValue]="hoveredVertical"
               [activeEntries]="activeEntries"
               [tooltipDisabled]="tooltipDisabled"
               (select)="onClick($event)"
               (activate)="onActivate($event)"
               (deactivate)="onDeactivate($event)"
        />
      </svg:g>

      <svg:g *ngFor="let series of lineChart1">
        <svg:g ngx-charts-circle-series
               [xScale]="xScaleLine"
               [yScale]="ySecondScale"
               [colors]="colorsLine"
               [data]="series"
               [scaleType]="scaleType"
               [visibleValue]="hoveredVertical"
               [activeEntries]="activeEntries"
               [tooltipDisabled]="tooltipDisabled"
               (select)="onClick($event)"
               (activate)="onActivate($event)"
               (deactivate)="onDeactivate($event)"
        />
      </svg:g>
    </svg:g>
  </svg:g>

</ngx-charts-chart>
