import { Inject, Injectable, NgZone } from '@angular/core';
import TileLayer from 'ol/layer/Tile';
import TileArcGISRest from 'ol/source/TileArcGISRest';
import { MapService } from '../../map/map.service';
import { AutoRefreshCommonService, ENVIRONMENT, LayerConfigCommonType } from 'flying-hellfish-common';
import { AppConstants } from '../../app.constants';
import { LayerConfigType } from '../../types/layer.type';

@Injectable({
  providedIn: 'root'
})
export class LayersService {
  baseLayers: LayerConfigCommonType[] = [];
  isFeltLayerActive: boolean = false;
  hoursNumberSinceReport: number = 24;
  hoursSinceReport: number = 24;
  layers: Map<string, LayerConfigType> = new Map();
  selectedBaseLayer: string;

  constructor(
    private mapService: MapService,
    private autoRefreshCommonService: AutoRefreshCommonService,
    private zone: NgZone,
    @Inject(ENVIRONMENT) private environment: any
  ) {
    this.mapService.setZone(this.zone);
    this.initialiseLayers();
  }

  // Initialise the layers map from config
  initialiseLayers(): void {
    for (const layer of this.mapService.mapLayers) {
      if (layer.isBaseLayer === true) {
        this.baseLayers.push(layer);
      }
    }

    for (const baseLayer of this.baseLayers) {
      if (baseLayer.visible === true) {
        this.setBaseLayer(baseLayer.id);
      }
    }

    for (const layer of this.environment.layers) {
      this.layers.set(layer.key, layer.properties);
    }
  }

  // Toggle the layer for a given key
  toggleLayer(layerKey: string): void {
    const layerConfig: LayerConfigType = this.layers.get(layerKey);
    if (!layerConfig.layer) {
      // Enable the layer
      layerConfig.visible = true;
      layerConfig.showSpinner = true;
      // Call the function in the config
      this[layerConfig.addFunction](layerKey);
    } else {
      // Disable the layer
      layerConfig.visible = false;
      layerConfig.showLegend = false;

      if (layerConfig.removeFunction) {
        this.mapService[layerConfig.removeFunction]();
      } else {
        this.zone.runOutsideAngular(() => {
          this.mapService.mapInstance.removeLayer(layerConfig.layer);
        });
      }

      // If auto refresh is enabled remove the layer from the auto refresh services
      if (layerConfig.autoRefreshOperator) {
        this.autoRefreshCommonService.removeAutoRefresh(AppConstants[layerConfig.autoRefreshOperator]);
      }

      layerConfig.layer = null;
    }
  }

  // Load the seismic hazard layer
  loadSeismicLayer(layerKey: string): void {
    const seismicHazard: LayerConfigType = this.layers.get(layerKey);
    seismicHazard.layer = new TileLayer({
      source: new TileArcGISRest({
        url: seismicHazard.url,
        params: seismicHazard.params
      }),
      opacity: seismicHazard.opacity / 100
    });
    seismicHazard.layer.setZIndex(seismicHazard.zindex);

    seismicHazard.layer.once('postrender', () => {
      this.zone.run(() => {
        seismicHazard.showSpinner = false;
      });
    });
    this.zone.runOutsideAngular(() => {
      this.mapService.mapInstance.addLayer(seismicHazard.layer);
    });
  }

  // Load the felt reports layer
  loadFeltReportsLayer(layerKey: string): void {
    this.autoRefreshCommonService.addAutoRefresh(AppConstants.FELT_REPORTS, () => {
      this.layers.get(layerKey).layer = this.mapService.createFeltReportLayer(this.hoursSinceReport, this.layers.get(layerKey).opacity / 100, this.layers.get(layerKey).zindex, (loaded) => {
        this.zone.run(() => {
          this.layers.get(layerKey).showSpinner = false;
        });
      });
      this.isFeltLayerActive = true;
    }, () => {
      if (MapService.feltReportVectorLayer) {
        return MapService.feltReportVectorLayer.getVisible();
      }
    });
  }

  // Load the neotectonic layer
  loadNeotectonicLayer(layerKey: string): void {
    this.autoRefreshCommonService.addAutoRefresh(AppConstants.NEOTECTONIC_FEATURES, () => {
      this.layers.get(layerKey).layer = this.mapService.createNeotectonicsLayer(this.layers.get(layerKey).opacity / 100, this.layers.get(layerKey).zindex, (loaded) => {
        this.zone.run(() => {
          this.layers.get(layerKey).showSpinner = false;
        });
      });
    }, () => {
      if (this.mapService.neotectonicsVectorLayer) {
        return this.mapService.neotectonicsVectorLayer.getVisible();
      }
    });
  }

  // Load the historic earthquakes layer
  loadHistoricEarthquakesLayer(layerKey: string): void {
    this.layers.get(layerKey).layer = this.mapService.createHistoricEarthquakesLayer(this.layers.get(layerKey).opacity / 100, this.layers.get(layerKey).zindex, this.layers.get(layerKey).url, (loaded) => {
      this.zone.run(() => {
        this.layers.get(layerKey).showSpinner = false;
      });
    });
  }

  // Loads the Tectonic Plate Boundaries layer
  loadTectonicPlateBoundariesLayer(layerKey: string): void {
    const tectonicPlateBoundariesLayerConfig: LayerConfigType = this.layers.get(layerKey);
    tectonicPlateBoundariesLayerConfig.layer = this.mapService.createTectonicPlateBoundariesLayer(tectonicPlateBoundariesLayerConfig, () => {
      this.zone.run(() => {
        tectonicPlateBoundariesLayerConfig.showSpinner = false;
      });
    });
  }

  // Change the layer's opacity for the passed in key
  changeLayerOpacity(layerKey: string, opacity: number): void {
    this.layers.get(layerKey).layer.setOpacity(opacity / 100);
  }

  // Updates the range to display for the felt report layer in hours
  changeFeltReportRange(layerKey: string): void {
    this.layers.get(layerKey).showSpinner = true;
    this.mapService.createFeltReportLayer(this.hoursNumberSinceReport, this.layers.get(layerKey).opacity / 100, this.layers.get(layerKey).zindex, (loaded) => {
      this.zone.run(() => {
        this.layers.get(layerKey).showSpinner = false;
      });
    });
    this.hoursSinceReport = this.hoursNumberSinceReport;
  }

  // Change the selected base layer
  setBaseLayer(baseLayerId: string): void {
    this.selectedBaseLayer = this.baseLayers.find(layer => layer.id === baseLayerId).name;
  }
}
