import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { AddressLocation, FeltReport, RecentQuakeType } from './felt.report';
import { AnimationCommon, DeviceCommonService, ENVIRONMENT, SidePanelCommonService } from 'flying-hellfish-common';
import { MapService } from '../../map/map.service';
import { FeltReportService } from './felt.report.service';
import { LayersService } from '../layers/layer.service';
import * as momentTimezone from 'moment-timezone';
import * as moment from 'moment';
import { RecentQuakesService } from '../recent-quakes/recent.quakes.service';
import { FeatureType } from '../../types/feature.type';
import { BehaviorSubject, Subscription } from 'rxjs';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point, polygon } from '@turf/helpers';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'ga-toolbar-felt-report',
  templateUrl: 'felt.report.component.html',
  styleUrls: ['felt.report.component.css'],
  preserveWhitespaces: true,
  animations: [
    AnimationCommon.animationCommonEnter300,
    AnimationCommon.animationCommonEnter500
  ]
})
export class FeltReportComponent implements OnDestroy {
  @ViewChild('feltPanel', { read: ElementRef }) feltPanelElement: ElementRef;

  feltReport: FeltReport;

  setSubmitEmitter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showProgressBar: boolean = false;
  showError: boolean = false;
  errorMessage: string;
  browser: any;

  situationList: string[] = this.environment.feltReport.options.situationList;
  sleepList: string[] = this.environment.feltReport.options.sleepList;
  othersFeltList: string[] = this.environment.feltReport.options.othersFeltList;
  motionList: string[] = this.environment.feltReport.options.motionList;
  reactionList: string[] = this.environment.feltReport.options.reactionList;
  responseList: string[] = this.environment.feltReport.options.responseList;
  swingList: string[] = this.environment.feltReport.options.swingList;
  noiseList: string[] = this.environment.feltReport.options.noiseList;
  defaultList: string[] = this.environment.feltReport.options.defaultList;
  shelfList: string[] = this.environment.feltReport.options.shelfList;
  pictureList: string[] = this.environment.feltReport.options.pictureList;
  applianceList: string[] = this.environment.feltReport.options.applianceList;
  wallsFencesList: string[] = this.environment.feltReport.options.wallsFencesList;
  damageList: string[] = this.environment.feltReport.options.damageList;
  element: any;

  recentQuakes: any[] = [];
  recentQuakesFormatted: any[] = [];
  recentQuakesActive: boolean = true;
  selectedRecentQuake: RecentQuakeType;
  enteredDate: string;
  feltDate: string = '';
  feltQuakeLogicIsValid: boolean = false;
  useDateField: boolean = true;
  isDateInFuture: boolean = false;
  isDateOlderThanOneDay: boolean = false;
  invalidEmail: boolean = false;

  feature: FeatureType;
  subscriptions: Subscription = new Subscription();

  constructor(private sidePanelCommonService: SidePanelCommonService, public deviceCommonService: DeviceCommonService, private mapService: MapService,
              private feltReportService: FeltReportService, private layersService: LayersService,
              private recentQuakesService: RecentQuakesService, @Inject(ENVIRONMENT) private environment: any) {
    this.feltReport = new FeltReport();
    this.setDefaultRecentQuakeOptions();
    this.setDefaultSelectedRecentQuake();

    // Get the users current location when the panel opens
    this.subscriptions.add(this.sidePanelCommonService.rightMenuEmitter.subscribe((data) => {
      if (data.activeToolId === 'feltReport') {
        // Display the felt cluster layer if a report already has been submitted
        if (this.feltReport.submitted) {
          // Check if the layer panel already has a felt report layer active
          if (!this.layersService.isFeltLayerActive) {
            this.mapService.createFeltReportLayer(24);
          }
          this.mapService.showRecentEarthquakesLayer('FeltReportComponent', false);
        }

        this.subscriptions.add(this.recentQuakesService.getRecentQuakes()
          .subscribe({
            next: response => {
              this.recentQuakes = response.features;
            },
            error: error => console.log(error),
            complete: () => this.recentQuakesFeaturesReturned()
          }));
      } else if ((data.lastActiveToolId === 'feltReport' || data.forceClear) && this.feltReport.submitted) {
        // Don't remove the felt report layer if the layer panel has the felt report layer active
        if (!this.layersService.isFeltLayerActive) {
          this.mapService.removeFeltReportLayer();
        }
        // If the new tool is search don't enable the recent quakes layer
        if (data.activeToolId !== 'search') {
          this.mapService.showRecentEarthquakesLayer('FeltReportComponent', true);
        }
      }
    }));

    this.browser = this.deviceCommonService.getDeviceInfo().browser;

    // Fired when the feature is passed in via another panel
    this.subscriptions.add(mapService.featureEmitter.subscribe(
      feature => {
        this.feature = feature;
      }
    ));
  }

  validateEmail(): void {
    if (this.feltReport.email) {
      const validEmail: boolean = new RegExp('[^@|\\s]+@[^@]+\\.[^@|\\s]+').test(this.feltReport.email);
      this.invalidEmail = !validEmail;
    } else {
      this.invalidEmail = false;
    }
  }

  setDefaultSelectedRecentQuake(): void {
    this.selectedRecentQuake = { text: 'Please select', utcDate: null, epicentralTime: null, eventId: '', locatedInAustralia: '' };
  }

  setDefaultRecentQuakeOptions(): void {
    this.recentQuakesFormatted.push({
      text: 'Please select',
      utcDate: null,
      epicentralTime: null,
      eventId: ''
    });

    this.recentQuakesFormatted.push({
      text: 'I cannot find the Earthquake',
      utcDate: null,
      epicentralTime: null,
      eventId: ''
    });
  }

  // Submits the felt report
  onSubmit(): void {
    this.showProgressBar = true;
    this.feltReportService.setWeightsForIntensityIndex(this.feltReport);
    this.setSubmitEmitter.next(true);
  }

  // Calls the felt report service to submit the report
  submitFeltReport(addressLocation: AddressLocation): void {
    this.feltReport.feltLocation = cloneDeep(addressLocation);
    this.feltReport.address = addressLocation.address;
    this.feltReport.suburb = addressLocation.suburb;
    this.feltReport.state = addressLocation.state;
    this.feltReport.postcode = addressLocation.postcode;
    this.feltReport.longitude = addressLocation.location.longitude;
    this.feltReport.latitude = addressLocation.location.latitude;
    if (this.useDateField) {
      this.feltReport.dateTime = moment(this.feltDate, 'DD-MM-YYYY HH:mm').toDate();
      this.feltReport.epicentralDateTime = null;
    } else if (this.feltReport.felt === 'No') {
      this.feltReport.dateTime = null;
    } else {
      this.feltReport.epicentralDateTime = new Date(this.selectedRecentQuake.epicentralTime);
    }

    this.subscriptions.add(this.feltReportService.submitFeltReport(this.feltReport)
      .subscribe({
        next: data => {
          if (!this.deviceCommonService.isMobile() && this.deviceCommonService.getScreenWidth() > 768) {
            this.mapService.showRecentEarthquakesLayer('FeltReportComponent', false);
            this.mapService.setMapPosition(this.feltReport.feltLocation.location.latitude, this.feltReport.feltLocation.location.longitude, 12);
            this.mapService.removeFeltReportLayer();

            // Check if the layer panel has the felt report layer active and if so apply its criteria
            if (this.layersService.hoursSinceReport > 0) {
              this.mapService.createFeltReportLayer(this.layersService.hoursSinceReport);
            } else {
              this.mapService.createFeltReportLayer(24);
            }
          }

          this.showProgressBar = false;
          this.feltReport.submitted = true;
        },
        error: error => {
          console.error(error);
          this.setShowError();
        }
      }));
  }

  // Show felt report error message.
  setShowError(): void {
    this.errorMessage = `Failed to submit felt report. Please try again and if the problem persists contact <a href="mailto:${this.environment.urgentFeedbackEmail}?Subject=${this.environment.feedbackEmailSubject} - Felt Report Error">${this.environment.urgentFeedbackEmail}</a>.`;
    this.showProgressBar = false;
    this.showError = true;
  }

  // Scroll to the bottom of the form
  scroll(): void {
    this.deviceCommonService.scrollIntoView(this.feltPanelElement);
  }

  // Remove leading and trailing whitespace from input.
  trim(event: any): string {
    if (event) {
      event = event.trim();
    }
    return event;
  }

  // Clears the form
  clear(): void {
    this.feltReport = new FeltReport();
    this.feltReport.dateTime = new Date();
    this.enteredDate = '';
    this.setDefaultSelectedRecentQuake();
    this.feltQuakeLogicIsValid = false;
    this.isDateInFuture = false;
    this.isDateOlderThanOneDay = false;
    this.feltDate = '';
    this.feature = null;
  }

  // Recent quakes service has returned
  recentQuakesFeaturesReturned(): void {
    this.recentQuakesFormatted = [];
    this.setDefaultRecentQuakeOptions();
    for (const quake of this.recentQuakes) {
      // Only show Australian and Banda Sea earthquakes
      if (quake.properties.located_in_australia === 'Y' || this.isValidExternalAusQuake(quake.properties.longitude, quake.properties.latitude, quake.properties.preferred_magnitude)) {
        const content: string = 'Magnitude ' + quake.properties.preferred_magnitude.toFixed(1) + ' ' + quake.properties.description + ' ' +
          moment.utc(quake.properties.epicentral_time, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD/MM/YYYY HH:mm').toString();

        const recentQuake: RecentQuakeType = {
          text: content,
          utcDate: quake.properties.origin_time,
          epicentralTime: quake.properties.epicentral_time,
          eventId: quake.properties.event_id,
          locatedInAustralia: quake.properties.located_in_australia
        };

        if (this.feature) {
          // Check if there is a feature already selected from earthquake details
          if (recentQuake.eventId === this.feature.event_id) {
            this.feltReport.felt = 'Yes';
            this.selectedRecentQuake = recentQuake;
            this.feltReport.eventId = this.selectedRecentQuake.eventId;
            this.feltReport.dateTime = new Date(this.selectedRecentQuake.utcDate);
            this.isValidFeltLogic();
          }
        }

        this.recentQuakesFormatted.push(recentQuake);
      }
    }

    // Add a feature that has been passed in that doesn't meet the above criteria
    if (this.feature) {
      if (this.recentQuakesFormatted.find(x => x.eventId === this.feature.event_id) === undefined) {
        const content: string = 'Magnitude ' + this.feature.preferred_magnitude.toFixed(1) + ' ' + this.feature.description + ' ' +
          moment.utc(this.feature.epicentral_time, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD/MM/YYYY HH:mm').toString();
        this.feltReport.felt = 'Yes';
        const recentQuake: RecentQuakeType = {
          text: content,
          utcDate: this.feature.origin_time,
          epicentralTime: this.feature.epicentral_time,
          eventId: this.feature.event_id,
          locatedInAustralia: ''
        };
        this.recentQuakesFormatted.push(recentQuake);
        this.updateSelectedRecentQuake(recentQuake);
      }
    }

    this.recentQuakesActive = false;
  }

  // Fired by the keypress event on the date input
  dateInputChange(value: string): void {
    this.enteredDate = value;
    this.isValidFeltLogic();
  }

  // Check if the date format is valid
  checkValidDate(date: string): boolean {
    let isValid: boolean;

    // Check if the date pattern is correct
    if (date.match(/^(0[1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d [012]{0,1}[0-9]:[0-6][0-9]$/)) {
      if (moment(date, 'DD/MM/YYYY HH:mm', true).isValid()) {
        // Check if the date is older than one day
        if (moment(date, 'DD/MM/YYYY HH:mm').isBefore(moment(new Date(), 'DD/MM/YYYY HH:mm', momentTimezone.tz.guess()).subtract(1, 'days'))) {
          this.isDateOlderThanOneDay = true;
        } else {
          this.isDateOlderThanOneDay = false;
        }

        // Check if the date is in the future
        if (moment(date, 'DD/MM/YYYY HH:mm').isAfter(moment.utc(new Date(), 'DD/MM/YYYY HH:mm', momentTimezone.tz.guess()))) {
          this.isDateInFuture = true;
          isValid = false;
        } else {
          this.isDateInFuture = false;
          isValid = true;
        }
      } else {
        this.isDateInFuture = false;
        this.isDateOlderThanOneDay = false;
        isValid = false;
      }
    } else {
      this.isDateInFuture = false;
      this.isDateOlderThanOneDay = false;
      isValid = false;
    }

    return isValid;
  }

  // Check if the felt logic for date and recent quakes is correct
  isValidFeltLogic(): void {
    if (this.feltReport) {
      if (this.feltReport.felt === 'No') {
        if (this.selectedRecentQuake.text !== 'Please select') {
          this.feltQuakeLogicIsValid = true;
          this.useDateField = false;
        } else if (this.selectedRecentQuake.text === 'Please select') {
          this.feltQuakeLogicIsValid = false;
          this.useDateField = false;
        }
      } else if (this.feltReport.felt === 'Yes') {
        if (this.selectedRecentQuake.text === 'Please select') {
          this.feltQuakeLogicIsValid = false;
        } else if (this.selectedRecentQuake.text === 'I cannot find the Earthquake') {
          if (this.checkValidDate(this.enteredDate)) {
            this.feltQuakeLogicIsValid = true;
            this.feltDate = this.enteredDate;
            this.useDateField = true;
          } else {
            this.feltQuakeLogicIsValid = false;
          }
        } else if (this.selectedRecentQuake.text !== 'Please select' && this.selectedRecentQuake.text !== 'I cannot find the Earthquake') {
          this.feltQuakeLogicIsValid = true;
          this.useDateField = false;
        }
      }
    } else {
      this.feltQuakeLogicIsValid = false;
    }
  }

  // Update the selected recent quake form the dropdown
  updateSelectedRecentQuake(value: any): void {
    this.selectedRecentQuake = value;
    this.feltReport.eventId = value.eventId;
    this.feltReport.dateTime = value.utcDate;
    this.enteredDate = '';
    this.isValidFeltLogic();
  }

  // Recent Quakes list only allows quakes in the Banda Sea above magnitude 5
  isValidExternalAusQuake(longitude: number, latitude: number, magnitude: number): boolean {
    if (magnitude >= 5) {
      const eventLocation: any = point([longitude, latitude]);
      const boundaryPolygon: any = polygon([this.environment.boundaryBandaSeaMagnitude5]);

      return booleanPointInPolygon(eventLocation, boundaryPolygon);
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.mapService.showRecentEarthquakesLayer('FeltReportComponent', true);
    this.subscriptions.unsubscribe();
  }
}
