import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { setTheme } from 'ngx-bootstrap/utils';
import { ENVIRONMENT } from 'flying-hellfish-common';

@Component({
  selector: 'ga-app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  preserveWhitespaces: true
})
export class AppComponent {
  constructor(private title: Title, @Inject(ENVIRONMENT) private environment: any) {
    setTheme('bs3');
    this.title.setTitle(this.environment.headerTitle);
  }
}
