<h4 class="tool-title">Layers
  <ga-help-link [linkFragment]="'layers'"></ga-help-link>
</h4>
<hr>
<h4 class="layer-group-header">Base Maps</h4>
<div class="dropdown" dropdown>
  <button type="button" class="btn btn-ga btn-dropdown" dropdownToggle>{{ layerService.selectedBaseLayer }}<span class="caret"></span>
  </button>
  <ul class="dropdown-menu btn-dropdown" *dropdownMenu>
    <li *ngFor="let baseLayer of layerService.baseLayers" [class.active]="baseLayer.visible">
      <a href="javascript:void(0)" (click)="changeBaseLayer(baseLayer)">{{ baseLayer.name }}</a>
    </li>
  </ul>
</div>
<hr>
<h4 class="layer-group-header">Overlays</h4>
<ng-container *ngFor="let layer of layerService.layers | keyvalue">
  <ng-container *ngIf="layer.value.group === constants.OVERLAYS_LAYER_GROUP">
    <div class="layer-header">
      <label class="checkmark-container">{{ layer.value.label }}
        <input type="checkbox" class="option-input checkbox" [checked]="layer.value.visible" (click)="layerService.toggleLayer(layer.key)"/><span class="checkmark"></span></label>
      <img *ngIf="layer.value.legend && layer.value.visible" class="seismic-group-img" src="assets/legend.png" alt="{{layer.value.showLegend ? 'Hide Legend' : 'Show Legend'}}" title="{{layer.value.showLegend ? 'Hide Legend' : 'Show Legend'}}" (click)="layer.value.showLegend = !layer.value.showLegend"/>
      <img src="assets/info-2-32.png" alt="{{layer.value.showInfo ? 'Hide Metadata' : 'Show Metadata'}}" title="{{layer.value.showInfo ? 'Hide Metadata' : 'Show Metadata'}}" (click)="layer.value.showInfo = !layer.value.showInfo"/>
      <div class="spinner" *ngIf="layer.value.showSpinner"></div>
    </div>
    <div *ngIf="layer.value.showInfo">
      <p>
        {{ layer.value.metadata }}
        <span *ngIf="layer.value?.sourceOrigin">
          (source: <a target="_blank" [href]="'https://' + layer.value?.sourceOrigin">{{ layer.value?.sourceOrigin }}</a>)
        </span>
      </p>
    </div>
    <div *ngIf="layer.value.visible" [@animationCommonEnter300]>
      <input type="range" fhSlider *ngIf="layer.value.visible && layer.key !== constants.FELT_REPORTS_LAYER" min="layer.value.sliderMin" max="layer.value.sliderMax" (input)="changeOpacity(layer.key, $event.target)" [(ngModel)]="layer.value.opacity"/>
      <input type="range" fhSlider *ngIf="layer.value.visible && layer.key === constants.FELT_REPORTS_LAYER" min="1" max="168" (change)="layerService.changeFeltReportRange(layer.key)" [(ngModel)]="layerService.hoursNumberSinceReport"/>
      <div *ngIf="layer.key !== constants.FELT_REPORTS_LAYER" style="margin-bottom: 5px" [@animationCommonEnter300]>Opacity: {{ layer.value.opacity }}%</div>
      <div *ngIf="layer.key === constants.FELT_REPORTS_LAYER" [@animationCommonEnter300]>Hours since submitted: {{ layerService.hoursNumberSinceReport }}</div>
    </div>
    <img *ngIf="layer.value.legend && layer.value.showLegend" alt="Legend" class="center" src="{{layer.value.legend}}" [@animationCommonEnter300Leave300]/>
    <hr>
  </ng-container>
</ng-container>

<div>
  <h4 class="layer-group-header">National Seismic Hazard
    <img src="assets/info-2-16-white.png" width="16px" height="16px" alt="{{showSeismicInfo ? 'Hide Metadata' : 'Show Metadata'}}" title="{{showSeismicInfo ? 'Hide Metadata' : 'Show Metadata'}}" (click)="showSeismicInfo = !showSeismicInfo"/>
  </h4>
</div>
<div *ngIf="showSeismicInfo" [@animationCommonEnter300Leave300]>
  <p>
    Geoscience Australia develops the National Seismic Hazard Assessment (NSHA) for Australia. The NSHA defines the level of earthquake ground shaking across Australia that has a likelihood of being exceeded in a given time
    period. Knowing how the ground-shaking hazard varies across Australia allows higher hazard areas to be identified for the development of mitigation strategies so communities can be more resilient to earthquake events.
  </p>
</div>
<ng-container *ngFor="let layer of layerService.layers | keyvalue">
  <ng-container *ngIf="layer.value.group === constants.SEISMIC_HAZARD_LAYER_GROUP">
    <div class="layer-header">
      <label class="checkmark-container">{{ layer.value.label }}
        <input type="checkbox" class="option-input checkbox" [checked]="layer.value.visible" (click)="layerService.toggleLayer(layer.key)"/><span class="checkmark"></span></label>
      <img *ngIf="layer.value.visible" class="seismic-group-img" src="assets/legend.png" alt="{{layer.value.showLegend ? 'Hide Legend' : 'Show Legend'}}" title="{{layer.value.showLegend ? 'Hide Legend' : 'Show Legend'}}" (click)="layer.value.showLegend = !layer.value.showLegend"/>
      <div class="spinner-container">
        <div class="spinner" *ngIf="layer.value.showSpinner"></div>
      </div>
    </div>
    <div [@animationCommonEnter300]>
      <input type="range" fhSlider *ngIf="layer.value.visible" min="layer.value.sliderMin" max="layer.value.sliderMax" (input)="changeOpacity(layer.key, $event.target)" [(ngModel)]="layer.value.opacity"/>
    </div>
    <div *ngIf="layer.value.visible" style="margin-bottom: 5px" [@animationCommonEnter300]>Opacity: {{ layer.value.opacity }}%</div>
    <img *ngIf="layer.value.showLegend" alt="Legend" class="center" src="{{layer.value.legend}}" [@animationCommonEnter300]/>
    <hr>
  </ng-container>
</ng-container>
