import { Component, ElementRef, Inject, Input, Renderer2, ViewChild } from '@angular/core';
import { DeviceCommonService, ENVIRONMENT } from 'flying-hellfish-common';

@Component({
  selector: 'ga-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.css'],
  preserveWhitespaces: true
})
export class HeaderComponent {
  readonly DEFAULT_MENU_ICON: string = 'assets/menu-4-32-black.png';

  @ViewChild('dropdown') dropdownRef: ElementRef;
  @Input() autoSaveEnabled: boolean = true;
  @Input() helpEnabled: boolean = true;
  @Input() shareEnabled: boolean = true;
  title: string = this.environment.headerTitle;
  menuIcon: string = this.DEFAULT_MENU_ICON;
  showShare: boolean = true;

  constructor(public deviceCommonService: DeviceCommonService, private renderer: Renderer2, @Inject(ENVIRONMENT) private environment: any) {
    if (this.deviceCommonService.isMobile()) {
      this.title = this.environment.mobileTitle;
    }
    this.setShowShare();
  }

  // Hide the Share Tool if the device used is iOS or Mac
  setShowShare(): void {
    this.showShare = !(this.deviceCommonService.getDeviceInfo().os === 'iOS' || this.deviceCommonService.getDeviceInfo().os === 'Mac');
  }

  // Close menu
  closeMenu(event: any): void {
    this.renderer.removeClass(this.dropdownRef.nativeElement, 'open');
  }
}
