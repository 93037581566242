import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { FeltReportComponent } from './felt.report.component';
import { FeltReportService } from './felt.report.service';
import { FeltReportMapComponent } from './felt.report.map.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FlyingHellfishCommonModule } from 'flying-hellfish-common';
import { LocationModule } from '@shared/services/location/location.module';
import { FeltReportLocationComponent } from './felt.report.location/felt.report.location.component';

@NgModule({
  declarations: [
    FeltReportComponent,
    FeltReportLocationComponent,
    FeltReportMapComponent
  ],
  imports: [
    SharedModule,
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    FlyingHellfishCommonModule,
    LocationModule,
  ],
  exports: [
    FeltReportComponent,
    FeltReportLocationComponent,
    FeltReportMapComponent
  ],
  providers: [
    FeltReportService
  ]
})
export class FeltReportModule {
}
