<div class="address" [@animationCommonEnter300]>
  <div class="address-div">
    <label for="address">Address during the Earthquake</label>
    <input id="address" autocomplete="new-password" type="text" class="form-control" maxlength="200" name="address" [typeaheadAsync]="true" [typeahead]="typeaheadObservable" [typeaheadOptionField]="addressField" (typeaheadOnSelect)="onClickTypeaheadOption($event)" [(ngModel)]="typeaheadInput" (ngModelChange)="addressLocation.address=$event">
  </div>
  <div class="address-div">
    <label for="suburb">Suburb *</label>
    <input id="suburb" autocomplete="new-password" type="text" class="form-control" maxlength="128" name="suburb" [(ngModel)]="addressLocation.suburb" (ngModelChange)="addressLocation.suburb=trim($event)" [ngModelOptions]="{updateOn: 'blur'}" required>
  </div>
  <div class="address-div">
    <label for="state">State *</label>
    <select id="state" autocomplete="new-password" name="state" class="form-control" [(ngModel)]="addressLocation.state" required>
      <option value="" selected="">Please select</option>
      <option value="ACT">ACT</option>
      <option value="NSW">NSW</option>
      <option value="NT">NT</option>
      <option value="QLD">QLD</option>
      <option value="SA">SA</option>
      <option value="TAS">TAS</option>
      <option value="VIC">VIC</option>
      <option value="WA">WA</option>
    </select>
  </div>
  <div class="address-div">
    <label for="postcode">Postcode</label>
    <input id="postcode" autocomplete="new-password" type="text" class="form-control" maxlength="4" pattern="\d{4}" [(ngModel)]="addressLocation.postcode" name="postcode">
  </div>
</div>
