import { LayerConstants } from '../app/tools/layers/layer.constants';
import { environmentCommon } from 'flying-hellfish-common';

export const environment: any = {
  ...environmentCommon,
  environment: 'nonprod',
  enableRotation: true,
  disclaimerUrl: '/cache/NEAC_Disclaimer V2.1.pdf',
  feedbackEmail: 'jatwc.training@gmail.com',
  urgentFeedbackEmail: 'earthquakes@ga.gov.au',
  feedbackEmailSubject: 'Earthquakes@GA Website Feedback',
  headerTitle: 'Earthquakes@GA (Training)',
  mobileTitle: 'Earthquakes@GA',
  finalStatusEffectiveDate: '2030-01-01T00:00:00-00:00',
  disclaimer: '<p><strong>DISCLAIMER:</strong> By proceeding to access these feeds, you acknowledge that they are provided for general informational purposes only. Their contents may not be fit for your purpose. <b>DO NOT</b> rely on these services to provide you with information about, or to plan your response to an emergency. Please comply with instructions provided by your local emergency services agency in an emergency. For the full disclaimer and copyright information about these services, please see <a target="_blank" rel=noopener href="http://www.ga.gov.au/disclaimer">this notice</a>.</p>',
  layers: [
    {
      key: LayerConstants.HISTORIC_EARTHQUAKES_LAYER,
      properties: {
        addFunction: 'loadHistoricEarthquakesLayer',
        autoRefreshOperator: null,
        group: 'overlaysGroup',
        label: 'Earthquakes 10 Years',
        layer: null,
        legend: null,
        metadata: 'This layer displays the earthquake locations for the last 10 years. This includes global events above magnitude 5 and all located Australian events. Further information on past seismicity can be found using the search function.',
        opacity: 70,
        params: null,
        removeFunction: 'removeHistoricEarthquakesLayer',
        showInfo: false,
        showLegend: false,
        showSpinner: false,
        sliderMin: 0,
        sliderMax: 100,
        url: 'https://earthquakes.ga.gov.au/geoserver/earthquakes/wms',
        visible: false,
        zindex: 80
      }
    },
    {
      key: LayerConstants.FELT_REPORTS_LAYER,
      properties: {
        addFunction: 'loadFeltReportsLayer',
        autoRefreshOperator: 'FELT_REPORTS',
        group: 'overlaysGroup',
        label: 'Felt Reports',
        layer: null,
        legend: null,
        metadata: 'The Felt Report layer displays a cluster, which represents a group of felt reports submitted online by the community. You can hover (or touch for mobile) over the cluster to see a breakdown of where the felt reports are located, or alternatively you can zoom in on the map.',
        opacity: 100,
        params: null,
        removeFunction: 'removeFeltReportLayer',
        showInfo: false,
        showLegend: false,
        showSpinner: false,
        sliderMin: 1,
        sliderMax: 168,
        url: null,
        visible: false,
        zindex: 96
      }
    },
    {
      key: LayerConstants.NEOTECTONIC_LAYER,
      properties: {
        addFunction: 'loadNeotectonicLayer',
        autoRefreshOperator: 'NEOTECTONIC_FEATURES',
        group: 'overlaysGroup',
        label: 'Neotectonic Features',
        layer: null,
        legend: null,
        metadata: 'The Neotectonic layer contains information on features within Australia that are believed to relate to large earthquakes during the Neotectonic Era (i.e., the past 5–10 million years). You can click on a feature on the map to find out more information.',
        opacity: 100,
        params: null,
        removeFunction: 'removeNeotectonicsLayer',
        showInfo: false,
        showLegend: false,
        showSpinner: false,
        sliderMin: 0,
        sliderMax: 100,
        url: null,
        visible: false,
        zindex: 96
      }
    },
    {
      key: LayerConstants.TECTONIC_PLATE_BOUNDARIES_LAYER,
      properties: {
        addFunction: 'loadTectonicPlateBoundariesLayer',
        autoRefreshOperator: null,
        group: 'overlaysGroup',
        label: 'Tectonic Plate Boundaries',
        layer: null,
        legend: 'assets/PlateBoundariesLegend.png',
        metadata: 'The outermost shell of the Earth consists of a mosaic of rigid “plates” that have been moving relative to one another for hundreds of millions of years.',
        sourceOrigin: 'usgs.gov',
        opacity: 100,
        params: null,
        removeFunction: 'removeTectonicPlateBoundariesLayer',
        showInfo: false,
        showLegend: false,
        showSpinner: false,
        sliderMin: 0,
        sliderMax: 100,
        url: 'assets/plate-boundaries-ga.kmz',
        visible: false,
        zindex: 75
      }
    },
    {
      key: LayerConstants.SEISMIC_HAZARD_TWO_PERCENT_LAYER,
      properties: {
        addFunction: 'loadSeismicLayer',
        autoRefreshOperator: null,
        group: 'seismicHazardGroup',
        label: 'NSHA18 Peak Ground Acceleration with a 2% chance of exceedance in 50 years',
        layer: null,
        legend: 'assets/legend-seismic-40-two-percent.png',
        metadata: null,
        opacity: 90,
        params: { layers: 'show:40' },
        removeFunction: null,
        showInfo: false,
        showLegend: false,
        showSpinner: false,
        sliderMin: 0,
        sliderMax: 100,
        url: 'https://services.ga.gov.au/gis/rest/services/National_Seismic_Hazard_Assessment_2018/MapServer',
        visible: false,
        zindex: 94
      }
    },
    {
      key: LayerConstants.SEISMIC_HAZARD_TEN_PERCENT_LAYER,
      properties: {
        addFunction: 'loadSeismicLayer',
        autoRefreshOperator: null,
        group: 'seismicHazardGroup',
        label: 'NSHA18 Peak Ground Acceleration with a 10% chance of exceedance in 50 years',
        layer: null,
        legend: 'assets/legend-seismic-16-ten-percent.png',
        metadata: null,
        opacity: 90,
        params: { layers: 'show:16' },
        removeFunction: null,
        showInfo: false,
        showLegend: false,
        showSpinner: false,
        sliderMin: 0,
        sliderMax: 100,
        url: 'https://services.ga.gov.au/gis/rest/services/National_Seismic_Hazard_Assessment_2018/MapServer',
        visible: false,
        zindex: 95
      }
    }
  ],
  serviceUrls: {
    atomFeedUrl: 'https://training.earthquakes.ga.gov.au/feeds/all_recent.atom',
    earthQuakeExportWFS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:earthquakes&outputFormat=csv&propertyName=(earthquake_id,azimuthal_gap,azimuth_horizontal_uncertainty,depth,depth_uncertainty,description,epicentral_time,evaluation_mode,evaluation_status,event_creation_time,event_id,event_modification_time,latitude,longitude,mb,md,mla,ms,mw,mww,mwp,mwmwp,minimum_distance,maximum_distance,nearest_station,origin_id,origin_time,origin_time_uncertainty,phase_count,preferred_magnitude,preferred_magnitude_type,max_horizontal_uncertainty,min_horizontal_uncertainty,source,standard_error,station_count,felt_reports_count)',
    earthQuakeFeltReportCountsWFS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:earthquakes_felt_report_counts&outputFormat=application/json',
    earthQuakeFeltReportsWFS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:earthquakes_felt_reports&outputFormat=application/json',
    earthQuakeFocalMechanismWFS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:earthquakes_focal_mechanism&outputFormat=application/json',
    earthQuakeGetCapsWMS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities',
    earthQuakeMagnitudeWFS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:earthquakes_magnitude&outputFormat=application/json',
    earthQuakeShakeMapGetCapsWMS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities&LAYER=',
    earthQuakeShakeMapWFS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/shakemap/ows?SERVICE=WFS&VERSION=1.0.0&REQUEST=GetFeature&typeName=shakemap:',
    earthQuakeShakeMapWMS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/shakemap/wms?SERVICE=WMS&VERSION=1.1.0&REQUEST=GetMap&layers=shakemap:',
    earthQuakeStationsExportWFS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:earthquakes_stations&outputFormat=csv&propertyName=(station_code,station_name,network_code,latitude,longitude,phase,arrival_time,distance_deg,azimuth_source_to_sta,time_defining_for_origin,amplitude_type,event_id,period,station_magnitude,station_magnitude_type,amplitude,amplitude_unit)',
    earthQuakeStationsWFS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:earthquakes_stations&outputFormat=application/json',
    earthQuakeSummaryGeoJSON: 'https://training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:earthquakes_seven_days&outputFormat=application/json&CQL_FILTER=display_flag=\'Y\'',
    earthQuakeSummaryGeoRSS: 'https://training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:earthquakes_seven_days&format=rss&CQL_FILTER=display_flag=\'Y\'',
    earthQuakeSummarySevenDaysWFS: '/cache/recent-earthquakes.json',
    earthQuakeSummarySevenDaysWFSKml: 'https://training.earthquakes.ga.gov.au/geoserver/earthquakes/wms/kml?layers=earthquakes:earthquakes_seven_days&CQL_FILTER=display_flag=\'Y\'',
    earthQuakeSummaryWFS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:earthquakes&outputFormat=application/json',
    earthquakesGeoserver: 'https://ui.training.earthquakes.ga.gov.au/geoserver',
    earthquakesNeotectonicFeaturesWFS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:neotectonics&outputFormat=application/json&cql_filter=access_description=\'O%20-%20Open%20Data\'',
    rssFeedUrl: 'https://training.earthquakes.ga.gov.au/feeds/all_recent.rss',
    shakeMapWFS: 'https://ui.training.earthquakes.ga.gov.au/geoserver/earthquakes/wfs?service=WFS&request=getfeature&typeNames=earthquakes:shakemap&outputFormat=application/json',
    skipCloudFrontUrl: 'https://cdn-training.eatws-nonprod.net/skip'
  },
  quakeDetails: {
    minZoomLevel: 2,
    maxZoomLevel: 13,
    zoomLevel: 6,
  },
  feltReport: {
    storeApi: 'https://api.training.earthquakes.ga.gov.au/felt/',
    arcGisTokenUrl: 'https://www.arcgis.com/sharing/oauth2/token?client_id=BWBjXyuTt7wFnHot&grant_type=client_credentials&client_secret=9ab4997674d345c884f2d02a850aad7a&f=pjson',
    reverseGeocodeUrl: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&featureTypes=&location=',
    getAddressCandidatesUrl: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?maxSuggestions=15&f=json&category=Address&countryCode=AUS',
    geocodeAddressUrl: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=pjson&outFields=Addr_type&forStorage=true&SingleLine=',
    miniMapZoomLevel: 11,
    hoursSinceSubmitted: 24,
    options: {
      situationList: ['Not specified', 'Inside a building', 'Outside a building', 'In a stopped vehicle', 'In a moving vehicle', 'Other'],
      sleepList: ['Not specified', 'No', 'Slept through it', 'Woke up'],
      othersFeltList: ['Not specified', 'No others felt it', 'Some felt it, most did not', 'Most felt it', 'Everyone/almost everyone felt it'],
      motionList: ['Not specified', 'Not felt', 'Weak', 'Mild', 'Moderate', 'Strong', 'Violent'],
      reactionList: ['Not specified', 'No reaction/not felt', 'Very little reaction', 'Excitement', 'Somewhat frightened', 'Very frightened', 'Extremely frightened'],
      responseList: ['Not specified', 'Took no action', 'Moved to doorway', 'Dropped and covered', 'Ran outside', 'Other'],
      swingList: ['Not specified', 'No', 'Yes, slight swinging', 'Yes, violent swinging'],
      noiseList: ['Not specified', 'No', 'Yes, slight noise', 'Yes, loud noise'],
      defaultList: ['Not specified', 'No', 'Yes'],
      shelfList: ['Not specified', 'No', 'Rattled slightly', 'Rattled loudly', 'A few toppled or fell off', 'Many fell off', 'Nearly everything fell off'],
      pictureList: ['Not specified', 'No', 'Yes, but did not fall', 'Yes, and some fell'],
      applianceList: ['Not specified', 'No', 'Yes, some contents fell out', 'Yes, shifted by a few centimetres', 'Yes, shifted by 20 cm or more', 'Yes, overturned'],
      wallsFencesList: ['Not specified', 'No', 'Yes, some were cracked', 'Yes, some partially fell', 'Yes, some fell completely'],
      damageList: ['No Damage', 'Hairline cracks in walls', 'A few large cracks in walls', 'Many large cracks in walls', 'Ceiling tiles or lighting fixtures fell',
        'Cracks in chimney', 'One or several cracked windows', 'Many windows cracked or some broken out', 'Masonry fell from block or brick wall(s)',
        'Old chimney, major damage or fell down', 'Modern chimney, major damage or fell down', 'Outside wall(s) tilted over or collapsed completely',
        'Separation of verandah, balcony, or other addition from building', 'Building permanently shifted over foundation']
    },
    weights: {
      feltIndex: [{ key: 'No', value: 0 }, { key: 'Yes', value: 1 }],
      othersFeltIndex: [{ key: 'Not specified', value: 0.72 }, {
        key: 'No others felt it',
        value: 0.36
      }, { key: 'Some felt it, most did not', value: 0.72 }, {
        key: 'Most felt it',
        value: 1
      }, { key: 'Everyone/almost everyone felt it', value: 1 }],
      motionIndex: [{ key: 'Not specified', value: 0 }, { key: 'Not felt', value: 0 }, {
        key: 'Weak',
        value: 1
      }, { key: 'Mild', value: 2 }, { key: 'Moderate', value: 3 }, { key: 'Strong', value: 4 }, { key: 'Violent', value: 5 }],
      reactionIndex: [{ key: 'Not specified', value: 0 }, {
        key: 'No reaction/not felt',
        value: 0
      }, { key: 'Very little reaction', value: 1 }, { key: 'Excitement', value: 2 }, {
        key: 'Somewhat frightened',
        value: 3
      }, { key: 'Very frightened', value: 4 }, { key: 'Extremely frightened', value: 5 }],
      standIndex: [{ key: 'Not specified', value: 0 }, { key: 'No', value: 0 }, { key: 'Yes', value: 1 }],
      shelfIndex: [{ key: 'Not specified', value: 0 }, { key: 'No', value: 0 }, {
        key: 'Rattled slightly',
        value: 0
      }, { key: 'Rattled loudly', value: 0 }, { key: 'A few toppled or fell off', value: 1 }, {
        key: 'Many fell off',
        value: 2
      }, { key: 'Nearly everything fell off', value: 3 }],
      pictureIndex: [{ key: 'Not specified', value: 0 }, { key: 'No', value: 0 }, {
        key: 'Yes, but did not fall',
        value: 1
      }, { key: 'Yes, and some fell', value: 1 }],
      furnitureIndex: [{ key: 'Not specified', value: 0 }, { key: 'No', value: 0 }, { key: 'Yes', value: 1 }],
      damageIndex: [{ key: 'No Damage', value: 0 }, {
        key: 'Hairline cracks in walls',
        value: 0.5
      }, { key: 'A few large cracks in walls', value: 0.75 }, {
        key: 'Many large cracks in walls',
        value: 1
      }, { key: 'Ceiling tiles or lighting fixtures fell', value: 1 },
        { key: 'Cracks in chimney', value: 1 }, {
          key: 'One or several cracked windows',
          value: 0.5
        }, { key: 'Many windows cracked or some broken out', value: 2 }, {
          key: 'Masonry fell from block or brick wall(s)',
          value: 2
        },
        { key: 'Old chimney, major damage or fell down', value: 2 }, {
          key: 'Modern chimney, major damage or fell down',
          value: 3
        }, { key: 'Outside wall(s) tilted over or collapsed completely', value: 3 },
        {
          key: 'Separation of verandah, balcony, or other addition from building',
          value: 3
        }, { key: 'Building permanently shifted over foundation', value: 3 }]
    }
  },
  apiGateway: {
    key: 'wUrLckqRx715E9vbCrPMiokvm3hHrVMZk&AevF1Ea'
  },
  subscription: {
    api: 'https://api.training.earthquakes.ga.gov.au/subscription/'
  },
  boundaries: {
    Australia: '144.569 -10.011,145.951 -10.695,147.057 -12.23,148.949 -13.729,149.691 -14.091,151.947 -15.192,152.2 -15.497,153.553 -17.12,153.92 -18.073,154.622 -19.892,154.873 -20.546,155.073 -21.695,155.252 -23.066,155.659 -26.578,155.924 -27.67,156.515 -30.111,156.709 -33.377,156.73 -33.716,156.551 -36.857,155.016 -39.177,154.898 -39.324,154.514 -39.8,153.089 -41.569,151.733 -43.353,150.841 -45.602,150.55 -46.531,150.305 -47.315,149.128 -48.671,146.879 -49.1,144.595 -47.244,143.952 -43.782,141.168 -41.105,140.954 -40.997,140.812 -40.925,137.635 -39.32,135.134 -38.27,134.802 -38.131,133.248 -37.479,132.959 -37.357,129.012 -36.976,127.784 -36.858,123.585 -38.138,122.751 -38.392,119.004 -38.821,113.578 -37.857,113.187 -37.562,110.259 -35.359,108.474 -31.611,108.151 -30.298,107.332 -26.971,107.082 -23.901,107.368 -21.724,108.503 -20.631,110.617 -18.594,111.258 -17.977,112.06 -17.528,113.685 -16.62,113.765 -16.609,116.326 -16.263,117.708 -15.59,119.182 -14.871,120.911 -13.61,122.216 -12.658,' +
      '122.627 -12.453,124.214 -11.659,125.817 -10.995,126.713 -10.624,130.496 -9.589,131.307 -9.544,135.065 -9.339,137.99 -9.35,140.479 -9.359,143.696 -9.578,143.873 -9.666,144.375 -9.914,144.569 -10.011',
    'New South Wales': '154.8449757 -39.38993065,153.1824593 -38.74150799,151.5199429 -38.09308532,149.8574264 -37.44466266,148.19491 -36.79624,148.11017 -36.80125,148.1314 -36.73508,148.2158 -36.64044,148.21661 -36.59806,148.13386 -36.56783,148.12339 -36.46366,148.03921 -36.39021,148.05913 -36.32272,148.03647 -36.31055,148.03858 -36.14206,147.99104 -36.12167,147.99991 -36.04725,147.9251 -36.04365,147.91247 -35.99556,147.70936 -35.92902,147.60279 -35.97835,147.54899 -35.96453,147.58333 -35.98249,147.55201 -36.00451,147.49309 -35.94322,147.40425 -35.94408,147.31877 -36.06078,147.14354 -36.03788,147.12159 -35.9985,147.0528 -36.10731,146.9579 -36.07935,146.94346 -36.11609,146.87228 -36.07513,146.8245 -36.08854,146.80292 -36.056,146.74852 -36.06051,146.73098 -36.02432,146.68504 -36.04047,146.612 -35.97193,146.56134 -35.96721,146.54971 -35.99187,146.50246 -35.95778,146.49533 -35.99137,146.4209 -35.96567,146.36838 -36.05115,146.3376 -36.02666,' +
      '146.29269 -36.04661,146.28133 -36.01124,146.18502 -36.0421,146.13498 -36.0027,145.97075 -36.01533,145.95567 -35.96183,145.90278 -35.95217,145.8098 -35.99108,145.67392 -35.925,145.5348 -35.8023,145.35678 -35.86648,145.12783 -35.82078,144.99226 -35.8519,144.96832 -35.86567,144.95679 -35.96134,144.92389 -35.98165,144.98278 -36.07182,144.86482 -36.06014,144.80808 -36.12384,144.72739 -36.11858,144.71897 -36.08697,144.68376 -36.0956,144.68914 -36.06272,144.65598 -36.07631,144.63509 -36.04883,144.61833 -36.07781,144.61662 -36.03284,144.58448 -36.04056,144.40825 -35.90387,144.41122 -35.85877,144.38166 -35.85254,144.3483 -35.76706,144.29604 -35.73699,144.26252 -35.75024,144.10532 -35.59098,144.03979 -35.54929,143.98934 -35.55425,143.96931 -35.50105,143.85827 -35.47954,143.74985 -35.38788,143.68453 -35.36831,143.64241 -35.40026,143.61976 -35.38674,143.56253 -35.33711,143.58967 -35.28082,143.56876 -35.20536,143.47195 -35.21899,' +
      '143.44931 -35.18911,143.39458 -35.19208,143.32252 -35.03629,143.31725 -34.95209,143.34134 -34.93208,143.32089 -34.88792,143.35128 -34.858,143.34958 -34.79326,143.27597 -34.79215,143.25157 -34.77241,143.27301 -34.75205,143.22566 -34.75828,143.21864 -34.72956,143.10781 -34.7009,143.1128 -34.68076,143.03918 -34.70192,142.9896 -34.66231,142.97251 -34.69468,142.9241 -34.64751,142.88679 -34.67943,142.86016 -34.66455,142.86833 -34.62754,142.78726 -34.58904,142.80969 -34.56022,142.79128 -34.54615,142.78329 -34.58,142.74879 -34.57722,142.75936 -34.60015,142.70077 -34.59844,142.69006 -34.65108,142.71168 -34.67442,142.67888 -34.68007,142.69963 -34.72424,142.61768 -34.72921,142.63792 -34.78071,142.5199 -34.75643,142.4994 -34.73921,142.51591 -34.66234,142.47605 -34.67149,142.48545 -34.63683,142.45297 -34.63363,142.46796 -34.56468,142.44788 -34.55814,142.45189 -34.58336,142.36785 -34.5308,142.40022 -34.52309,142.37578 -34.51521,' +
      '142.39346 -34.49269,142.35974 -34.49848,142.3841 -34.47752,142.35452 -34.47044,142.35172 -34.39651,142.39631 -34.33578,142.28468 -34.32588,142.28792 -34.2969,142.23635 -34.30656,142.24456 -34.21297,142.22257 -34.18362,142.16735 -34.18243,142.14782 -34.14901,142.08444 -34.17283,142.07726 -34.13215,142.02965 -34.12536,142.04016 -34.10032,141.96853 -34.13661,141.90044 -34.11119,141.87959 -34.13959,141.73102 -34.09134,141.7188 -34.11489,141.67774 -34.10143,141.61921 -34.15647,141.58409 -34.15281,141.60421 -34.19028,141.51036 -34.21657,141.49823 -34.15611,141.44583 -34.16813,141.36013 -34.11113,141.32554 -34.13907,141.24346 -34.06339,141.18661 -34.09242,141.15261 -34.0556,141.09479 -34.05144,141.08847 -34.07768,141.03518 -34.03676,141.02103 -34.05854,141.00199 -34.03755,141.0010867 -32.35806333,141.0001833 -30.67857667,140.99928 -28.99909,142.591264 -28.999074,144.183248 -28.999058,145.775232 -28.999042,147.367216 -28.999026,' +
      '148.9592 -28.99901,149.03234 -28.9557,149.08064 -28.84226,149.14072 -28.83246,149.13957 -28.8072,149.29849 -28.70887,149.38788 -28.69533,149.45347 -28.59438,149.50273 -28.57442,149.58755 -28.57328,149.67753 -28.635,149.72123 -28.60945,149.97655 -28.61325,150.01655 -28.57955,150.15253 -28.5495,150.2123 -28.5732,150.29192 -28.53678,150.44184 -28.66394,150.63314 -28.67676,150.75147 -28.63505,151.00868 -28.74128,151.04532 -28.84477,151.10307 -28.83615,151.27575 -28.93906,151.28063 -29.1032,151.31497 -29.16518,151.39455 -29.17744,151.50108 -29.07193,151.49812 -29.02027,151.5435 -28.95311,151.72487 -28.86834,151.77608 -28.95796,151.83121 -28.96225,151.85092 -28.90816,151.91646 -28.92972,152.01007 -28.90523,152.03899 -28.86403,152.01653 -28.84874,152.03673 -28.75691,152.07619 -28.70938,152.00204 -28.6506,151.95712 -28.51867,151.98015 -28.50262,152.01473 -28.52511,152.06836 -28.46657,152.16 -28.43188,152.21736 -28.44899,' +
      '152.3101 -28.36311,152.38818 -28.36789,152.4145 -28.29545,152.46834 -28.25813,152.53363 -28.26365,152.52489 -28.30491,152.57832 -28.33845,152.61357 -28.27165,152.64073 -28.31266,152.7524 -28.3643,152.88009 -28.30983,153.10912 -28.35668,153.17666 -28.25004,153.36691 -28.24683,153.47715 -28.15745,153.53442 -28.17702,154.6787472 -27.71652313,155.8230744 -27.25602625,156.169195 -28.68373675,156.5153157 -30.11144724,156.586712 -31.31307444,156.6581082 -32.51470165,156.7295045 -33.71632885,156.6402975 -35.28677659,156.5510906 -36.85722433,155.7837471 -38.01722286,155.0164036 -39.1772214,154.8449757 -39.38993065',
    'Northern Territory': '137.99466 -9.35000776,137.9951 -11.01469398,137.99554 -12.67938021,137.99598 -14.34406643,137.99642 -16.00875266,137.99686 -17.67343888,137.9973 -19.3381251,137.99774 -21.00281133,137.99818 -22.66749755,137.99862 -24.33218378,137.99906 -25.99687,136.499315 -25.99716,134.99957 -25.99745,133.499825 -25.99774,132.00008 -25.99803,130.500335 -25.99832,129.00059 -25.99861,129.0006352 -24.22074673,129.0006803 -22.44288347,129.0007255 -20.6650202,129.0007707 -18.88715693,129.0008159 -17.10929366,129.000861 -15.3314304,129.0009062 -13.55356713,129.0009514 -11.77570386,129.0009965 -9.9978406,130.4960776 -9.58880127,132.0189206 -9.5055153,133.5417637 -9.42222932,135.0646068 -9.33894334,136.5296334 -9.34447555,137.99466 -9.35000776',
    Queensland: '155.8230744 -27.25602625,154.6787472 -27.71652313,153.53442 -28.17702,153.47715 -28.15745,153.36691 -28.24683,153.17666 -28.25004,153.10912 -28.35668,152.88009 -28.30983,152.7524 -28.3643,152.64073 -28.31266,152.61357 -28.27165,152.57832 -28.33845,152.52489 -28.30491,152.53363 -28.26365,152.46834 -28.25813,152.4145 -28.29545,152.38818 -28.36789,152.3101 -28.36311,152.21736 -28.44899,152.16 -28.43188,152.06836 -28.46657,152.01473 -28.52511,151.98015 -28.50262,151.95712 -28.51867,152.00204 -28.6506,152.07619 -28.70938,152.03673 -28.75691,152.01653 -28.84874,152.03899 -28.86403,152.01007 -28.90523,151.91646 -28.92972,151.85092 -28.90816,151.83121 -28.96225,151.77608 -28.95796,151.72487 -28.86834,151.5435 -28.95311,151.49812 -29.02027,151.50108 -29.07193,151.39455 -29.17744,151.31497 -29.16518,151.28063 -29.1032,151.27575 -28.93906,151.10307 -28.83615,151.04532 -28.84477,151.00868 -28.74128,150.75147 -28.63505,' +
      '150.63314 -28.67676,150.44184 -28.66394,150.29192 -28.53678,150.2123 -28.5732,150.15253 -28.5495,150.01655 -28.57955,149.97655 -28.61325,149.72123 -28.60945,149.67753 -28.635,149.58755 -28.57328,149.50273 -28.57442,149.45347 -28.59438,149.38788 -28.69533,149.29849 -28.70887,149.13957 -28.8072,149.14072 -28.83246,149.08064 -28.84226,149.03234 -28.9557,148.9592 -28.99901,147.367216 -28.999026,145.775232 -28.999042,144.183248 -28.999058,142.591264 -28.999074,140.99928 -28.99909,140.999335 -27.497735,140.99939 -25.99638,139.499225 -25.996625,137.99906 -25.99687,137.99862 -24.33218378,137.99818 -22.66749755,137.99774 -21.00281133,137.9973 -19.3381251,137.99686 -17.67343888,137.99642 -16.00875266,137.99598 -14.34406643,137.99554 -12.67938021,137.9951 -11.01469398,137.99466 -9.35000776,139.2368396 -9.35469846,140.4790191 -9.35938916,142.0875175 -9.46883858,143.6960159 -9.578288,144.8232741 -10.13673969,145.9505322 -10.69519139,' +
      '146.5037528 -11.46255996,147.0569735 -12.22992853,148.0028 -12.97944967,148.9486266 -13.72897082,150.4476696 -14.46064344,151.9467126 -15.19231607,152.7497736 -16.15598739,153.5528347 -17.1196587,153.9930349 -18.26179082,154.4332351 -19.40392293,154.8734353 -20.54605505,155.0875918 -21.77741897,155.3017483 -23.0087829,155.4802194 -24.79337149,155.6586904 -26.57796008,155.8230744 -27.25602625',
    'South Australia': '140.99928 -28.99909,141.0004467 -30.67317,141.0016133 -32.34725,141.00278 -34.02133,140.96368 -33.98054,140.9668867 -35.1875,140.9700933 -36.39446,140.9733 -37.60142,140.9491361 -39.29166522,140.9249722 -40.98191044,139.8282199 -40.42800316,138.7314675 -39.87409588,137.6347152 -39.32018861,136.0761719 -38.66585073,134.5176285 -38.01151285,132.9590852 -37.35717498,131.6398102 -37.22981122,130.3205351 -37.10244746,129.00126 -36.97508369,129.0013457 -35.40701602,129.0014314 -33.83894835,129.0015171 -32.27088068,129.0016029 -30.70281301,129.0016886 -29.13474534,129.0017743 -27.56667767,129.00186 -25.99861,130.7157929 -25.99829143,132.4297257 -25.99797286,134.1436586 -25.99765429,135.8575914 -25.99733571,137.5715243 -25.99701714,139.2854571 -25.99669857,140.99939 -25.99638,140.999335 -27.497735,140.99928 -28.99909',
    Tasmania: '153.5876659 -40.95001165,152.6602778 -42.15162266,151.7328898 -43.35323367,151.2867755 -44.47754259,150.8406613 -45.60185152,150.3053377 -47.31508457,149.1275665 -48.67140517,148.0032994 -48.88557385,146.8790323 -49.09974252,145.7368833 -48.17175876,144.5947343 -47.24377499,144.2734659 -45.51270514,143.9521976 -43.78163529,142.7998541 -42.67362644,141.6475106 -41.56561759,142.5655342 -40.58174878,143.4835577 -39.59787998,144.1878781 -39.3218625,145.1642899 -39.39522921,146.1407018 -39.46859593,147.4850972 -39.21875252,148.4518722 -39.20764837,149.384621 -39.27427328,150.3887535 -39.60739782,151.3928861 -39.94052236,152.490276 -40.44526701,153.5876659 -40.95001165',
    Victoria: '141.00278 -34.02133,141.02103 -34.05854,141.03518 -34.03676,141.08847 -34.07768,141.09737 -34.0509,141.15261 -34.0556,141.18445 -34.0923,141.24346 -34.06339,141.32554 -34.13907,141.36013 -34.11113,141.44583 -34.16813,141.49498 -34.15475,141.51036 -34.21657,141.60421 -34.19028,141.58409 -34.15281,141.61921 -34.15647,141.67774 -34.10143,141.7188 -34.11489,141.73102 -34.09134,141.87959 -34.13959,141.90044 -34.11119,141.96853 -34.13661,142.04016 -34.10032,142.02965 -34.12536,142.07726 -34.13215,142.08444 -34.17283,142.14782 -34.14901,142.16735 -34.18243,142.22257 -34.18362,142.24456 -34.21297,142.23635 -34.30656,142.28792 -34.2969,142.28468 -34.32588,142.39631 -34.33578,142.35172 -34.39651,142.35452 -34.47044,142.3841 -34.47752,142.35974 -34.49848,142.39346 -34.49269,142.37578 -34.51521,142.40022 -34.52309,142.36785 -34.5308,142.45189 -34.58336,142.44788 -34.55814,142.46796 -34.56468,142.45297 -34.63363,' +
      '142.48545 -34.63683,142.47605 -34.67149,142.51591 -34.66234,142.4994 -34.73921,142.5199 -34.75643,142.63792 -34.78071,142.61768 -34.72921,142.69963 -34.72424,142.67888 -34.68007,142.71168 -34.67442,142.69006 -34.65108,142.70077 -34.59844,142.75936 -34.60015,142.74879 -34.57722,142.78329 -34.58,142.79128 -34.54615,142.80969 -34.56022,142.78726 -34.58904,142.86833 -34.62754,142.86016 -34.66455,142.88679 -34.67943,142.9241 -34.64751,142.97251 -34.69468,142.9896 -34.66231,143.03918 -34.70192,143.1128 -34.68076,143.10781 -34.7009,143.21864 -34.72956,143.22566 -34.75828,143.27301 -34.75205,143.25157 -34.77241,143.27597 -34.79215,143.34958 -34.79326,143.35128 -34.858,143.32089 -34.88792,143.34134 -34.93208,143.31725 -34.95209,143.32252 -35.03629,143.39458 -35.19208,143.44931 -35.18911,143.47195 -35.21899,143.56876 -35.20536,143.58967 -35.28082,143.56253 -35.33711,143.61976 -35.38674,143.64241 -35.40026,143.68453 -35.36831,' +
      '143.74985 -35.38788,143.85827 -35.47954,143.96931 -35.50105,143.98934 -35.55425,144.03979 -35.54929,144.10532 -35.59098,144.26252 -35.75024,144.29604 -35.73699,144.3483 -35.76706,144.38166 -35.85254,144.41122 -35.85877,144.40825 -35.90387,144.58448 -36.04056,144.61662 -36.03284,144.61833 -36.07781,144.63509 -36.04883,144.65598 -36.07631,144.68914 -36.06272,144.68376 -36.0956,144.71897 -36.08697,144.72739 -36.11858,144.80808 -36.12384,144.86482 -36.06014,144.98278 -36.07182,144.92389 -35.98165,144.95679 -35.96134,144.96832 -35.86567,144.99226 -35.8519,145.12783 -35.82078,145.35678 -35.86648,145.5348 -35.8023,145.67392 -35.925,145.8098 -35.99108,145.90278 -35.95217,145.95567 -35.96183,145.97075 -36.01533,146.13498 -36.0027,146.18502 -36.0421,146.28133 -36.01124,146.29269 -36.04661,146.3376 -36.02666,146.36838 -36.05115,146.4209 -35.96567,146.49533 -35.99137,146.50246 -35.95778,146.54971 -35.99187,146.56134 -35.96721,' +
      '146.612 -35.97193,146.68504 -36.04047,146.73098 -36.02432,146.74852 -36.06051,146.80292 -36.056,146.8245 -36.08854,146.87228 -36.07513,146.94346 -36.11609,146.9579 -36.07935,147.0528 -36.10731,147.12159 -35.9985,147.14354 -36.03788,147.31877 -36.06078,147.40425 -35.94408,147.49309 -35.94322,147.55201 -36.00451,147.58333 -35.98249,147.54899 -35.96453,147.60279 -35.97835,147.70936 -35.92902,147.91247 -35.99556,147.9251 -36.04365,147.99991 -36.04725,147.99104 -36.12167,148.03858 -36.14206,148.03647 -36.31055,148.05913 -36.32272,148.03921 -36.39021,148.12339 -36.46366,148.13386 -36.56783,148.21661 -36.59806,148.2158 -36.64044,148.1314 -36.73508,148.11017 -36.80125,148.19491 -36.79624,149.8574264 -37.44466266,151.5199429 -38.09308532,153.1824593 -38.74150799,154.8449757 -39.38993065,154.2163208 -40.16997115,153.5876659 -40.95001165,152.490276 -40.44526701,151.3928861 -39.94052236,150.3887535 -39.60739782,149.384621 -39.27427328,' +
      '148.4518722 -39.20764837,147.4850972 -39.21875252,146.1407018 -39.46859593,145.1642899 -39.39522921,144.1878781 -39.3218625,143.4835577 -39.59787998,142.5655342 -40.58174878,141.6475106 -41.56561759,141.1682072 -41.10475462,140.9249722 -40.98191044,140.9491361 -39.29166522,140.9733 -37.60142,140.9700933 -36.39446,140.9668867 -35.1875,140.96368 -33.98054,141.00278 -34.02133',
    'Western Australia': '129.00186 -25.99861,129.0017743 -27.56667767,129.0016886 -29.13474534,129.0016029 -30.70281301,129.0015171 -32.27088068,129.0014314 -33.83894835,129.0013457 -35.40701602,129.00126 -36.97508369,127.7837913 -36.85754839,126.1062921 -37.36915998,124.4287928 -37.88077156,122.7512936 -38.39238314,121.5020848 -38.53516946,120.252876 -38.67795578,119.0036672 -38.8207421,117.6473718 -38.57983665,116.2910763 -38.3389312,114.9347808 -38.09802575,113.5784853 -37.8571203,112.472019 -37.02431115,111.3655527 -36.191502,110.2590863 -35.35869285,109.6641988 -34.10946501,109.0693112 -32.86023716,108.4744237 -31.61100932,108.0936877 -30.06434301,107.7129516 -28.51767671,107.3322156 -26.9710104,107.2072762 -25.43624047,107.0823367 -23.90147054,107.225094 -22.8128537,107.3678512 -21.72423687,108.3404494 -20.78730814,109.3130475 -19.85037941,110.2856456 -18.91345068,111.2582437 -17.97652195,112.471764 -17.29836281,113.6852843 -16.62020366,' +
      '115.0058826 -16.44173486,116.3264809 -16.26326605,117.7541502 -15.56726221,119.1818195 -14.87125838,120.1930831 -14.1336183,121.2043468 -13.39597823,122.2156105 -12.65833815,123.2149776 -12.1586463,124.2143447 -11.65895445,125.4635533 -11.14141666,126.7127618 -10.62387887,127.8568792 -10.31085973,129.0009965 -9.9978406,129.0010925 -11.77570386,129.0011884 -13.55356713,129.0012844 -15.3314304,129.0013803 -17.10929366,129.0014762 -18.88715693,129.0015722 -20.6650202,129.0016681 -22.44288347,129.0017641 -24.22074673,129.00186 -25.99861',
    World: '-180 -90, 180 -90, 180 90, -180 90, -180 -90'
  },
  boundaries100kmBuffer: {
    Australia: '144.569 -10.011,145.951 -10.695,147.057 -12.23,148.949 -13.729,149.691 -14.091,151.947 -15.192,152.2 -15.497,153.553 -17.12,153.92 -18.073,154.622 -19.892,154.873 -20.546,155.073 -21.695,155.252 -23.066,155.659 -26.578,155.924 -27.67,156.515 -30.111,156.709 -33.377,156.73 -33.716,156.551 -36.857,155.016 -39.177,154.898 -39.324,154.514 -39.8,153.089 -41.569,151.733 -43.353,150.841 -45.602,150.55 -46.531,150.305 -47.315,149.128 -48.671,146.879 -49.1,144.595 -47.244,143.952 -43.782,141.168 -41.105,140.954 -40.997,140.812 -40.925,137.635 -39.32,135.134 -38.27,134.802 -38.131,133.248 -37.479,132.959 -37.357,129.012 -36.976,127.784 -36.858,123.585 -38.138,122.751 -38.392,119.004 -38.821,113.578 -37.857,113.187 -37.562,110.259 -35.359,108.474 -31.611,108.151 -30.298,107.332 -26.971,107.082 -23.901,107.368 -21.724,108.503 -20.631,110.617 -18.594,111.258 -17.977,112.06 -17.528,113.685 -16.62,113.765 -16.609,116.326 -16.263,117.708 -15.59,119.182 -14.871,120.911 -13.61,122.216 -12.658,' +
      '122.627 -12.453,124.214 -11.659,125.817 -10.995,126.713 -10.624,130.496 -9.589,131.307 -9.544,135.065 -9.339,137.99 -9.35,140.479 -9.359,143.696 -9.578,143.873 -9.666,144.375 -9.914,144.569 -10.011',
    'New South Wales': '139.9730072 -28.9982605,140.0409546 -28.6796341,140.2339172 -28.4001560,140.5265350 -28.1990623,140.8823242 -28.1028004,142.1549377 -28.1018200,143.4275665 -28.1008396,144.7001801 -28.0998592,145.9727936 -28.0988808,147.2454071 -28.0979004,148.5180206 -28.0969200,149.2123718 -27.7095528,150.2629242 -27.6347961,151.2765350 -27.8472900,152.1056976 -27.4152851,152.4805145 -27.3476887,152.8526917 -27.3947182,153.3310852 -27.2645607,154.4844360 -26.8167572,155.6377869 -26.3689518,155.9302979 -27.6164513,156.2228088 -28.8639488,156.5153198 -30.1114464,156.5867157 -31.3130741,156.6581116 -32.5147018,156.7295074 -33.7163277,156.6700287 -34.7632942,156.6105652 -35.8102608,156.5510864 -36.8572235,155.7837524 -38.0172234,155.0164032 -39.1772232,154.2245178 -40.1597939,152.8954773 -39.6470222,151.5664368 -39.1342506,150.2373962 -38.6214790,148.9083557 -38.1087112,147.5793152 -37.5959396,147.2251892 -37.3575325,147.0133057 -37.0170670,145.5216064 -36.8616333,144.7009583 -37.0291901,' +
      '144.0530548 -36.8547516,143.3721771 -36.3033905,142.8777924 -36.0547333,142.4657440 -35.6780701,141.8877716 -35.4931374,141.5060883 -35.1179924,140.4425659 -34.8220558,140.0066528 -34.3988571,139.9190826 -34.0466347,139.9325562 -32.7845421,139.9460449 -31.5224476,139.9595337 -30.2603550,139.9730072 -28.9982605',
    'Northern Territory': '137.9449463 -26.8981190,136.6581421 -26.8982868,135.3713531 -26.8984547,134.0845490 -26.8986225,132.7977600 -26.8987904,131.5109558 -26.8989563,130.2241516 -26.8991241,128.9373627 -26.8992920,128.4616852 -26.7588215,128.1203613 -26.4257355,128.0017700 -26.0033016,128.0096283 -24.5709629,128.0174866 -23.1386242,128.0253448 -21.7062855,128.0332031 -20.2739449,128.0410614 -18.8416061,128.0489197 -17.4092674,128.0567780 -15.9769287,128.0646362 -14.5445890,128.0724945 -13.1122503,128.0803375 -11.6799116,128.0881958 -10.2475719,129.2921448 -9.9181871,130.4960785 -9.5888014,131.6382141 -9.5263367,132.7803497 -9.4638720,133.9224701 -9.4014082,135.0646057 -9.3389435,136.3447418 -9.3437777,137.6248627 -9.3486109,138.9049988 -9.3534451,138.9126282 -10.7444162,138.9202728 -12.1353865,138.9279022 -13.5263567,138.9355469 -14.9173279,138.9431763 -16.3082981,138.9508209 -17.6992683,138.9584503 -19.0902405,138.9660797 -20.4812107,138.9737244 -21.8721809,138.9813538 -23.2631512,138.9889984 -24.6541214,138.9966278 -26.0450935,138.9014282 -26.3866234,138.6696014 -26.6673756,138.3320160 -26.8481979,137.9449463 -26.8981190',
    Queensland: '140.9689789 -29.9007530,140.8059845 -29.8851490,140.4580688 -29.7667198,140.1807404 -29.5458183,140.0123901 -29.2502937,140.0026245 -28.0746918,139.9928436 -26.8990898,138.8610992 -26.8826256,137.7293549 -26.8661613,137.2551727 -26.6010914,137.0142365 -26.1507378,137.0200806 -24.7503891,137.0259247 -23.3500423,137.0317688 -21.9496956,137.0376129 -20.5493488,137.0434570 -19.1490002,137.0492859 -17.7486534,137.0551300 -16.3483067,137.0609741 -14.9479589,137.0668182 -13.5476122,137.0726624 -12.1472645,137.0785065 -10.7469177,137.0843506 -9.3465700,138.2158966 -9.3508434,139.3474579 -9.3551159,140.4790192 -9.3593893,141.5513458 -9.4323559,142.6236877 -9.5053215,143.6960144 -9.5782881,144.8232727 -10.1367397,145.9505310 -10.6951914,146.5037537 -11.4625597,147.0569763 -12.2299290,148.0028076 -12.9794493,148.9486237 -13.7289705,149.9479828 -14.2167530,150.9473572 -14.7045345,151.9467163 -15.1923161,152.7497711 -16.1559868,153.5528412 -17.1196594,153.9930420 -18.2617912,154.4332275 -19.4039230,' +
      '154.8734283 -20.5460548,155.0875854 -21.7774181,155.3017426 -23.0087833,155.4207306 -24.1985092,155.5397034 -25.3882351,155.6586914 -26.5779610,155.8503265 -27.3684540,156.0419769 -28.1589489,154.7669830 -28.6761246,153.4920044 -29.1933022,152.9661865 -29.2518692,152.5697937 -29.6623135,151.5313263 -30.0716381,150.9179382 -30.0030499,150.6084137 -29.8223095,150.3929749 -29.5687771,150.0877991 -29.5109863,149.8269043 -29.5288563,149.4330444 -29.8001137,149.0100555 -29.9000778,147.6698761 -29.9001904,146.3296967 -29.9003029,144.9895172 -29.9004154,143.6493378 -29.9005280,142.3091583 -29.9006405,140.9689789 -29.9007530',
    'South Australia': '140.9417114 -25.0937328,141.3327026 -25.1458340,141.6683960 -25.3276634,141.9028473 -25.6143208,141.9970093 -25.9560108,142.0075684 -27.3233814,142.0181274 -28.6907501,142.0286865 -30.0581207,142.0392609 -31.4254913,142.0498199 -32.7928619,142.0603790 -34.1602287,142.0709381 -35.5275993,142.0814972 -36.8949699,142.0920563 -38.2623405,142.1026154 -39.6297073,142.1131744 -40.9970779,142.0429535 -41.2923431,141.8372192 -41.5625114,141.7312622 -41.6461449,141.1682129 -41.1047554,139.9903717 -40.5098991,138.8125458 -39.9150429,137.6347198 -39.3201904,136.4658051 -38.8294334,135.2969055 -38.3386803,134.1279907 -37.8479271,132.9590912 -37.3571739,131.6892395 -37.2345848,130.4193878 -37.1119919,129.1495361 -36.9893990,127.8796997 -36.8668060,127.8950653 -35.5135078,127.9104385 -34.1602135,127.9258118 -32.8069153,127.9411850 -31.4536171,127.9565582 -30.1003189,127.9719315 -28.7470207,127.9873047 -27.3937225,128.0026703 -26.0404243,128.0345154 -25.7772064,128.3128357 -25.3467522,128.6273041 -25.1623096,128.9771576 -25.0962505,130.4727325 -25.0959358,131.9682922 -25.0956211,133.4638672 -25.0953064,134.9594421 -25.0949917,136.4550018 -25.0946770,137.9505768 -25.0943623,139.4461365 -25.0940475,140.9417114 -25.0937328',
    Tasmania: '149.8531647 -38.4513512,150.8885345 -38.7952538,151.9239044 -39.1391563,153.0649414 -39.6609764,154.2059784 -40.1828003,153.3816223 -41.2396126,152.5572510 -42.2964211,151.7328949 -43.3532333,151.2867737 -44.4775429,150.8406677 -45.6018524,150.5729980 -46.4584694,150.3053436 -47.3150864,149.7164459 -47.9932442,149.1275635 -48.6714058,148.0032959 -48.8855743,146.8790283 -49.0997429,145.7368774 -48.1717606,144.5947418 -47.2437744,144.3805542 -46.0897293,144.1663818 -44.9356804,143.9521942 -43.7816353,143.0242004 -42.8893433,142.0962067 -41.9970474,141.1682129 -41.1047554,140.7966156 -40.9170876,141.7200317 -39.9472008,142.6434479 -38.9773102,143.8092346 -38.4710579,144.2414246 -38.4221115,145.1507416 -38.4898758,146.0600433 -38.5576439,147.3305054 -38.3261108,148.4461212 -38.3068733,149.8531647 -38.4513512',
    Victoria: '140.9855652 -33.0792351,141.5536041 -33.2018814,142.2497101 -33.2158241,142.7033386 -33.3699303,143.1636963 -33.6998405,144.0664520 -34.1150246,144.2941742 -34.3433800,144.4822693 -34.7038879,144.8677673 -34.9417114,145.6583710 -34.9066734,146.2036896 -35.0806770,146.9339905 -35.1102791,147.8009186 -35.0308266,148.3804779 -35.1793594,148.9218750 -35.5491333,149.2181854 -36.2024918,150.4542542 -36.6818962,151.6903381 -37.1612968,152.9264069 -37.6407013,154.1624908 -38.1201019,155.3985596 -38.5995064,154.5946960 -39.6424484,153.7908325 -40.6853905,152.9869690 -41.7283325,151.9199677 -41.2356033,150.8529816 -40.7428741,149.9733276 -40.4504318,149.0936737 -40.1579895,147.6317444 -40.1178284,146.1679840 -40.3689804,145.2908630 -40.3063393,144.4137421 -40.2437019,144.2751007 -40.2981911,143.3310394 -41.2874222,142.3869934 -42.2766533,141.7776031 -41.6907043,141.1682129 -41.1047554,140.4617004 -40.7479362,139.7551880 -40.3911171,139.7825165 -39.0850220,139.8098602 -37.7789268,139.8371887 -36.4728279,139.8645325 -35.1667328,139.8918610 -33.8606339,140.0154266 -33.5497208,140.2611542 -33.2967606,140.6001434 -33.1318741,140.9855652 -33.0792351',
    'Western Australia': '129.9486084 -25.7140846,129.9696503 -27.1351719,129.9906769 -28.5562592,130.0117035 -29.9773464,130.0327454 -31.3984337,130.0537720 -32.8195229,130.0747986 -34.2406082,130.0958405 -35.6616974,130.1168671 -37.0827866,128.9503326 -36.9701653,127.7837906 -36.8575478,126.5256653 -37.2412567,125.2675400 -37.6249657,124.0094147 -38.0086746,122.7512970 -38.3923836,121.5020828 -38.5351677,120.2528763 -38.6779556,119.0036697 -38.8207436,117.6473694 -38.5798378,116.2910767 -38.3389320,114.9347839 -38.0980263,113.5784836 -37.8571205,112.4720154 -37.0243111,111.3655548 -36.1915016,110.2590866 -35.3586922,109.6641998 -34.1094666,109.0693130 -32.8602371,108.4744263 -31.6110096,108.1888733 -30.4510098,107.9033203 -29.2910099,107.6177673 -28.1310101,107.3322144 -26.9710102,107.2489243 -25.9478302,107.1656265 -24.9246502,107.0823364 -23.9014702,107.2250977 -22.8128529,107.3678513 -21.7242374,108.3404465 -20.7873077,109.3130493 -19.8503799,110.2856445 -18.9134502,111.2582474 -17.9765224,112.4717636 -17.2983627,113.6852875 -16.6202030,115.0058823 -16.4417343,116.3264847 -16.2632656,117.2782593 -15.7992640,118.2300415 -15.3352613,119.1818161 -14.8712587,120.1930847 -14.1336184,121.2043457 -13.3959780,122.2156067 -12.6583385,123.2149811 -12.1586466,124.2143478 -11.6589546,125.4635544 -11.1414165,126.7127609 -10.6238785,127.7684708 -10.3350468,128.8241882 -10.0462151,129.8798981 -9.7573833,129.8861389 -11.2079926,129.8923950 -12.6586018,129.8986359 -14.1092110,129.9048767 -15.5598202,129.9111328 -17.0104294,129.9173737 -18.4610386,129.9236298 -19.9116478,129.9298706 -21.3622570,129.9361267 -22.8128662,129.9423676 -24.2634754,129.9486084 -25.7140846',
    World: '-180 -90, 180 -90, 180 90, -180 90, -180 -90'
  },
  boundaryBandaSeaMagnitude5: [[142.5, -7.7978358], [142.5, -9.4951809889], [140.829620000099993, -9.37689], [134.804230000399997, -9.36324], [130.494899999599994, -9.5902], [126.811330000300003, -10.591759999800001], [124.213099999899995, -11.6604], [122.214299999800005, -12.6597], [122.009330000399999, -7.9427], [129.153249999800011, -4.7932500001], [131.851950000100004, -4.1766399997], [131.934890000100012, -4.8915299997], [131.686569999800014, -7.5101699997], [141.007280000099996, -7.4592899997], [142.5, -7.7978358]],
  recentEarthquakes: {
    periodList: [{ text: '30 days', value: 30 }, { text: '7 days', value: 7 }, { text: '1 day', value: 1 }],
    magnitudeList: [{ text: 'All Magnitudes', value: 10 }, { text: 'Magnitude 5+', value: 5 }, { text: 'Magnitude 2.5+', value: 2.5 }],
    regionList: ['World', 'Australia']
  },
  autoRefresh: {
    rateInSeconds: [0, 15, 30, 60, 300, 600],
    localStorageKey: 'update-interval.earthquakes:training',
    defaultInterval: 15
  },
  toolbar: {
    sidePanels: {
      defaultPanelWidth: 500,
      maxScreenWidthDefaultPanelWidth: 1800,
      maxScreenWidthPanelOverlap: 1200,
      mobilePanelWidth: 400,
      transitions: {
        left: [
          {
            class: '.ol-scale-line',
            offset: 10
          }
        ],
        right: [
          {
            class: '.ol-attribution',
            offset: 180
          },
          {
            class: '.ol-overviewmap',
            offset: 10
          },
          {
            class: '.ol-mouse-position',
            offset: 10
          },
          {
            class: '.ol-zoomslider',
            offset: 10
          },
        ]
      }
    },
    tools: {
      left: [
        {
          toolId: 'about',
          displayText: 'About',
          panelWidth: 700,
          icon: 'assets/info-16.png'
        },
        {
          toolId: 'emergencyInfo',
          displayText: 'Emergency Info',
          panelWidth: 700,
          icon: 'assets/warning-3-16.png'
        },
        {
          toolId: 'layers',
          displayText: 'Layers',
          panelWidth: 700,
          icon: 'assets/layers-16.png'
        },
        {
          toolId: 'legend',
          displayText: 'Legend',
          panelWidth: 700,
          icon: 'assets/legend-16.png'
        },
        {
          toolId: 'notifications',
          displayText: 'Notifications',
          panelWidth: 700,
          icon: 'assets/rss-4-16.png'
        }
      ],
      right: [
        {
          toolId: 'identify',
          displayText: 'Earthquake Details',
          panelWidth: 700,
          icon: 'assets/list-view-16.png'
        },
        {
          toolId: 'search',
          displayText: 'Search',
          panelWidth: 700,
          icon: 'assets/search-15-16.png'
        },
        {
          toolId: 'feltReport',
          displayText: 'Felt Report',
          panelWidth: 700,
          icon: 'assets/report-3-16.png'
        },
        {
          toolId: 'recentQuakes',
          displayText: 'Recent Earthquakes',
          panelWidth: 700,
          icon: 'assets/lightning-bolt-16.png'
        }
      ]
    }
  },
  applicationStateApiKey: '4AWOsocFzN3vbTcXZoXLy4nDP4PcMfH118WZ3LYo',
  applicationStateApiUrl: 'https://api.customerdigitalservices.ga.gov.au/applicationState/',
  iconsCacheUrl: 'https://customerdigitalservices.ga.gov.au/icons',
  identityPoolId: 'ap-southeast-2:aba9cd49-6734-4dee-b5e8-d89fe4b7b2a8',
  placeIndexName: 'earthquakes-esri-place-index-training'
};
