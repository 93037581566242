import { ReverseGeocodingProvider } from '@shared/services/location/reverse-geocoding/reverse.geocoding.provider';
import { from, Observable } from 'rxjs';
import { ReverseGeocodingResultType } from '@shared/services/location/location.types';
import { Inject, Injectable } from '@angular/core';
import { AWS_PROVIDER } from '@shared/aws.module';
import { LocationClient, SearchForPositionResult, SearchPlaceIndexForPositionCommand } from '@aws-sdk/client-location';
import { map } from 'rxjs/operators';
import { ENVIRONMENT } from 'flying-hellfish-common';

@Injectable()
export class AwsReverseGeocodingProvider extends ReverseGeocodingProvider {
  constructor(@Inject(AWS_PROVIDER.LOCATION) private locationClient: LocationClient, @Inject(ENVIRONMENT) private environment: any) {
    super();
  }

  positionToLocationText(longitude: number, latitude: number): Observable<ReverseGeocodingResultType | null> {
    return from(this.locationClient.send(new SearchPlaceIndexForPositionCommand({
      IndexName: this.environment.placeIndexName,
      Position: [longitude, latitude],
      MaxResults: 1
    }))).pipe(map((response) => {
      if (response.Results.length) {
        const result: SearchForPositionResult = response.Results[0];

        return {
          address: result.Place.Label,
          state: result.Place.Region,
          suburb: result.Place.Neighborhood,
          postcode: result.Place.PostalCode
        };
      }

      return null;
    }));
  }
}
