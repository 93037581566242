<div id="popup" class="ol-popup" #popup>
  <div>
    <a class="ol-popup-closer" (click)="closePopup()"><img src="assets/x-mark-16-white.png" title="Close" alt="Close" height="12" width="12"></a>
  </div>
  <div id="popup-content">
    <div class="popup">Felt Grid</div>
    <table class="table table-striped" aria-label="Felt Grid">
      <tr class="sr-only">
        <th scope="col">Property</th>
        <th scope="col">Value</th>
      </tr>
      <tbody>
      <tr>
        <td class="title">Number of responses:</td>
        <td>{{feltGridReponse?.feltResponses}}</td>
      </tr>
      <tr>
        <td class="title">Intensity:</td>
        <td>{{feltGridReponse?.intensity}}</td>
      </tr>
      <tr>
        <td class="title">Grid resolution:</td>
        <td>{{feltGridReponse?.gridResolution}} km</td>
      </tr>
      <tr>
        <td class="title">Location:</td>
        <td>{{feltGridReponse?.location}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
