<div class="heading">
  <div>
    <h4 class="tool-title">Recent Earthquakes
      <ga-help-link [linkFragment]="'recent-earthquakes'"></ga-help-link>
    </h4>
  </div>
  <div *ngIf="showQuakeDetail">
    <ga-toolbar-share-event [feature]="selectedQuakeDetail"></ga-toolbar-share-event>
  </div>
  <div *ngIf="showQuakeDetail">
    <ga-toolbar-felt-quake [feature]="selectedQuakeDetail"></ga-toolbar-felt-quake>
  </div>
</div>
<hr style="margin-top: 0">
<div *ngIf="!showQuakeDetail" [@animationCommonEnter300Leave200]>
  <div class="dropdown" dropdown>
    <button type="button" class="btn btn-ga btn-dropdown" dropdownToggle>{{ recentQuakesService.selectedPeriod.text }}
      <span class="caret"></span></button>
    <ul class="dropdown-menu btn-dropdown" *dropdownMenu>
      <li *ngFor="let period of periodList" [class.active]="period === recentQuakesService.selectedPeriod">
        <a href="#" (click)="updateSelectedPeriod(period)">{{ period.text }}</a>
      </li>
    </ul>
  </div>
</div>
<div *ngIf="!showQuakeDetail" [@animationCommonEnter300Leave200]>
  <div class="dropdown" dropdown>
    <button type="button" class="btn btn-ga btn-dropdown" dropdownToggle>{{ recentQuakesService.selectedMagnitude.text }}
      <span class="caret"></span></button>
    <ul class="dropdown-menu btn-dropdown" *dropdownMenu>
      <li *ngFor="let magnitude of magnitudeList" [class.active]="magnitude === recentQuakesService.selectedMagnitude">
        <a href="#" (click)="updateSelectedMagnitude(magnitude)">{{ magnitude.text }}</a>
      </li>
    </ul>
  </div>
</div>
<div *ngIf="!showQuakeDetail" [@animationCommonEnter300Leave200]>
  <div class="dropdown" dropdown>
    <button type="button" class="btn btn-ga btn-dropdown" dropdownToggle>{{ recentQuakesService.selectedRegion }}
      <span class="caret"></span></button>
    <ul class="dropdown-menu btn-dropdown" *dropdownMenu>
      <li *ngFor="let region of regionList" [class.active]="region === recentQuakesService.selectedRegion">
        <a href="#" (click)="updateSelectedRegion(region)">{{ region }}</a>
      </li>
    </ul>
  </div>
</div>
<hr *ngIf="!showQuakeDetail" [@animationCommonEnter300Leave200]>
<div class="error" *ngIf="recentQuakesService.errorMessage !== null">
  <fh-alert type="error" message="Error: {{recentQuakesService.errorMessage}}"></fh-alert>
</div>
<div *ngIf="!showQuakeDetail">
  <p *ngIf="deviceCommonService.isMobileOrTablet()"><em>Touch on an Earthquake to view more details</em></p>
</div>
<div class="progress" *ngIf="recentQuakesService.showProgressBar">
  <div class="progress-bar progress-bar-info progress-bar-striped active" role="progressbar"
       aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width:100%">
    Loading
  </div>
</div>
<div *ngFor="let feature of recentQuakesService.features">
  <div *ngIf="!showQuakeDetail">
    <div id="description" class="header" title="Click to display Earthquake details">
      <span (click)="showQuakeDetails(feature)">{{ feature.properties.description }}</span></div>
    <table class="table table-responsive table-striped" aria-label="Earthquake details">
      <tr class="sr-only">
        <th scope="col">Property</th>
        <th scope="col">Value</th>
        <th scope="col">Property</th>
        <th scope="col">Value</th>
      </tr>
      <tbody (click)="showQuakeDetails(feature)" title="Click to display Earthquake details">
        <tr>
          <td id="preferredMagnitudeTitle" class="title" title={{glossary.magnitude}}>Magnitude:</td>
          <td id="preferredMagnitude">{{ feature.properties.preferred_magnitude | number:'1.1-1' }}</td>
          <td id="depthTitle" class="title" title={{glossary.depth}}>Depth:</td>
          <td id="depth">{{ feature.properties.depth | number:'1.0-0' }} km</td>
        </tr>
        <tr>
          <td id="lonTitle" class="title" title={{glossary.longitude}}>Longitude:</td>
          <td id="longitude">{{ feature.properties.longitude | number:'1.2-2' }}</td>
          <td id="latTitle" class="title" title={{glossary.latitude}}>Latitude:</td>
          <td id="latitude">{{ feature.properties.latitude | number:'1.2-2' }}</td>
        </tr>
        <tr>
          <td id="originTimeTitle" class="title" title={{glossary.time}}>Date (UTC):</td>
          <td id="originTime">{{ feature.properties.origin_time | dateTimeFormat }}</td>
          <td id="reportedFeltTitle" class="title" *ngIf="feature.properties.reported_felt === 'Y'">Felt:</td>
          <td id="reportedFelt" *ngIf="feature.properties.reported_felt === 'Y'">Yes</td>
          <td *ngIf="feature.properties.reported_felt === null"></td>
          <td *ngIf="feature.properties.reported_felt === null"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="showQuakeDetail" [@animationCommonEnter300Leave200]>
  <ga-quake-details [feature]="selectedQuakeDetail"></ga-quake-details>
  <hr>
  <div class="text-center btn-back">
    <button class="btn btn-ga" (click)="returnToRecentQuakes()">
      <img src="assets/arrow-122-16.png" alt="Back" height="14" width="14"/>&nbsp;Back
    </button>
  </div>
</div>
