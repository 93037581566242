import { NgModule } from '@angular/core';
import { LocationService } from '@shared/services/location/location.service';
import { AwsGeocodingProvider } from '@shared/services/location/geocoding/aws.geocoding.provider';
import { FlyingHellfishGeocodingProvider } from '@shared/services/location/geocoding/flying.hellfish.geocoding.provider';
import { AwsReverseGeocodingProvider } from '@shared/services/location/reverse-geocoding/aws.reverse.geocoding.provider';
import { FlyingHellfishReverseGeocodingProvider } from '@shared/services/location/reverse-geocoding/flying.hellfish.reverse.geocoding.provider';

@NgModule({
  providers: [
    AwsGeocodingProvider,
    AwsReverseGeocodingProvider,
    FlyingHellfishGeocodingProvider,
    FlyingHellfishReverseGeocodingProvider,
    LocationService
  ]
})
export class LocationModule {
}
