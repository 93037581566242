<ga-header [autoSaveEnabled]="false" [helpEnabled]="false" [shareEnabled]="false"></ga-header>
<div class="toolbar">
  <div class="toolbar-container" [@animationCommonEnter500]>
    <div>
      <nav>
        <ul class="toolbar-items">
          <button type="button" class="tool-toggle-btn" [routerLink]="['']">
            <span class="hidden-sm hidden-xs">Home</span>
            <img src="assets/home-7-16.png" alt="Home" class="toolbar-icon"/>
          </button>
        </ul>
      </nav>
    </div>
  </div>
</div>
<div class="main-container" [@animationCommonEnter500]>
  <div class="main-child-container">
    <h2 class="hidden-xs">Earthquakes&#64;GA - Help</h2>
    <h3 class="visible-xs">Earthquakes&#64;GA - Help</h3>
    <hr>
    <div>
      <div id="about" class="anchor-placeholder"></div>
      <h4>About</h4>
      <p><strong>Earthquakes&#64;GA</strong> is an online map-based tool created by the Australian Government that provides the latest earthquake information available from the National Earthquake Alerts Centre (NEAC) at
        Geoscience Australia (GA).
        Available information includes the latest earthquake location, depth and magnitude updates, FeltGrid updates, and the latest ShakeMap.
      </p>
    </div>

    <div>
      <div id="toolbar-and-operations" class="anchor-placeholder"></div>
      <h4>Toolbar and Operations on the Portal</h4>
      <div class="toolbar-table">
        <table class="table table-bordered" aria-label="Toolbar and Operations on the Portal">
          <thead>
            <tr>
              <th scope="col">Tool</th>
              <th scope="col">Symbol</th>
              <th scope="col">Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr id="about-row">
              <td>About</td>
              <td class="ga-icon"><img src="{{iconCacheUrl}}/info-32-white.png" alt="About"/></td>
              <td>Allows users to show and hide information about the portal.</td>
            </tr>
            <tr id="emergency-info-row">
              <td>Emergency Info</td>
              <td class="ga-icon"><img src="{{iconCacheUrl}}/warning-3-32-white.png" alt="Emergency Info"/></td>
              <td>Allows users to show and hide emergency contact information.</td>
            </tr>
            <tr id="layers-row">
              <td>Layers</td>
              <td class="ga-icon"><img src="{{iconCacheUrl}}/layers-32-white.png" alt="Layers"/></td>
              <td>Allows users to show and hide the layers selection window.</td>
            </tr>
            <tr id="legend-row">
              <td>Legend</td>
              <td class="ga-icon"><img src="{{iconCacheUrl}}/legend-32-white.png" alt="Legend"/></td>
              <td>Allows users to show and hide the legend window.</td>
            </tr>
            <tr id="notifications-row">
              <td>Notifications</td>
              <td class="ga-icon"><img src="{{iconCacheUrl}}/rss-4-32-white.png" alt="Notifications"/></td>
              <td>Allows users to subscribe to our feeds.</td>
            </tr>
            <tr id="recent-earthquakes-row">
              <td>Recent Earthquakes</td>
              <td class="ga-icon"><img src="{{iconCacheUrl}}/lightning-bolt-32-white.png" alt="Recent Earthquakes"/></td>
              <td>Allows users to quickly view information regarding recent earthquakes.
              </td>
            </tr>
            <tr id="felt-report-row">
              <td>Felt Report</td>
              <td class="ga-icon"><img src="{{iconCacheUrl}}/report-3-32-white.png" alt="Felt Report"/></td>
              <td>Allows users to submit a report detailing the earthquake they have or haven't felt.</td>
            </tr>
            <tr id="search-row">
              <td>Search</td>
              <td class="ga-icon"><img src="{{iconCacheUrl}}/search-15-32-white.png" alt="Search"/></td>
              <td>Allows users to search through the earthquakes archive.</td>
            </tr>
            <tr id="earthquake-details-row">
              <td>Earthquake Details</td>
              <td class="ga-icon"><img src="{{iconCacheUrl}}/list-view-32-white.png" alt="Earthquake Details"/></td>
              <td>Displays the details of the selected earthquake.</td>
            </tr>
            <tr>
              <td>Share URL</td>
              <td><img src="{{iconCacheUrl}}/sharethis-5-32-black.png" alt="Share URL"/></td>
              <td>Allows users to share a URL with current map extent and selected layers.</td>
            </tr>
            <tr>
              <td>Refresh Rate</td>
              <td><img src="{{iconCacheUrl}}/timer-32-black.png" alt="Refresh Rate"/></td>
              <td>Allows users set the frequency that the server is checked for new data.</td>
            </tr>
            <tr>
              <td>Help</td>
              <td><img src="{{iconCacheUrl}}/question-mark-4-32-black.png" alt="Help"/></td>
              <td>Allow users access to the help documentation for the portal.</td>
            </tr>
            <tr>
              <td>Zoom</td>
              <td class="black-background" style="height: 100px"><img style="border-radius: 4px; height: 100px;" src="cache/icons/zoom-bar.png" alt="Zoom"/></td>
              <td>Move the white bar up to zoom in, and down to zoom out. For mobile and tablet devices users can zoom the map by pinching with two fingers on the touch screen.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <div id="layers" class="anchor-placeholder"></div>
      <h4>Layers</h4>
      <p>The Layers panel allows you to control all the layers on the map including the base map.</p>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-layers-520-425.png" alt="Layers panel help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <strong>Base Maps</strong>
          <ol>
            <li>Dropdown to select preferred based map.</li>
          </ol>
          <strong>Overlays</strong>
          <ol>
            <li value="2">
              <p>The Felt Report layer displays approximate locations of earthquake felt reports submitted by the community. For a description of a Felt Report navigate to the “Felt Report” section of this Help Page.</p>
              <p>When the Felt Report Overlay is selected, individual felt reports submitted via the online form to Geoscience Australia within the time-frame indicated by the sliding button, are displayed as single blue stars.
                Moving the sliding button left or right will respectively decrease on increase the time-frame that is viewed. Default time-frame is the last 24 hours.</p>
              <p>If two or more felt reports are coming from a similar location, they will be clustered together and displayed as a blue circle with a number representing the number of felt reports in a cluster. You can hover (or
                touch for mobile) over the cluster to see a breakdown of where the felt reports are located, or alternatively you can zoom in on the map.</p>
            </li>
            <li>This layer displays the earthquake locations for the last 10 years. This includes global events above magnitude 5 and Australian events of magnitude 2.0 or larger, or that have been reported felt by the community.
              Further information on past seismicity can be found using the search function.
            </li>
            <li>The Neotectonic layer contains information on features within Australia that are believed to relate to large earthquakes during the Neotectonic Era (i.e., the past 5–10 million years). You can click on a feature on
              the map to find out more information.
            </li>
            <li>The Earth’s crust consists of a mosaic of rigid tectonic plates that have been moving relative to one another for hundreds of millions of years. When this overlay is selected, the main plate edges are displayed.
              Once displayed, a little symbol will appear next to the tick box which, when clicked, will show the legend for different tectonic plate boundaries. The main tectonic plate boundaries accommodate the majority of
              world’s seismicity. The tectonic plate layer was sourced from USGS (usgs.gov).
            </li>
          </ol>
          <strong>National Seismic Hazard</strong>
          <ol>
            <li value="6">Geoscience Australia develops the National Seismic Hazard Assessment (NSHA) for Australia. The NSHA defines the level of earthquake ground shaking across Australia that has a likelihood of being exceeded
              in a given time period. Knowing how the ground-shaking hazard varies across Australia allows higher hazard areas to be identified for the development of mitigation strategies so communities can be more resilient to
              earthquake events.
            </li>
            <li>Toggle layer - NSHA18 Peak Ground Acceleration with a 10% chance of exceedance in 50 years</li>
            <li>Toggle layer - NSHA18 Peak Ground Acceleration with a 2% chance of exceedance in 50 years</li>
          </ol>
        </div>
      </div>
    </div>
    <div>
      <div id="notifications" class="anchor-placeholder"></div>
      <h4>Notifications</h4>
      <p>For subscribing to our Earthquake notification services, so that you can be notified when we publish or update information about the earthquakes that we monitor.</p>
      <div [innerHTML]="disclaimer"></div>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-notifications-520-295.png" alt="Notifications panel help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li><strong>RSS reader:</strong> Link to download RSS (Really Simple Syndication) XML</li>
            <li><strong>GeoRSS reader:</strong> Link to download GeoRSS XML</li>
            <li><strong>GeoJSON reader:</strong> Link to download GeoJSON</li>
            <li><strong>KML reader:</strong> Link to download Keyhole markup Language (KML) XML</li>
            <li><strong>ATOM CAP-AU reader:</strong> Link to download Atom Syndication Format XML</li>
            <li><strong>Earthquakes&#64;GA Twitter:</strong> Link to &#64;EarthquakesGA's X (Formerly Twitter) feed</li>
            <li><strong>Email:</strong> Subscribe or unsubscribe to email notifications filtered by magnitude and location relative to Australia</li>
          </ol>
        </div>
      </div>
    </div>
    <div>
      <div id="recent-earthquakes" class="anchor-placeholder"></div>
      <h4>Recent Earthquakes</h4>
      <p>Filter recent earthquakes, clicking on an earthquake will display more details.</p>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-recentEarthquakes-520-495.png" alt="Recent earthquakes panel help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li>Dropdown to select to display earthquakes recorded in recently: 1 day, 7 days or 30 days</li>
            <li>Dropdown to filter earthquakes displayed by magnitude: all, greater than 5 or greater than 2.5</li>
            <li>Dropdown to filter earthquakes to only Australia</li>
            <li>Earthquake location name</li>
            <li><strong>Magnitude: </strong>
              <p>The magnitude is a way of estimating the energy released in an earthquake from measurements of shaking made on a seismogram. Magnitude scales are logarithmic, so a magnitude 6.0
                earthquake releases about 32 times the energy of a magnitude 5.0 earthquake, which in turn releases 32 times the energy of a magnitude 4.0 earthquake. The first magnitude scale developed was the Richter scale, a
                local magnitude scale for Southern California. Commonly used magnitude scales nowadays include local magnitude scale for appropriate tectonic regions, ML, surface wave magnitude, MS, body wave magnitude, mb, and
                moment magnitude Mw, among others. These scales all use slightly different underlying principles and methods to estimate the size of an earthquake.</p>
              <p>In Australia a local Australian magnitude (MLa) is used to represent estimated magnitude of local (Australian) earthquakes, unless Mw is available.</p>
              <p>Attenuation relationships define the rate at which seismic energy decreases with increasing distance from an earthquake. The attenuation relationships for MLa cover three discrete regions as described in Gaull &
                Gregson (1991), Greenhalgh & Singh (1986), and Michael-Leiba & Malafant (1992) for Western Australia, South Australia, and East Australia respectively.</p>
            </li>
            <li><strong>Depth: </strong> {{ glossary.depth }}</li>
            <li><strong>Longitude:</strong> {{ glossary.longitude }}</li>
            <li><strong>Latitude: </strong> {{ glossary.latitude }}</li>
            <li><strong>Date:</strong> {{ glossary.originTime }}</li>
          </ol>
        </div>
      </div>
    </div>
    <div>
      <div id="felt-report" class="anchor-placeholder"></div>
      <h4>Felt Report</h4>
      <p>Felt Report is a questionnaire filled out by a member of the public following perceived ground shaking. Felt reports are used by researchers to estimate earthquake intensity. Intensity measures the effects of the
        earthquake on people, infrastructure, and environment and generally decreases with distance (compare with Magnitude, an estimate of energy release, which remains constant regardless of where it is measured).
        Intensity measurements can reveal areas of differing attenuation and can therefore help researchers in hazard studies, and to improve attenuation equations that are used for magnitude estimates.</p>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-feltReport-520-299.png" alt="Felt report panel help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li>Select “Yes” if you felt an earthquake. Information regarding earthquakes in the vicinity of your location that you didn't feel is also valuable to us.</li>
            <li>
              <p>Dropdown to select from a list of recent earthquakes. If you think that the earthquake you felt is not in the list,
                please select "I cannot find the Earthquake" and input the date and time when you felt the shaking.</p>
              <p>These steps will be followed by a series of questions to help us to better understand the earthquake that you are reporting.</p>
              <p>At the completion of the questions, you will be asked to input your location and optionally your contact details.</p>
            </li>
            <li value="3">Clear felt report and start again.</li>
            <li>Once all mandatory fields have been input you will be able to submit the report.</li>
          </ol>
        </div>
      </div>
    </div>
    <div>
      <div id="search" class="anchor-placeholder"></div>
      <h4>Search</h4>
      <p>Used to search the earthquakes archive based on relevant criteria.</p>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-search-520-439.png" alt="Search panel help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li><strong>Location:</strong> Filter search results to show earthquakes within Australia (Australia) only or within Australia and the rest of the world (World).</li>
            <li><strong>Magnitude range:</strong> Limit search results to within a given magnitude range. Magnitude scales go from 0 to 10, so if unchanged the search results will include eligible earthquakes of all magnitudes.
              The lower and upper limits are extended by 0.05. For example, a search of earthquakes within magnitude range 3 to 5 will yield results between 2.95 and 5.05.
            </li>
            <li><strong>Date range:</strong> Filter earthquakes that occurred within an absolute UTC date range. The default range is past 12 months.</li>
            <li><strong>Recent Earthquakes:</strong> Select from a list of recent earthquakes to use as the centre of a search radius. If selected, search results will only include eligible (within date and magnitude range)
              earthquakes within the prescribed radius of the chosen earthquake.
              <ol type="i">
                <li><strong>Radius from Recent Earthquake:</strong> Select a search radius 5 - 200 km (default: 100 km).</li>
              </ol>
            </li>
            <li><strong>State:</strong> Filter search results by Australian state/territory.</li>
            <li><strong>Results Per Page:</strong> Search results to display per page.</li>
            <li><strong>Draw:</strong> Allows drawing a shape (see Drawing method) on the map which defines the boundary of the geographical area for an earthquake search. Search results will be limited to within the shape only.
              <ol type="i">
                <li><strong>Clear:</strong> Delete drawing.</li>
              </ol>
            </li>
            <li><strong>Drawing method:</strong> Select to use box, circle or polygon as a search boundary. Once the “Draw” button is clicked, it will change to display “Clear”. Clicking it will clear any shapes drawn and exit the
              drawing mode of the search.
              <p>When in drawing mode: for the box, click once to start drawing, and once again to finish. The drawing starts from the upper left corner of the box and finishes in the bottom right corner. For the circle, click to
                start drawing and drag the mouse until the circle is of desired size; click again to finish drawing. For the polygon, click once to start drawing and click for each vertex of the polygon. When the first and the
                last vertices are connected, the drawing automatically ends.</p>
            </li>
            <li><strong>Clear:</strong> Reset all search criteria to default values.</li>
            <li><strong>Search:</strong> Begin search based on entered criteria.</li>
          </ol>
        </div>
      </div>
    </div>
    <div>
      <div id="earthquake-details" class="anchor-placeholder"></div>
      <h4>Earthquake details</h4>
      <p></p>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-earthquakeDetails-389-702.png" alt="Earthquake details panel help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li><strong>Share icon:</strong> Click to copy link to this earthquake and share with others.</li>
            <li>Location of earthquake.</li>
            <li><strong>Summary:</strong> Details of earthquake.</li>
            <li><strong>Magnitude:</strong> Click to display for magnitude details.</li>
            <li><strong>Quality Indicators:</strong> Click to display for quality indicators.</li>
            <li><strong>Solution Status</strong> Click to expand for solution status details.</li>
            <li><strong>Stations:</strong> Click to view a list of associated stations.</li>
            <li><strong>Focal Mechanism:</strong> Click to display focal mechanism data.</li>
            <li><strong>Seismograms:</strong>Click to display seismograms.</li>
            <li><strong>Felt Report Chart:</strong> Click to display number of felt reports received over time.</li>
            <li><strong>ShakeMake, FeltGrid and other downloads:</strong> Click to access documentation and available downloads related to this earthquake (e.g. information about FeltGrid and Shakemap).</li>
            <li><strong>Earthquake Map Location:</strong> Zoomed in earthquake map location.</li>
          </ol>
          <p> When an earthquake is within Australia and felt reports are available, these buttons will display.</p>
          <ol>
            <li value="13">Click to display ShakeMap layer for the selected earthquake to the map.</li>
            <li>Click to add Felt Grid layer to the map.</li>
            <li>Click to display Felt Report locations layer for the selected earthquake to the map.</li>
          </ol>
        </div>
      </div>
      <div id="summary" class="anchor-placeholder"></div>
      <h5>Summary</h5>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-eq-details-summary-540-134.png" alt="Summary help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li><strong>Origin:</strong> {{ glossary.originTime }}</li>
            <li><strong>Epicentral Time:</strong> {{ glossary.epicentralTime }}</li>
            <li><strong>Longitude:</strong> {{ glossary.longitude }}</li>
            <li><strong>Latitude:</strong> {{ glossary.latitude }}</li>
            <li><strong>Magnitude: </strong>
              <p>The magnitude is a way of estimating the energy released in an earthquake from measurements of shaking made on a seismogram. Magnitude scales are logarithmic, so a magnitude 6.0
                earthquake releases about 32 times the energy of a magnitude 5.0 earthquake, which in turn releases 32 times the energy of a magnitude 4.0 earthquake. The first magnitude scale developed was the Richter scale, a
                local magnitude scale for Southern California. Commonly used magnitude scales nowadays include local magnitude scale for appropriate tectonic regions, ML, surface wave magnitude, MS, body wave magnitude, mb, and
                moment magnitude Mw, among others. These scales all use slightly different underlying principles and methods to estimate the size of an earthquake.</p>
              <p>In Australia a local Australian magnitude (MLa) is used to represent estimated magnitude of local (Australian) earthquakes, unless Mw is available. Throughout the website MLa is simply abbreviated to ML .</p>
              <p>Attenuation relationships define the rate at which seismic energy decreases with increasing distance from an earthquake. The attenuation relationships for MLa cover three discrete regions as described in Gaull &
                Gregson (1991), Greenhalgh & Singh (1986), and Michael-Leiba & Malafant (1992) for Western Australia, South Australia, and East Australia respectively.</p></li>
            <li><strong>Depth:</strong> {{ glossary.depth }}</li>
            <li><strong>Event Id:</strong> {{ glossary.eventId }}</li>
            <li><strong>Felt Reports:</strong> Number of felt reports received for this earthquake.</li>
          </ol>
        </div>
      </div>
      <div id="magnitude" class="anchor-placeholder"></div>
      <h5>Magnitude</h5>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-eq-details-magnitude-489-166.png" alt="Magnitude help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li>This tab displays all calculated magnitude types for the selected earthquake. The magnitude type that is reported as preferred for the earthquake is specifically indicated. Different magnitude types use different
              physical and mathematical methodologies to estimate the energy release in an earthquake. The preferred magnitude of an earthquake is selected based on available magnitude estimates for the earthquake, global best
              practice, and the National earthquake Alert Centre’s Standard Operating Procedures.
              <p>Possible magnitude types:</p>
              <ol type="i">
                <li><strong>Mw:</strong> {{ glossary.mw }}</li>
                <li><strong>Mwp:</strong> {{ glossary.mwp }}</li>
                <li><strong>Mw(Mwp):</strong> {{ glossary.mwmwp }}</li>
                <li><strong>Mww:</strong> {{ glossary.mww }}</li>
                <li><strong>MLa:</strong> {{ glossary.mla }}</li>
                <li><strong>mb:</strong> {{ glossary.mb }}</li>
                <li><strong>ms:</strong> {{ glossary.ms }}</li>
                <li><strong>mb_IDC(ga):</strong> {{ glossary.mb_idc }}</li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
      <div id="quality-indicators" class="anchor-placeholder"></div>
      <h5>Quality Indicators</h5>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-eq-details-qualityIndicators-462-132.png" alt="Quality indicators help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li><strong>Time defining stations:</strong> {{ glossary.stations }}</li>
            <li><strong>Number of phases used:</strong> {{ glossary.phases }}</li>
            <li><strong>Max azimuthal gap:</strong> {{ glossary.azimuth }}</li>
            <li><strong>RMS residual:</strong> {{ glossary.rms }}</li>
          </ol>
        </div>
      </div>
      <div id="solution-status" class="anchor-placeholder"></div>
      <h5>Solution Status</h5>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-eq-details-solutionStatus-513-70.png" alt="Solutions status help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li><strong>Updated:</strong> {{ glossary.solutionUpdated }}</li>
            <li><strong>Status:</strong> {{ glossary.solutionStatus }}</li>
            <li><strong>Source:</strong> {{ glossary.solutionSource }}</li>
            <li><strong>Published Version:</strong> {{ glossary.solutionPublishVersion }}</li>
          </ol>
        </div>
      </div>
      <div id="stations" class="anchor-placeholder"></div>
      <h5>Stations</h5>
      <p>Seismic monitoring station details.</p>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-eq-details-stations-492-144.png" alt="Stations help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li><strong>Station name:</strong> {{ glossary.stationName }}</li>
            <li><strong>Station Code:</strong> {{ glossary.stationCode }}</li>
            <li><strong>Arrival Time:</strong> {{ glossary.stationArrivalTime }}</li>
            <li><strong>Phase:</strong> {{ glossary.stationPhase }}</li>
            <li><strong>Use of Phase:</strong> {{ glossary.stationUseOfPhase }}</li>
            <li><strong>Azimuth:</strong> {{ glossary.stationAzimuth }}</li>
            <li><strong>Distance:</strong> {{ glossary.stationDistance }}</li>
          </ol>
        </div>
      </div>
      <div id="focal-mechanism" class="anchor-placeholder"></div>
      <h5>Focal Mechanism</h5>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-eq-details-focalMechanism-480-683.png" alt="Focal mechanism help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li>
              <p><strong>Focal Mechanism:</strong> The focal mechanism, also referred to as a “fault-plane solution” is a mathematical description of the slip along a fault plane at the earthquake source, i.e. it describes the
                deformation occurring at the earthquake
                origin. This mathematical description includes orientation of the fault plane along which the slip occurred, and the slip vector (i.e., the direction and length of the slip).</p>
              <p>When mathematically inferring a focal mechanism estimate, seismologists solve for the location of the centroid. This is the location of the centre of the energy release of the earthquake.
                For large earthquakes (larger than magnitude 6), the location of the centroid (lat, lon, depth, time - found under the “Focal Mechanism” tab) can be significantly different from the location of the hypocentre (aka
                the
                earthquake origin) which is the estimated location and time of the start of the earthquake rupture.
                Earthquake hypocentre details can be found under the “Summary” tab.</p></li>
            <li><strong>Magnitude:</strong> For the definition of magnitude please refer to other parts of this Help Page (e.g. “<a href="/help#recent-earthquakes">Recent Earthquakes</a>” or “<a href="/help#summary">Earthquake
              Summary</a>” ).
              This particular magnitude value refers to the estimate of moment magnitude. The moment magnitude is obtained through the same mathematical process that estimates the focal mechanism.
            </li>
            <li><strong>Type:</strong> This is magnitude type for the value shown under 2 (Magnitude). The Mw type is the estimate of moment magnitude using a combination of observed body and surface waves in a mathematical
              inversion process. The Mww type is the estimate of moment magnitude derived from the inversion process of the W-phase – a very long period waveform comprised of surface reflections, arriving between the P and S
              waves.
            </li>
            <li><strong>Longitude/Latitude/Depth:</strong> For full definitions of these terms please refer to other parts of this Help Page (e.g. “<a href="/help#recent-earthquakes">Recent Earthquakes</a>” or
              “<a href="/help#summary">Earthquake Summary</a>” ). These particular estimates are for
              longitude, latitude and depth of the centroid location.
              Note that this is different from the longitude, latitude, and depth of the hypocentre, which is displayed under “Summary”.
            </li>
            <li><strong>Azimuthal Gap:</strong> In seismology, azimuthal gap refers to the angular distance between the two closest seismic stations that can detect an earthquake. It is defined as the minimum angle between the two
              lines connecting the earthquake’s epicenter to the two closest seismic stations. The maximum azimuthal gap is not a fixed value. It depends on the seismic network’s geometry and the distance between the earthquake’s
              epicenter and the seismic stations. The azimuthal gap is used to estimate the accuracy of the earthquake’s location and magnitude. A smaller azimuthal gap indicates a more accurate location and magnitude estimation.
              This is the azimuthal gap for centroid location estimate.
            </li>
            <li><strong>Misfit:</strong> A value between 0 and 1 indicating how well the observed data fit the modelled data. 0 indicates perfect fit, 1 indicates that the observed and modelled data are exactly opposite.</li>
            <li><strong>Method:</strong> Indicates the mathematical method used to estimate the centroid location, focal mechanism, and moment magnitude. It reads “wphase” if the estimates were made based on long-period W-phase,
              and is unspecified otherwise.
            </li>
            <li><strong>Node Plane 1 and 2:</strong> One plane is the fault plane – representing the fault surface – and the other is called the auxiliary plane and has no structural significance.
              Determining which is the fault plane requires an understanding of the geological setting in which the earthquake occurred.
            </li>
            <li><strong>Dip:</strong> Measured positive between the Earth surface and the fault plane, in the range of 0-90 degrees.</li>
            <li><strong>Rake:</strong> Aka slip angle expressed in the range -180 – 180 degrees, where negative angles are predominantly downward movement along the fault plane, and positive angles are predominantly upwards.</li>
            <li><strong>Strike:</strong> Orientation of the fault line (viewed from above), measured clockwise from north, in the range 0-360 degrees</li>
            <li><strong>Moment Tensor:</strong> Graphical representation of the earthquake’s focal mechanism.</li>
          </ol>
        </div>
      </div>
      <div id="seismograms" class="anchor-placeholder"></div>
      <h5>Seismograms</h5>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-eq-details-seismograms-464-170.png" alt="Seismograms help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li>
              The tag identifies the seismic instrument that recorded the signal.
              Individual identifiers are separated by a full stop symbol, from left to right: network code (e.g. AU), instrument code (same as “Station Code”
              above, e.g. TOO), location code (e.g. 10 or 00), instrument type and component code (e.g. Broadband, High gain instrument, Vertical component, abbreviated to BHZ).
              For more information on channel names of the instrument see.
              <a href="https://ds.iris.edu/ds/nodes/dmc/data/formats/seed-channel-naming/" target="_blank" rel="noopener">SEED Channel Naming</a> AND
              <a href="https://ds.iris.edu/ds/newsletter/vol1/no1/1/specification-of-seismograms-the-location-identifier/" target="_blank" rel="noopener">Specification of Seismograms: The Location Identifier</a>
            </li>
            <li>Red dot is a theoretical arrival of a P wave to the instrument identified under 1) above, assuming the location of the earthquake estimated through mathematical inversion process. Red line is an observed P arrival
              as recorded on the instrument component identified under 1) above.
            </li>
            <li>Blue dot is the theoretical arrival of an S wave to the instrument identified under 1) above.</li>
            <li>The seismic signal recorded on the instrument (and component of the instrument) identified under 1) above), filtered with a broad filter (0.8 – 8 Hz).</li>
            <li>The numbers on either side of the forward slash symbol are: (on the left-hand side) average amplitude value of the displayed filtered signal (4) and, (on the right-hand side) the maximum amplitude value of the
              displayed filtered signal (4); both values are measured in counts after filtering.
            </li>
            <li>The distance (in kilometres) between the instrument identified in 1) above and the estimated location of the earthquake projected to the Earth’s surface (epicentre), also referred to as epicentral distance in
              seismology.
            </li>
          </ol>
        </div>
      </div>
      <div id="felt-report-chart" class="anchor-placeholder"></div>
      <h5>Felt Report Chart</h5>
      <p>The felt chart shows both the: cumulative number of felt reports from an earthquake (updated in 10-minute intervals) over a 24-hour period following the earthquake’s origin (red line), and; number of felt reports received
        for each 10-minute interval (or the rate of felt reports received) over the same 24-hour period (blue line).</p>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-eq-details-feltReportChart-462-445.png" alt="Felt report chart help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
        </div>
      </div>
      <h5>ShakeMap, FeltGrid and other downloads</h5>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-eq-details-downloads-524-349.png" alt="Downloads help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li>Earthquakes details in SiesComp XML format</li>
            <li>ShakeMap Disclaimer and Information document</li>
            <li>National Earthquake Alert Centre ShakeMap Report overview document</li>
            <li>National Earthquake Alert Centre FeltGrid Report overview document</li>
            <li>National Earthquake Alert Centre Modified Mercalli Intensity (MMI) scale document</li>
            <li>Intensity ShakeMap map - available in PDF or JPG</li>
            <li>Peak Ground Velocity Contours map - available in PDF or JPG</li>
            <li>Peak Ground Acceleration Contours map - available in PDF or JPG</li>
            <li>Vectors - available in ShapeFile, GeoJSON or KML</li>
            <li>Raster - available in ESRI Grid, KMZ also a colour legend for the rasters</li>
            <li>Earthquake metadata in JSON format</li>
          </ol>
        </div>
      </div>
      <div id="felt-report-button" class="anchor-placeholder"></div>
      <h5>Felt Report Button</h5>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-earthquakeDetails-feltReportButton-520-239.png" alt="Felt report button help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li>Distribution of felt reports.</li>
          </ol>
        </div>
      </div>
      <div id="shake-map-button" class="anchor-placeholder"></div>
      <h5>ShakeMap Button</h5>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-earthquakeDetails-shakemapButton-520-382.png" alt="Shake map button help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li>ShakeMap showing earthquake shaking intensity.</li>
            <li>Modified Mercalli Intensity Colour Scale.</li>
          </ol>
        </div>
      </div>
      <div id="felt-grid-button" class="anchor-placeholder"></div>
      <h5>FeltGrid Button</h5>
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 help-diagrams"><img class="img-responsive" src="cache/help-diagrams/help-diagrams-earthquakeDetails-feltGridButton-520-367.png" alt="Felt grid button help diagram"></div>
        <div class="col-sm-6 col-md-7 col-lg-8 help-diagrams">
          <ol>
            <li>FeltGrid map showing intensity of felt reports.</li>
            <li>Modified Mercalli Intensity Colour Scale.</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<fh-footer [feedbackEmail]="feedbackEmail"></fh-footer>
