<h4 *ngIf="!feltReport.submitted || showError" class="tool-title">Felt an Earthquake?
  <ga-help-link [linkFragment]="'felt-report'"></ga-help-link>
</h4>
<hr>
<p [hidden]="feltReport.submitted || showProgressBar || showError">If you felt an earthquake, or know of an earthquake that
  occurred recently, please fill in this
  form. Even if you did
  not feel the earthquake yourself, please fill in the form, as this information will assist us.
  <a href="http://www.ga.gov.au/privacy" target="_blank" rel="noopener">Privacy Policy</a></p>

<form (ngSubmit)="onSubmit()" #feltReportForm="ngForm" [hidden]="feltReport.submitted || showProgressBar || showError" autocomplete="off">
  <div class="felt-container">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title">Felt Report (Mandatory *)</h3>
      </div>
      <div>
        <div class="panel-body">
          <div class="felt" style="margin-bottom: 6px">
            <div>
              <label>Did you feel an earthquake? *</label>
            </div>
            <div>
              <label class="container">Yes<input type="radio" name="felt" value="Yes" [(ngModel)]="feltReport.felt" (click)="scroll()" required><span class="checkmark"></span></label>
            </div>
            <div>
              <label class="container">No<input type="radio" name="felt" value="No" [(ngModel)]="feltReport.felt" (click)="scroll()"><span class="checkmark"></span></label>
            </div>
          </div>

          <div class="felt" *ngIf="feltReport.felt === 'Yes' || feltReport.felt === 'No'">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <label>Which Earthquake
                  <span *ngIf="feltReport.felt === 'Yes'">did</span><span *ngIf="feltReport.felt === 'No'">didn't</span>
                  you feel?* (Epicentral time)</label>
              </div>
            </div>
            <div>
              <div class="dropdown" dropdown>
                <button type="button" class="btn btn-ga btn-dropdown" dropdownToggle>{{ selectedRecentQuake.text }}
                  <span class="caret"></span></button>
                <ul class="dropdown-menu btn-dropdown" *dropdownMenu>
                  <li *ngFor="let recentQuake of recentQuakesFormatted" [class.active]="recentQuake === selectedRecentQuake">
                    <a href="#" (click)="updateSelectedRecentQuake(recentQuake)">{{ recentQuake.text }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="felt" *ngIf="(selectedRecentQuake.text === 'I cannot find the Earthquake' && feltReport.felt === 'Yes')" [@animationCommonEnter300]>
            <label>Date and Time you felt the earthquake: *</label>
            <input type="text" class="form-control" name="dateTime" placeholder="01/01/2000 15:00" (ngModelChange)="dateInputChange($event)"
                   [(ngModel)]="feltDate" [ngModelOptions]="{updateOn: 'change'}" required
                   pattern="^(0[1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d [012]{0,1}[0-9]:[0-6][0-9]$">
            <div style="font-style: italic; margin-top: 5px;">Date Format dd/mm/yyyy hh:mm (Epicentral time)</div>
            <div *ngIf="isDateInFuture" style="padding-top: 15px">
              <fh-alert [message]="'Date cannot be in the future'" [type]="'error'"></fh-alert>
            </div>
            <div *ngIf="isDateOlderThanOneDay" style="padding-top: 15px">
              <fh-alert [message]="'Warning - Date is older than one day'" [type]="'info'"></fh-alert>
            </div>
          </div>

          <div *ngIf="feltQuakeLogicIsValid">
            <div class="felt" *ngIf="feltQuakeLogicIsValid" [@animationCommonEnter300]>
              <div>
                <label>What was your situation during the earthquake? *</label>
              </div>
              <div *ngFor="let option of situationList">
                <label class="container">{{ option }}
                  <input type="radio" name="situation" value={{option}} [(ngModel)]="feltReport.situation" (click)="option !== 'Other' ? [scroll(),feltReport.situationOther=''] : ''" required><span class="checkmark"></span></label>
              </div>
              <div>
                <input type="text" maxlength="512" name="situationOther" class="form-control" [disabled]="feltReport.situation!=='Other' ? true : null"
                       [(ngModel)]="feltReport.situationOther" (ngModelChange)="feltReport.situationOther=trim($event)" [ngModelOptions]="{updateOn: 'change'}" required="feltReport.situation == 'Other'" (change)="feltReport.situationOther ? scroll() : ''">
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.sleep || (feltReport.situation && feltReport.situation !== 'Other') || (feltReport.situation && feltReport.situation === 'Other' && feltReport.situationOther)" [@animationCommonEnter300]>
              <div>
                <label>Were you asleep? *</label>
              </div>
              <div *ngFor="let option of sleepList">
                <label class="container">{{ option }}
                  <input type="radio" name="sleep" value={{option}} [(ngModel)]="feltReport.sleep" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.sleep" [@animationCommonEnter300]>
              <div>
                <label>Did others nearby feel it? *</label>
              </div>
              <div *ngFor="let option of othersFeltList">
                <label class="container">{{ option }}
                  <input type="radio" name="othersFelt" value={{option}} [(ngModel)]="feltReport.othersFelt" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.othersFelt" #target [@animationCommonEnter300]>
              <div>
                <label>How would you describe the shaking? *</label>
              </div>
              <div *ngFor="let option of motionList">
                <label class="container">{{ option }}
                  <input type="radio" name="motion" value={{option}} [(ngModel)]="feltReport.motion" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.motion" [@animationCommonEnter300]>
              <div>
                <label>How did you react? *</label>
              </div>
              <div *ngFor="let option of reactionList">
                <label class="container">{{ option }}
                  <input type="radio" name="reaction" value={{option}} [(ngModel)]="feltReport.reaction" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.reaction" [@animationCommonEnter300]>
              <div>
                <label>How did you respond? *</label>
              </div>
              <div *ngFor="let option of responseList">
                <label class="container">{{ option }}
                  <input type="radio" name="response" value={{option}} [(ngModel)]="feltReport.response" (click)="option !== 'Other' ? [scroll(),feltReport.responseOther=''] : ''" required><span class="checkmark"></span></label>
              </div>
              <div>
                <input id="responseOther" type="text" maxlength="512" name="responseOther" class="form-control" [disabled]="feltReport.response!=='Other' ? true : null"
                       [(ngModel)]="feltReport.responseOther" (ngModelChange)="feltReport.responseOther=trim($event)" [ngModelOptions]="{updateOn: 'change'}" required="feltReport.response == 'Other'" (change)="feltReport.responseOther ? scroll() : ''">
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.stand || (feltReport.response && feltReport.response !== 'Other') || (feltReport.response && feltReport.response === 'Other' && feltReport.responseOther)" [@animationCommonEnter300]>
              <div>
                <label>Was it difficult to stand and/or walk? *</label>
              </div>
              <div *ngFor="let option of defaultList">
                <label class="container">{{ option }}
                  <input type="radio" name="stand" value={{option}} [(ngModel)]="feltReport.stand" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.stand" [@animationCommonEnter300]>
              <div>
                <label>Did you notice any swinging of doors or other free-hanging objects? *</label>
              </div>
              <div *ngFor="let option of swingList">
                <label class="container">{{ option }}
                  <input type="radio" name="swing" value={{option}} [(ngModel)]="feltReport.swing" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.swing" [@animationCommonEnter300]>
              <div>
                <label>Did you hear creaking or other noises? *</label>
              </div>
              <div *ngFor="let option of noiseList">
                <label class="container">{{ option }}
                  <input type="radio" name="noise" value={{option}} [(ngModel)]="feltReport.noise" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.noise" [@animationCommonEnter300]>
              <div>
                <label>Did objects rattle, topple over, or fall off shelves? *</label>
              </div>
              <div *ngFor="let option of shelfList">
                <label class="container">{{ option }}
                  <input type="radio" name="shelf" value={{option}} [(ngModel)]="feltReport.shelf" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.shelf" [@animationCommonEnter300]>
              <div>
                <label>Did pictures on walls move or get knocked askew? *</label>
              </div>
              <div *ngFor="let option of pictureList">
                <label class="container">{{ option }}
                  <input type="radio" name="picture" value={{option}} [(ngModel)]="feltReport.picture" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.picture" [@animationCommonEnter300]>
              <div>
                <label>Did any furniture or appliances slide, topple over, or become displaced? *</label>
              </div>
              <div *ngFor="let option of defaultList">
                <label class="container">{{ option }}
                  <input type="radio" name="furniture" value={{option}} [(ngModel)]="feltReport.furniture" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.furniture" [@animationCommonEnter300]>
              <div>
                <label>Was a heavy appliance (e.g. refrigerator or range) affected? *</label>
              </div>
              <div *ngFor="let option of applianceList">
                <label class="container">{{ option }}
                  <input type="radio" name="appliance" value={{option}} [(ngModel)]="feltReport.appliance" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.appliance" [@animationCommonEnter300]>
              <div>
                <label>Were free-standing walls or fences damaged? *</label>
              </div>
              <div *ngFor="let option of wallsFencesList">
                <label class="container">{{ option }}
                  <input type="radio" name="wallsFences" value={{option}} [(ngModel)]="feltReport.wallsFences" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <div class="felt" *ngIf="feltReport.wallsFences" [@animationCommonEnter300]>
              <div>
                <label>Was there any damage to the building? *</label>
              </div>
              <div *ngFor="let option of damageList">
                <label class="container">{{ option }}
                  <input type="radio" class="option-input radio" name="damage" value={{option}} [(ngModel)]="feltReport.damage" (click)="scroll()" required><span class="checkmark"></span></label>
              </div>
            </div>

            <ga-felt-report-location *ngIf="feltReport.damage" [submitBehaviorSubject]="setSubmitEmitter" (addressLocationEmitter)="submitFeltReport($event)" (showErrorEmitter)="setShowError()"></ga-felt-report-location>

            <div class="felt" *ngIf="feltReport.damage">
              <div>
                <label for="comments">Additional Comments:</label>
              </div>
              <div>
                <textarea id="comments" maxlength="2048" name="otherComments" [(ngModel)]="feltReport.comments"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div #feltPanel></div>
    <div class="panel panel-default" *ngIf="feltReport.damage && feltQuakeLogicIsValid">
      <div class="panel-heading">
        <h3 class="panel-title">Contact Information (Optional)</h3>
      </div>
      <div>
        <div class="panel-body">
          <div class="contact">
            <div>
              <label for="name">Name</label>
            </div>
            <div>
              <input id="name" type="text" class="form-control" maxlength="512" name="name" [(ngModel)]="feltReport.name">
            </div>
            <div>
              <label for="phone">Phone</label>
            </div>
            <div>
              <input id="phone" type="tel" class="form-control" maxlength="50" name="phone" [(ngModel)]="feltReport.phone" pattern="[0-9]*">
            </div>
            <div>
              <label for="email">Email</label>
            </div>
            <div>
              <input type="email" class="form-control contact-last" id="email" maxlength="200" name="email" [(ngModel)]="feltReport.email" (ngModelChange)="validateEmail()">
              <div *ngIf="invalidEmail">
                <fh-alert [message]="'Enter a valid email'" [type]="'info'"></fh-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-default" *ngIf="feltReport.damage && feltQuakeLogicIsValid">
      <div class="panel-heading">
        <h3 class="panel-title">Privacy Statement</h3>
      </div>
      <div>
        <div class="panel-body">
          <div class="felt" style="margin-bottom: 6px">
            <div>
              <label>Do you consent to your personal information being: *</label>
            </div>
          </div>
          <div>
            <ul>
              <li>1. shared with researchers for scientific analysis;</li>
              <li>2. shared with emergency service organisations to plan for and respond to emergent situations; and</li>
              <li>3. stored on computer equipment that may be located outside of Australia.</li>
            </ul>
            <p>For further information please see Geoscience Australia’s <a href="http://www.ga.gov.au/privacy" target="_blank" rel="noopener">privacy policy</a>.</p>
          </div>
          <div>
            <label class="container">Yes<input type="radio" name="privacyConsent" value="Yes" [(ngModel)]="feltReport.privacyConsent" required><span class="checkmark"></span></label>
          </div>
          <div>
            <label class="container">No<input type="radio" name="privacyConsent" value="No" [(ngModel)]="feltReport.privacyConsent"><span class="checkmark"></span></label>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center submit-btn">
      <button class="btn btn-ga" type="button" (click)="clear()">Clear</button>
      <button id="feltButton" type="submit" class="btn btn-ga" [disabled]="!feltReportForm.form.valid || !feltQuakeLogicIsValid || invalidEmail">
        Submit Report
      </button>
    </div>
  </div>
</form>

<div class="progress" [hidden]="!showProgressBar">
  <div class="progress-bar progress-bar-info progress-bar-striped active" role="progressbar"
       aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width:100%">
    Submitting Felt Report
  </div>
</div>

<div *ngIf="feltReport.submitted && !showError" [@animationCommonEnter500]>
  <h4>Felt Cluster Map</h4>
  <p *ngIf="!deviceCommonService.isMobileOrTablet()">
    A cluster represents a group of felt reports. You can hover over the cluster to see a breakdown of where the felt
    reports are located, or alternatively you can zoom in on the map.
  </p>
  <p *ngIf="deviceCommonService.isMobileOrTablet()">
    A cluster represents a group of felt reports. You can touch over the cluster to see a breakdown of where the felt
    reports are located, or alternatively you can zoom in on the map.
  </p>
  <p>
    If an earthquake was located by Geoscience Australia, the earthquake information will be listed in Recent
    Earthquakes within the next 30 minutes.
  </p>
  <p>
    Displaying the last 24 hours of felt reports. To alter this range please select Layers.
  </p>
  <p>
    Geoscience Australia thanks you for submitting your report.
  </p>
  <div>
    <ga-felt-report-map-component [feltReport]="feltReport"></ga-felt-report-map-component>
  </div>
</div>

<div *ngIf="showError">
  <fh-alert [message]="errorMessage" type="error"></fh-alert>
</div>
