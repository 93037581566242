import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpComponent } from './help.component';
import { FlyingHellfishCommonModule } from 'flying-hellfish-common';
import { HeaderModule } from '../header/header.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HelpComponent
  ],
  imports: [
    CommonModule,
    FlyingHellfishCommonModule,
    HeaderModule,
    RouterModule
  ],
  exports: [
    HelpComponent
  ]
})
export class HelpModule {
}
