import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { NotificationSubscription } from './notification.subscription';
import { ENVIRONMENT } from 'flying-hellfish-common';

@Injectable()
export class NotificationsService {
  emailSubscriptionSubject: Subject<void> = new Subject();
  private apiGatewayKey: string = this.environment.apiGateway.key;
  private arcGisTokenUrl: string = this.environment.feltReport.arcGisTokenUrl;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: any) {
  }

  // Calls the AWS API to insert or delete a subscription from the database
  submitSubscription(subscription: NotificationSubscription): Observable<any> {
    const body: string = JSON.stringify({
      earthquakesSubscription: subscription
    });

    const httpOptions: { headers: HttpHeaders } = {
      headers: new HttpHeaders({
        'Content-Type': 'application',
        'x-api-key': this.apiGatewayKey
      })
    };

    return this.http.post(this.environment.subscription.api, body, httpOptions);
  }
}
