<div class="cbp-spmenu cbp-spmenu-vertical cbp-spmenu-right" style="background-color: white;">
  <div>
    <a href="javascript:;" class="closePanel pull-left" title="Close panel"
       (click)="closePanelClick()"><img src="assets/arrow-24-16.png" alt="Close"></a>
  </div>
  <div #container [hidden]="activeToolId !== 'search'" class="right-side-menu-container">
    <ga-toolbar-search></ga-toolbar-search>
  </div>
  <div #container [hidden]="activeToolId !== 'recentQuakes'" class="right-side-menu-container">
    <ga-toolbar-recent-quakes></ga-toolbar-recent-quakes>
  </div>
  <div *ngIf="activeToolId === 'identify'" class="right-side-menu-container">
    <ga-toolbar-identify></ga-toolbar-identify>
  </div>
  <div #container [hidden]="activeToolId !== 'feltReport'" class="right-side-menu-container">
    <ga-toolbar-felt-report></ga-toolbar-felt-report>
  </div>
</div>
