import { Component, Input } from '@angular/core';
import { FeatureType } from '../../types/feature.type';
import { SidePanelCommonService } from 'flying-hellfish-common';
import { MapService } from '../../map/map.service';

@Component({
  selector: 'ga-toolbar-felt-quake',
  templateUrl: 'felt.quake.component.html',
  styleUrls: ['felt.quake.component.css'],
  preserveWhitespaces: true
})
export class FeltQuakeComponent {
  @Input() feature: FeatureType;

  constructor(private sidePanelCommonService: SidePanelCommonService, private mapService: MapService) {
  }

  // Open the felt report panel and emit the feature
  launchFeltReport(): void {
    this.sidePanelCommonService.mapToolClicked({
      toolId: 'feltReport',
      toolSide: 'right',
      keepOpen: true
    });

    this.mapService.featureEmitter.next(this.feature);
  }
}
