import { AfterViewInit, Component, HostListener, Inject, Input, NgZone, OnChanges, QueryList, ViewChildren } from '@angular/core';
import { MapService } from '../../map/map.service';
import { FeltReport } from './felt.report';
import { ENVIRONMENT, MiniMapCommonComponent } from 'flying-hellfish-common';
import VectorLayer from 'ol/layer/Vector';
import { Circle, Fill, RegularShape, Stroke, Style, Text } from 'ol/style';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { Select } from 'ol/interaction';
import Feature, { FeatureLike } from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';

@Component({
  selector: 'ga-felt-report-map-component',
  templateUrl: 'felt.report.map.component.html',
  styleUrls: ['felt.report.map.component.css'],
  preserveWhitespaces: true
})
export class FeltReportMapComponent implements AfterViewInit, OnChanges {
  static feltReportVectorLayer: VectorLayer<VectorSource>;
  static feltReportFeatureCount: number;

  @ViewChildren(MiniMapCommonComponent) miniMapComponents: QueryList<MiniMapCommonComponent>;

  private earthQuakeFeltReportsWFSUrl: string = this.environment.serviceUrls.earthQuakeFeltReportsWFS;
  private hoursSinceSubmitted: number = this.environment.feltReport.hoursSinceSubmitted;

  datumProjection: string = this.environment.datumProjection;
  displayProjection: string = this.environment.displayProjection;
  zoomLevel: number = this.environment.feltReport.miniMapZoomLevel;

  @Input() feltReport: FeltReport;

  // Sets up styles for the layer
  static getFeltReportStyle(feature: FeatureLike): Style {
    FeltReportMapComponent.calculateClusterInfo();

    let style: Style;
    const size: any = feature.get('features').length;

    if (size > 1) {
      style = new Style({
        image: new Circle({
          radius: feature.get('radius'),
          fill: new Fill({
            color: [0, 255, 255, Math.min(0.8, 0.4 + (size / FeltReportMapComponent.feltReportFeatureCount))]
          })
        }),
        text: new Text({
          text: size.toString(),
          fill: new Fill({
            color: '#fff'
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.6)',
            width: 3
          })
        })
      });
    } else {
      const originalFeature: any = feature.get('features')[0];
      style = FeltReportMapComponent.getEarthquakeStyle(originalFeature);
    }

    return style;
  }

  // Calculates the radius for cluster
  static calculateClusterInfo(): void {
    FeltReportMapComponent.feltReportFeatureCount = 0;
    const features: Feature[] = FeltReportMapComponent.feltReportVectorLayer.getSource().getFeatures();

    for (const feature of features) {
      feature.set('radius', 25);
    }
  }

  // Style to display a single felt report
  static getEarthquakeStyle(feature: Feature): Style {
    return new Style({
      geometry: feature.getGeometry(),
      image: new RegularShape({
        radius1: 25,
        radius2: 3,
        points: 5,
        angle: Math.PI,
        fill: new Fill({
          color: 'rgba(0, 255, 255, 0.8)'
        }),
        stroke: new Stroke({
          color: 'rgba(0, 255, 255, 0.2)',
          width: 1
        })
      })
    });
  }

  constructor(private mapService: MapService, private zone: NgZone, @Inject(ENVIRONMENT) private environment: any) {
  }

  // Update the map when the screen is resized
  @HostListener('window:resize') onResize(event: any): void {
    if (this.mapService.mapInstance) {
      setTimeout(() => {
        this.mapService.mapInstance.updateSize();
      }, 500);
    }
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.mapService.mapInstance.updateSize();
    }, 500);
  }

  ngAfterViewInit(): void {
    FeltReportMapComponent.feltReportVectorLayer = new VectorLayer({
      source: new Cluster({
        distance: 40,
        source: new VectorSource({
          url: this.earthQuakeFeltReportsWFSUrl + '&CQL_FILTER=hours_since_report<=' + this.hoursSinceSubmitted,
          format: new GeoJSON()
        })
      }),
      style: FeltReportMapComponent.getFeltReportStyle,
      visible: true
    });

    const feltReportInteraction: Select = new Select({
      condition: (evt): boolean => {
        return evt.type === 'pointermove' ||
          evt.type === 'singleclick';
      },
      style: MapService.selectStyleFunction
    });

    const coordinate: Coordinate = this.mapService.transformCoordinate(this.feltReport.longitude, this.feltReport.latitude, this.displayProjection, this.datumProjection);
    if (this.miniMapComponents.length > 0) {
      this.zone.runOutsideAngular(() => {
        this.miniMapComponents.first.miniMapService.setMapPosition(coordinate[1], coordinate[0], this.zoomLevel, false);
        this.miniMapComponents.first.miniMapService.mapInstance.addInteraction(feltReportInteraction);
        FeltReportMapComponent.feltReportVectorLayer.setZIndex(99);
        this.miniMapComponents.first.miniMapService.mapInstance.addLayer(FeltReportMapComponent.feltReportVectorLayer);
      });
    }
  }
}
