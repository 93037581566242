<div class="notifications-container">
  <h4 class="tool-title">Earthquake Notification Service
    <ga-help-link [linkFragment]="'notifications'"></ga-help-link>
  </h4>
  <hr>

  <ga-notification-link
    [link]="rssFeedUrl"
    type="RSS"
    icon="assets/rss-4-32.png">
  </ga-notification-link>

  <ga-notification-link
    [link]="geoserverGeoRssUrl"
    type="GeoRSS"
    icon="assets/rss-4-32.png">
  </ga-notification-link>

  <ga-notification-link
    [link]="geoserverGeoJsonUrl"
    type="GeoJSON"
    icon="assets/map-marker-32.png">
  </ga-notification-link>

  <ga-notification-link
    [link]="geoserverWfsKmlUrl"
    type="KML"
    icon="assets/google-earth-32.png">
  </ga-notification-link>

  <ga-notification-link
    [link]="atomFeedUrl"
    type="ATOM CAP-AU"
    icon="assets/rss-4-32.png">
  </ga-notification-link>

  <div class="notifications">
    <img src="assets/twitter-4-32.png" alt="Twitter">
    <a (click)="toggleTwitter()">Earthquakes&#64;GA Twitter</a>
    <div class="notification-disclaimer" *ngIf="contentTwitter" [innerHtml]="content" [@animationCommonEnter300Leave300]></div>
    <div class="text-center" *ngIf="contentTwitter" [@animationCommonEnter300Leave300]>
      <button type="button" class="btn btn-ga" (click)="goToUrl('https://twitter.com/earthquakesga'); toggleTwitter()">Accept</button>
      <button type="button" class="btn btn-ga" (click)="toggleTwitter()">Decline</button>
    </div>
  </div>

  <div class="notifications">
    <img src="assets/email-14-32.png" alt="Email">
    <a class="email" (click)="toggleSubscription()">
      Email Subscription</a>
  </div>

  <form (ngSubmit)="onSubscribe()" #subscriptionForm="ngForm" *ngIf="displaySubscription" [@animationCommonEnter300Leave300]
        autocomplete="off">
    <hr>
    <p>Once you subscribe to the Earthquake Notification Service we will notify you when we publish or update information
      about the earthquakes that we monitor.</p>
    <div class="">
      <div class="subscription-group">
        <div style="text-align:left">
          <label class="container radio-inline" style="width: 100px">Subscribe<input type="radio" name="subscribe"
                                                                                     value="Subscribe"
                                                                                     [(ngModel)]="subscriptionType"
                                                                                     (change)="toggleSubscriptionType()"><span
            class="checkmark"></span></label>
          <label class="container radio-inline">Unsubscribe<input type="radio" name="unsubscribe" value="Unsubscribe"
                                                                  [(ngModel)]="subscriptionType"
                                                                  (change)="toggleSubscriptionType()"><span
            class="checkmark"></span></label>
          <a class="closePanel pull-right" href="javascript:;" title="Close subscription"
             (click)="toggleSubscription()"><img src="assets/x-mark-16.png" alt="Close subscription"></a>
        </div>
      </div>
      <div class="subscription-group" *ngIf="!submitted">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <label>Email: (Mandatory *)</label>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <input type="email" class="form-control" id="email" name="email"
                   [(ngModel)]="subscription.email" [email]="true"
                   [disabled]="submitted" required>
          </div>
        </div>
      </div>
      <div class="subscription-group" *ngIf="subscriptionType === 'Subscribe' && !submitted" [@animationCommonEnter300]>
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-6">
            <label for="magnitudeMin">Magnitude Min: *</label>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-6">
            <label for="magnitudeMax">Magnitude Max: *</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-6">
            <input type="number" class="form-control" id="magnitudeMin" name="magnitudeMin"
                   [(ngModel)]="subscription.magnitudeMin"
                   required>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-6">
            <input type="number" class="form-control" id="magnitudeMax" name="magnitudeMax"
                   [(ngModel)]="subscription.magnitudeMax"
                   required>
          </div>
        </div>
      </div>
      <div class="subscription-group" *ngIf="subscriptionType === 'Subscribe' && !submitted" [@animationCommonEnter300]>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <label for="magnitudeMin">Location:</label>
          </div>
        </div>
        <div>
          <div class="dropdown" dropdown>
            <button type="button" class="btn btn-ga btn-dropdown" dropdownToggle>{{ selectedLocation }}<span
              class="caret"></span></button>
            <ul class="dropdown-menu btn-dropdown" *dropdownMenu>
              <li *ngFor="let location of locationList" [class.active]="location === selectedLocation">
                <a href="#" (click)="updateLocation(location)">{{ location }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="subscription-group"
           *ngIf="subscriptionType === 'Subscribe' && !submitted && selectedLocation === 'Please select' && !deviceCommonService.isMobileOrTablet()"
           [@animationCommonEnter300]>
        <div style="text-align:center">
          <button id="drawClearExtentButton" type="button" class="btn btn-ga draw-btn" (click)="draw()"
                  [disabled]="submitted">{{ drawActive ? 'Clear' : 'Draw' }}
          </button>
          <label class="container radio-inline" style="width: 50px">Box<input type="radio" name="box" value="Box"
                                                                              [(ngModel)]="drawType"
                                                                              (change)="resetDraw()"><span
            class="checkmark"></span></label>
          <label class="container radio-inline" style="width: 60px">Circle<input type="radio" name="circle"
                                                                                 value="Circle" [(ngModel)]="drawType"
                                                                                 (change)="resetDraw()"><span
            class="checkmark"></span></label>
          <label class="container radio-inline">Polygon<input type="radio" name="polygon" value="Polygon"
                                                              [(ngModel)]="drawType" (change)="resetDraw()"><span
            class="checkmark"></span></label>
        </div>
        <ng-container *ngIf="drawActive">
          <div class="draw-help" *ngIf="drawType === 'Circle' && drawInteractionActive && radius$ | async as radius;">
            Radius: {{ radius | number:'1.0-0' }}km
          </div>
        </ng-container>
        <div class="draw-help" *ngIf="drawInteractionActive && drawType === 'Box'">Click to start drawing, then click to complete drawing.
        </div>
        <div class="draw-help" *ngIf="drawInteractionActive && drawType === 'Circle'">Click to start drawing, then click to complete drawing.
        </div>
        <div class="draw-help" *ngIf="drawInteractionActive && drawType === 'Polygon'">Click to start drawing, then click twice to complete drawing.
        </div>
        <div class="draw-help" *ngIf="!drawInteractionActive && drawBoundsText !== null">{{ drawBoundsText }}</div>
        <div *ngIf="showDrawError" style="margin-top: 15px">
          <fh-alert [message]="'International Dateline crossed. Drawing must be in the same -180 to 180 Dateline.'" [type]="'error'"></fh-alert>
        </div>
      </div>

      <div class="notification-disclaimer" *ngIf="subscriptionForm.form.valid && !submitted && (subscription.geometry !== null || selectedLocation !== 'Please select')" [innerHtml]="content" [@animationCommonEnter300Leave300]></div>
      <div class="text-center" *ngIf="subscriptionForm.form.valid && !submitted && (subscription.geometry !== null || selectedLocation !== 'Please select')" [@animationCommonEnter300Leave300]>
        <div class="subscription-group" style="text-align:center">
          <label class="container radio-inline" style="width: 75px">Accept<input type="radio" name="accept" value="Accept"
                                                                                 [(ngModel)]="disclaimer"><span
            class="checkmark"></span></label>
          <label class="container radio-inline" style="width: 75px">Decline<input type="radio" name="decline" value="Decline"
                                                                                  [(ngModel)]="disclaimer"><span
            class="checkmark"></span></label>
        </div>
      </div>

      <div class="progress" [hidden]="!showProgressBar">
        <div class="progress-bar progress-bar-info progress-bar-striped active" role="progressbar"
             aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width:100%">
          {{ progressBarText }}
        </div>
      </div>

      <div *ngIf="submitted && submittedData.status === 'Success'">
        <fh-alert [message]="submittedData.message" [type]="'success'"></fh-alert>
      </div>
      <div *ngIf="submitted && (submittedData.status === 'Error' || submittedData.status === 'Warning')">
        <fh-alert [message]="submittedData.message" [type]="'error'"></fh-alert>
      </div>
      <div *ngIf="errorMessage">
        <fh-alert [message]="errorMessage" [type]="'error'"></fh-alert>
      </div>

      <div #submitButton class="text-center submit-btn">
        <button class="btn btn-ga" type="button" (click)="clear()">Clear</button>
        <button id="subscribeButton" *ngIf="subscriptionType === 'Subscribe'" type="submit" class="btn btn-ga"
                [disabled]="!subscriptionForm.form.valid || (subscription.geometry === null && selectedLocation === 'Please select') || submitted || disclaimer === 'Decline'">
          Submit
        </button>
        <button id="unsubscribeButton" *ngIf="subscriptionType === 'Unsubscribe'" type="submit" class="btn btn-ga"
                [disabled]="!subscriptionForm.form.valid || submitted">Submit
        </button>
      </div>
    </div>
  </form>
</div>
