import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MapService } from '../../map/map.service';
import { AnimationCommon, BoundsCommon, DeviceCommonService, DrawTypeType, ENVIRONMENT, GeometryCommonService, SidePanelCommonService } from 'flying-hellfish-common';
import { Observable, Subscription } from 'rxjs';
import { NotificationsService } from './notifications.service';
import { NotificationSubscription, QueryTypes } from './notification.subscription';
import { ToolConstants } from '../../shared/tool-constants';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppConstants } from '../../app.constants';

@Component({
  selector: 'ga-toolbar-notifcations',
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.css'],
  preserveWhitespaces: true,
  animations: [
    AnimationCommon.animationCommonEnter300,
    AnimationCommon.animationCommonEnter300Leave300
  ]
})
export class NotificationsComponent implements OnDestroy {
  @ViewChild('submitButton', { read: ElementRef }) submitButtonElement: ElementRef;

  atomFeedUrl: string = this.environment.serviceUrls.atomFeedUrl;
  rssFeedUrl: string = this.environment.serviceUrls.rssFeedUrl;
  geoserverWfsKmlUrl: string = this.environment.serviceUrls.earthQuakeSummarySevenDaysWFSKml;
  geoserverGeoRssUrl: string = this.environment.serviceUrls.earthQuakeSummaryGeoRSS;
  geoserverGeoJsonUrl: string = this.environment.serviceUrls.earthQuakeSummaryGeoJSON;
  helpIcon: string = AppConstants.DEFAULT_HELP_ICON;

  displaySubscription: boolean = false;
  showProgressBar: boolean = false;
  progressBarText: string = null;
  subscription: NotificationSubscription;
  subscriptions: Subscription = new Subscription();

  subscriptionType: string = 'Subscribe';
  submitted: boolean = false;
  submittedData: any;
  drawCoordinates: any[] = null;
  drawActive: boolean = false;
  drawInteractionActive: boolean = false;
  drawBoundsText: string = null;
  drawType: DrawTypeType = 'Box';
  disclaimer: string = 'Decline';
  drawRadius: number = 0;
  errorMessage: string = null;
  locationList: string[] = ['Please select', 'Australia', 'New South Wales', 'Northern Territory', 'Queensland', 'South Australia', 'Tasmania', 'Victoria', 'Western Australia', 'World'];
  selectedLocation: string = 'Please select';
  showDrawError: boolean = false;
  content: SafeHtml;
  contentTwitter: boolean = false;
  contentEmail: boolean = false;
  browser: any;

  radius$: Observable<number> = this.mapService.onRadiusChange.asObservable();

  constructor(private sidePanelCommonService: SidePanelCommonService, private mapService: MapService, public deviceCommonService: DeviceCommonService,
              private notificationService: NotificationsService, private geometryCommonService: GeometryCommonService, private sanitizer: DomSanitizer,
              @Inject(ENVIRONMENT) private environment: any) {
    this.subscription = new NotificationSubscription();
    this.subscription.magnitudeMin = 0;
    this.subscription.magnitudeMax = 9.9;
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.environment.disclaimer);
    this.browser = this.deviceCommonService.getDeviceInfo().browser;

    // If the last active tool was Notifications then reset
    this.subscriptions.add(this.sidePanelCommonService.leftMenuEmitter.subscribe((data) => {
      if (data.lastActiveToolId === 'notifications') {
        this.reset();
      }
    }));

    // If using the route 'emailSubscription' this opens the email subscription on the notifications panel
    this.subscriptions.add(this.notificationService.emailSubscriptionSubject.subscribe(() => {
      setTimeout(() => {
        this.toggleSubscription();
      }, 200);
    }));
  }

  // Toggle the display of the Twitter disclaimer.
  toggleTwitter(): void {
    this.contentTwitter = !this.contentTwitter;
  }

  // Toggle the display of the email notification
  toggleSubscription(): void {
    if (this.displaySubscription) {
      this.clear();
    }
    this.displaySubscription = !this.displaySubscription;
    this.contentEmail = !this.contentEmail;
    this.scroll();
  }

  // Toggle the subscription type and retain the email contents
  toggleSubscriptionType(): void {
    const email: string = this.subscription.email;
    this.clear();
    this.subscription.email = email;
  }

  // Clear all the variables and map layers
  clear(): void {
    this.subscription.email = null;
    this.subscription.magnitudeMin = 0;
    this.subscription.magnitudeMax = 9.9;
    this.subscription.geometry = null;
    this.mapService.removeDrawExtentInteraction(ToolConstants.SUBSCRIPTION);
    this.mapService.removeDrawExtentLayer(ToolConstants.SUBSCRIPTION);
    this.drawActive = false;
    this.drawInteractionActive = false;
    this.drawBoundsText = null;
    this.showProgressBar = false;
    this.submitted = false;
    this.submittedData = null;
    this.errorMessage = null;
    this.selectedLocation = 'Please select';
    this.drawType = 'Box';
    this.drawRadius = 0;
    this.mapService.removeStateLayer(ToolConstants.SUBSCRIPTION);
    this.showDrawError = false;
    this.disclaimer = 'Decline';
  }

  // Resets the subscription fields
  reset(): void {
    this.clear();
    this.subscriptionType = 'Subscribe';
    this.displaySubscription = false;
  }

  // Activate the draw tool and change the button text
  draw(): void {
    this.drawActive = !this.drawActive;

    if (!this.drawActive) {
      this.drawCoordinates = null;
      this.subscription.geometry = null;
      this.mapService.removeDrawExtentLayer(ToolConstants.SUBSCRIPTION);
      this.mapService.removeDrawExtentInteraction(ToolConstants.SUBSCRIPTION);
      this.drawBoundsText = null;
      this.drawRadius = 0;
      this.showDrawError = false;
    } else if (this.drawActive) {
      this.drawInteractionActive = true;
      this.mapService.startDrawExtentInteraction(this.mapService, this.drawType, ToolConstants.SUBSCRIPTION);

      // Subscribe to the draw end event
      this.subscriptions.add(this.mapService.drawEndEmitter.subscribe((data) => {
        if (data.layerId === ToolConstants.SUBSCRIPTION) {
          if (data.extent) {
            const bounds: BoundsCommon = new BoundsCommon(data.extent[0], data.extent[1], data.extent[2], data.extent[3]);
            if (this.geometryCommonService.hasGeometryCrossedDateLine(bounds)) {
              this.showDrawError = true;
            } else {
              const correctedCoordinates: [number[]] = this.geometryCommonService.getCorrectedDrawExtentBounds(bounds, <[number[]]>data.coordinatesEpsg4326);
              let polygon: string = '';
              for (let i: number = 0; i < correctedCoordinates.length; i++) {
                polygon = polygon + correctedCoordinates[i][0] + ' ' + correctedCoordinates[i][1] + ', ';
              }
              this.subscription.geometry = polygon.substr(0, polygon.length - 2);
            }
            this.drawRadius = data.radius;
          }
          this.drawInteractionActive = false;
          this.mapService.removeDrawExtentInteraction(ToolConstants.SUBSCRIPTION);
          this.scroll();
        }
      }));
    }
  }

  // Triggered when the submit button is selected
  onSubscribe(): void {
    if (this.subscriptionType === 'Subscribe') {
      this.progressBarText = 'Subscribing';
      this.subscription.queryType = QueryTypes.INSERT;

      // Call the subscription service to subscribe the user
      this.subscriptions.add(this.notificationService.submitSubscription(this.subscription)
        .subscribe({
          next: data => {
            this.showProgressBar = false;
            this.submitted = true;
            this.submittedData = data;
            this.mapService.removeStateLayer(ToolConstants.SUBSCRIPTION);
          },
          error: error => {
            this.showProgressBar = false;
            this.errorMessage = typeof error === 'string' ? error : error.message;
            console.log(error);
          }
        }));
    } else if (this.subscriptionType === 'Unsubscribe') {
      this.progressBarText = 'Unsubscribing';
      this.subscription.queryType = QueryTypes.DELETE;

      // Call the subscription service to unsubscribe the user
      this.subscriptions.add(this.notificationService.submitSubscription(this.subscription)
        .subscribe({
          next: data => {
            this.showProgressBar = false;
            this.submitted = true;
            this.submittedData = data;
          },
          error: error => {
            this.showProgressBar = false;
            this.errorMessage = typeof error === 'string' ? error : error.message;
            console.log(error);
          }
        }));
    }

    this.showProgressBar = true;
  }

  // Resets the draw functionality
  resetDraw(): void {
    this.drawActive = false;
    this.drawInteractionActive = false;
    this.drawCoordinates = null;
    this.mapService.removeDrawExtentInteraction(ToolConstants.SUBSCRIPTION);
    this.mapService.removeDrawExtentLayer(ToolConstants.SUBSCRIPTION);
    this.drawBoundsText = null;
    this.showDrawError = false;
  }

  // Update the subscription geometry when a state is selected from the environment file
  updateLocation(location: string): void {
    this.selectedLocation = location;
    if (this.selectedLocation !== 'Please select') {
      this.subscription.geometry = this.environment.boundaries100kmBuffer[this.selectedLocation];
      this.mapService.addStateLayer(this.subscription.geometry, ToolConstants.SUBSCRIPTION);

      this.resetDraw();
      this.scroll();
    } else {
      this.subscription.geometry = null;
      this.mapService.removeStateLayer(ToolConstants.SUBSCRIPTION);
    }
  }

  // Scroll to the bottom of the panel where the submit button is.
  scroll(): void {
    this.deviceCommonService.scrollIntoView(this.submitButtonElement);
  }

  goToUrl(url: string): void {
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
