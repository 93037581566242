import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { Router } from '@angular/router';
import { FeatureType } from '../../types/feature.type';
import { AnimationCommon } from 'flying-hellfish-common';

@Component({
  selector: 'ga-toolbar-share-event',
  templateUrl: 'share.event.component.html',
  styleUrls: ['share.event.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: true,
  animations: [
    AnimationCommon.animationCommonLeave1000
  ]
})
export class ShareEventComponent {
  @Input() feature: FeatureType;

  showCopiedToClipboard: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef, private clipboardService: ClipboardService, private router: Router) {
  }

  // Copy the event id to the clipboard.
  copyEventIDtoClipboard(eventID: string): void {
    let urlArray: string[];
    let eventIDUrl: string;
    if (this.router.url === '/') {
      eventIDUrl = window.location.href + 'event/' + eventID;
    } else {
      urlArray = window.location.href.split(this.router.url);
      eventIDUrl = urlArray[0] + '/event/' + eventID;
    }
    this.copyToClipboard(eventIDUrl);
    this.showCopiedToClipboard = true;
    setTimeout(() => {
      this.showCopiedToClipboard = false;
      this.changeDetectorRef.markForCheck();
    }, 1500);
  }

  // Use ngx-clipboard to copy shortUrl to the clipboard
  copyToClipboard(text: string): void {
    this.clipboardService.copyFromContent(text);
  }
}
