import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MapService } from '../map/map.service';
import { QuakeDetailsService } from '../tools/quake-details/quake.details.service';
import Overlay from 'ol/Overlay';
import { Subscription } from 'rxjs';
import { ShakemapEventType } from '../types/shakemap.type';

@Component({
  selector: 'ga-shakemap-popup',
  templateUrl: 'shakemap.popup.component.html',
  styleUrls: ['shakemap.popup.component.css'],
  preserveWhitespaces: true
})
export class ShakemapPopupComponent implements AfterViewInit, OnDestroy {
  @ViewChild('popup', { read: ElementRef }) popup: ElementRef;
  shakemapOverlay: Overlay;
  shakemapReponse: ShakemapEventType;
  subscriptions: Subscription = new Subscription();

  constructor(private mapService: MapService, private quakeDetailsService: QuakeDetailsService) {
    this.subscriptions.add(quakeDetailsService.onDisplayShakemapPopup$.subscribe((response) => {
      if (response) {
        this.shakemapReponse = response;
        this.shakemapOverlay?.setPosition(this.mapService.getCoordinateFromPixel(response.pixel));
      } else {
        this.closePopup();
      }
    }));

    this.subscriptions.add(mapService.onMapZoom$.subscribe(() => {
      this.closePopup();
    }));
  }

  // Setup the overlay for the shakemap popup
  ngAfterViewInit(): void {
    this.shakemapOverlay = new Overlay({
      element: this.popup.nativeElement,
      autoPan: true
    });

    this.mapService.addOverlay(this.shakemapOverlay);
  }

  // Closes the shakemap popup
  closePopup(): void {
    this.shakemapOverlay?.setPosition(undefined);
    this.popup.nativeElement.blur();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
