<div class="notifications">
  <img [src]="icon" [alt]="type">
  <a (click)="showDisclaimer = !showDisclaimer; acceptedDisclaimer = false">{{ type }}</a>
  <div class="notification-disclaimer" *ngIf="showDisclaimer" [innerHtml]="disclaimer" [@animationCommonEnter300Leave300]></div>
  <div class="text-center" *ngIf="showDisclaimer" [@animationCommonEnter300Leave300]>
    <button type="button" class="btn btn-ga" (click)="acceptedDisclaimer = true">Accept</button>
    <button type="button" class="btn btn-ga" (click)="showDisclaimer = !showDisclaimer">Decline</button>
  </div>
  <ng-container *ngIf="showDisclaimer && acceptedDisclaimer">
    <hr>
    <div class="input-group">
      <input type="text" class="form-control" readonly [value]="link">
      <span class="input-group-btn">
        <button class="btn btn-ga" type="button" title="Copy {{ type }} URL to clipboard" attr.aria-label="Copy {{ type }} URL to clipboard" (click)="copyToClipboard(link)">
          <span class="glyphicon glyphicon-copy" aria-hidden="true"></span>
        </button>
        <button class="btn btn-ga" type="button" title="Download {{ type }} file" attr.aria-label="Download {{ type }} file" (click)="goToUrl(link)">
          <span class="glyphicon glyphicon-download" aria-hidden="true"></span>
        </button>
      </span>
    </div>
  </ng-container>
</div>
