export class FeltReport {
  felt: string;
  feltIndex: number;
  dateTime: Date;
  epicentralDateTime: Date;
  situation: string;
  situationOther: string;
  sleep: string;
  othersFelt: string;
  othersFeltIndex: number;
  motion: string;
  motionIndex: number;
  reaction: string;
  reactionIndex: number;
  response: string;
  responseOther: string;
  stand: string;
  standIndex: number;
  swing: string;
  noise: string;
  shelf: string;
  shelfIndex: number;
  picture: string;
  pictureIndex: number;
  furniture: string;
  furnitureIndex: number;
  appliance: string;
  wallsFences: string;
  damage: string;
  damageIndex: number;
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  comments: string;
  name: string;
  phone: string;
  email: string;
  latitude: number;
  longitude: number;
  eventId: string;
  privacyConsent: string;
  feltLocation: AddressLocation;
  submitted: boolean;
}

export class RecentQuakeType {
  eventId: string;
  epicentralTime: string;
  locatedInAustralia: string;
  text: string;
  utcDate: string;
}

export class AddressLocation {
  address: string;
  state: string;
  suburb: string;
  postcode: string;
  location: { latitude: number, longitude: number };
}
