import { Component, Input } from '@angular/core';
import { environment } from '@environment';

@Component({
  selector: 'ga-help-link',
  templateUrl: './help-link.component.html'
})
export class HelpLinkComponent {
  @Input() linkFragment: string;
  iconCacheUrl: string = environment.iconsCacheUrl;
}
