import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { QuakeDetailsComponent } from './quake.details.component';
import { QuakeDetailsMapComponent } from './quake.details.map.component';
import { QuakeDetailsService } from './quake.details.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FeltReportChartComponent } from './chart/felt.report.chart.component';
import { FlyingHellfishCommonModule } from 'flying-hellfish-common';

@NgModule({
  declarations: [
    QuakeDetailsComponent,
    QuakeDetailsMapComponent,
    FeltReportChartComponent
  ],
  imports: [
    SharedModule,
    NgxChartsModule,
    FlyingHellfishCommonModule
  ],
  exports: [
    QuakeDetailsComponent,
    QuakeDetailsMapComponent
  ],
  providers: [
    QuakeDetailsService
  ]
})
export class QuakeDetailsModule {
}
