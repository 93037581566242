import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable, of, Subject } from 'rxjs';
import { FeatureJson } from '../../types/feature.json.type';
import * as moment from 'moment';
import { FeatureType } from '../../types/feature.type';
import { FeltReportCount } from '../../types/felt.report.count';
import { catchError, first, map } from 'rxjs/operators';
import { FeltGridQueryResponseType } from '../../types/felt.grid.query.response.type';
import { ShakeMap, ShakemapEventType } from '../../types/shakemap.type';
import { Pixel } from 'ol/pixel';
import { containsCoordinate, Extent } from 'ol/extent';
import { ImageStatic } from 'ol/source';
import { Layer } from 'ol/layer';
import { AppConstants } from '../../app.constants';
import { FeltReport, FeltReportCalculation } from '../../types/felt.report';
import * as _ from 'lodash';
import { ENVIRONMENT } from 'flying-hellfish-common';
import ImageLayer from 'ol/layer/Image';
import { Coordinate } from 'ol/coordinate';

@Injectable()
export class QuakeDetailsService {
  private stationsURL: string = this.environment.serviceUrls.earthQuakeStationsWFS;
  private magnitudeURL: string = this.environment.serviceUrls.earthQuakeMagnitudeWFS;
  private stationsExportURL: string = this.environment.serviceUrls.earthQuakeStationsExportWFS;
  private focalMechanismURL: string = this.environment.serviceUrls.earthQuakeFocalMechanismWFS;
  private feltReportsWFSUrl: string = this.environment.serviceUrls.earthQuakeFeltReportsWFS;
  private skipCloudFrontUrl: string = this.environment.serviceUrls.skipCloudFrontUrl;
  private onDisplayFeltGridQuery: Subject<FeltGridQueryResponseType> = new Subject<FeltGridQueryResponseType>();
  private onDisplayShakemapPopup: Subject<ShakemapEventType> = new Subject<ShakemapEventType>();
  private shakemapExtent: Extent;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: any) {
  }

  // Get a csv export from Geoserver using WFS of the Earthquake Id passed in.
  getCsvExport(criteria: string): Observable<Blob> {
    const exportUrl: string = this.stationsExportURL + criteria + '&sortBy=distance_deg+A';
    return this.http.get<Blob>(exportUrl, { responseType: 'blob' as 'json' });
  }

  // Get stations for an Earthquake Id Geoserver using WFS
  getStations(eventId: string): Observable<FeatureJson> {
    return this.http.get<FeatureJson>(this.stationsURL + `&CQL_FILTER=event_id='${eventId}' and time_defining_for_origin=true&sortBy=distance_deg+A`);
  }

  // Get Magnitude for an Earthquake Id Geoserver using WFS
  getMagnitude(earthquakeId: number): Observable<FeatureJson> {
    return this.http.get<FeatureJson>(this.magnitudeURL + '&CQL_FILTER=earthquake_id=' + earthquakeId);
  }

  // Check if creation date of the feature is before Seiscomp EQ@GA AWS release date
  isFeaturePreSeiscomp(feature: FeatureType): boolean {
    if (moment(feature.event_creation_time).isBefore('2018-05-24T00:00:00-00:00')) {
      return true;
    } else {
      return false;
    }
  }

  // Get focal mechanism for an Earthquake Id Geoserver using WFS
  getFocalMechanism(earthquakeId: number): Observable<FeatureJson> {
    return this.http.get<FeatureJson>(this.focalMechanismURL + '&CQL_FILTER=earthquake_id=' + earthquakeId);
  }

  // Get seismogram for an event
  getSeismogram(eventId: string): Observable<boolean> {
    const exportUrl: string = this.skipCloudFrontUrl + '/events/' + eventId + '/traces.png';

    return this.http.head(exportUrl, { observe: 'response' }).pipe(
      map((response) => response.status === 200),
      catchError(() => of(false))
    );
  }

  // Get all the felt reports for an event
  getFeltReportsForEvent(eventId: string): Observable<FeatureJson> {
    return this.http.get<FeatureJson>(this.feltReportsWFSUrl + '&CQL_FILTER=event_id=\'' + eventId + '\'');
  }

  // Determine whether ShakeMap downloads are available
  hasShakeMapDownloads(eventId: string): Observable<boolean> {
    const exportUrl: string = this.skipCloudFrontUrl + '/events/' + eventId + '/intensity.pdf';

    return this.http.head(exportUrl, { observe: 'response' }).pipe(
      map((response) => response.status === 200),
      catchError(() => of(false))
    );
  }

  // Determine whether Geoserver has layer
  hasLayer(layer: string): Observable<boolean> {
    const wmsUrl: string = `${this.environment.serviceUrls.earthquakesGeoserver}/wms`;
    const params: HttpParams = new HttpParams()
      .append('service', 'WMS')
      .append('version', '1.1.1')
      .append('request', 'DescribeLayer')
      .append('exceptions', 'application/json')
      .append('output_format', 'application/json')
      .append('layers', layer);

    return this.http.get<any>(wmsUrl, { params }).pipe(
      map((response) => !response.hasOwnProperty('exceptions'))
    );
  }

  // Get felt reports count with the given interval and hours since the quake
  getFeltReportCounts(feltReportCalculation: FeltReportCalculation): Observable<FeltReportCount[]> {
    return this.http.get<FeatureJson>(`${this.feltReportsWFSUrl}&CQL_FILTER=event_id='${feltReportCalculation.eventId}'`)
      .pipe(first(), map((response) => {
        const feltReport: FeltReport[] = response.features.map((feature) => feature.properties) as FeltReport[];
        const feltReportCounts: FeltReportCount[] = [];
        _.range(0, AppConstants.MINUTES_PER_HOUR * feltReportCalculation.hoursSinceEvent, feltReportCalculation.reportCountInterval).forEach(value => {
          feltReportCounts.push({ felt_reports: 0, hour: 0, minute: value });
        });
        feltReport.forEach(report => {
          const createdDate: string = report.created_date ? report.created_date : moment.utc().add(-report.hours_since_report, 'hours').toISOString();
          const eventToReportInMins: number = moment.duration(moment(createdDate).diff(moment(feltReportCalculation.originTime))).asMinutes();
          const reportCount: FeltReportCount = feltReportCounts.find(reportTime => reportTime.minute > eventToReportInMins);
          if (reportCount) {
            reportCount.felt_reports += 1;
          }
        });
        return feltReportCounts;
      }));
  }

  // Get data as text from url
  getDataAsTextFromUrl(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'text' });
  }

  // Display felt grid cell details
  displayFeltGridDetails(pixel: Pixel, properties: any, resolution: number): void {
    this.onDisplayFeltGridQuery.next({
      pixel,
      location: properties.location,
      intensity: properties.intensity,
      feltResponses: properties.nresp,
      gridResolution: resolution
    });
  }

  // Display Event details on Shakemap popup
  displayEventDetails(pixel: Pixel, shakemap: ShakeMap): void {
    this.onDisplayShakemapPopup.next({
      pixel,
      event_id: shakemap.event_id,
      latitude: shakemap.event_latitude,
      longitude: shakemap.event_longitude,
      depth: shakemap.event_depth,
      magnitude: shakemap.event_magnitude,
      magnitude_type: shakemap.event_magnitude_type,
      origin_time: shakemap.event_origin_time,
      published_version: shakemap.event_published_version
    });
  }

  // Hides the Felt Grid popup
  hideFeltGridDetails(): void {
    this.onDisplayFeltGridQuery.next(null);
  }

  // Hides the Shakemap popup
  hideEventDetails(): void {
    this.onDisplayShakemapPopup.next(null);
  }

  // Download JSON file
  downloadJson(url: string): Observable<any> {
    return this.http.get(url);
  }

  get onDisplayFeltGridQuery$(): Observable<FeltGridQueryResponseType> {
    return this.onDisplayFeltGridQuery.asObservable();
  }

  get onDisplayShakemapPopup$(): Observable<ShakemapEventType> {
    return this.onDisplayShakemapPopup.asObservable();
  }

  getShakeMap(eventId: string): Observable<ShakeMap> {
    return this.http.get<FeatureJson>(`${this.environment.serviceUrls.shakeMapWFS}&CQL_FILTER=event_id='${eventId}'&dt=${Date.now()}`)
      .pipe<ShakeMap>(map((response) => response.features[0].properties));
  }

  getFeltGridGeoJSON(eventId: string, resolution: number): Observable<any> {
    return this.http.get(`/shakemap/${eventId}/grid/felt_reports_${resolution}km.geojson`);
  }

  // Get ShakeMap layer using static PNG image with Esri world file georeferencing
  async getShakeMapLayer(eventId: string, shakemapVersion: number): Promise<Layer> {
    const image: HTMLImageElement = await new Promise((resolve, reject) => {
      const imageLoader: HTMLImageElement = this.getImageElement();
      imageLoader.onload = (): void => {
        resolve(imageLoader);
      };

      imageLoader.src = `/shakemap/${eventId}/v${shakemapVersion}/${eventId}.png`;
    });

    this.shakemapExtent = await lastValueFrom(this.http.get(`/shakemap/${eventId}/v${shakemapVersion}/${eventId}.wld`, { responseType: 'text' }).pipe(map((pngw) => {
      const [xScale, ySkew, xSkew, yScale, minX, maxY]: number[] = pngw.split(/\r?\n/).map(n => +n);

      const maxX: number = minX + (image.width * xScale);
      const minY: number = maxY + (image.height * yScale);

      return [minX, minY, maxX, maxY] as Extent;
    })));

    return new ImageLayer({
      source: new ImageStatic({
        imageExtent: this.shakemapExtent,
        projection: this.environment.datumProjection,
        url: `/shakemap/${eventId}/v${shakemapVersion}/${eventId}.png`
      }),
      zIndex: 40
    });
  }

  // Returns true if the given coordinate falls within the shakemap extent
  isCoordinateWithinShakemap(coordinate: Coordinate): boolean {
    return containsCoordinate(this.shakemapExtent, coordinate);
  }

  // Get image element
  getImageElement(): HTMLImageElement {
    return new Image();
  }
}
