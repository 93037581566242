import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FeatureJson } from '../../types/feature.json.type';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from 'flying-hellfish-common';

@Injectable()
export class SearchService {
  private searchURL: string = this.environment.serviceUrls.earthQuakeSummaryWFS;
  private recentQuakesUrl: string = this.environment.serviceUrls.earthQuakeSummarySevenDaysWFS;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: any) {
  }

  // Get a csv export from Geoserver using WFS of the search criteria passed in.
  getCsvExport(criteria: string): Observable<Blob> {
    let exportUrl: string = this.environment.serviceUrls.earthQuakeExportWFS;
    exportUrl = exportUrl + criteria;
    return this.http.get<Blob>(exportUrl, { responseType: 'blob' as 'json' });
  }

  // Get features from Geoserver using WFS
  getFeatures(appendToUrl: string): Observable<FeatureJson> {
    return this.http.get<FeatureJson>(this.searchURL + appendToUrl);
  }

  // Get feature count from Geoserver using WFS
  getFeatureCount(appendToUrl: string): Observable<string> {
    return this.http.get(this.searchURL + appendToUrl, { responseType: 'text' });
  }

  // Get recent quakes from Geoserver using WFS
  getRecentQuakes(): Observable<FeatureJson> {
    return this.http.get<FeatureJson>(this.recentQuakesUrl);
  }
}
