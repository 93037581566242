import { InjectionToken, NgModule } from '@angular/core';
import { environment } from '@environment';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { Provider } from '@aws-sdk/types';
import { CognitoIdentityCredentialProvider } from '@aws-sdk/credential-providers/dist-types/ts3.4';
import { LocationClient } from '@aws-sdk/client-location';
import { AwsCredentialIdentity } from '@aws-sdk/types/dist-types/identity';

// Allow type inference of AWS providers
// eslint-disable-next-line
export const AWS_PROVIDER = {
  CREDENTIAL: new InjectionToken<Provider<AwsCredentialIdentity>>('CredentialProvider'),
  LOCATION: new InjectionToken<LocationClient>('Location')
} as const;

@NgModule({
  providers: [
    {
      provide: AWS_PROVIDER.CREDENTIAL, useFactory: (): CognitoIdentityCredentialProvider => {
        return fromCognitoIdentityPool({
          clientConfig: {
            region: 'ap-southeast-2'
          },
          identityPoolId: environment.identityPoolId
        });
      }
    },
    {
      provide: AWS_PROVIDER.LOCATION, useFactory: (credentials: Provider<AwsCredentialIdentity>): LocationClient => {
        return new LocationClient({
          apiVersion: '2020-11-19',
          region: 'ap-southeast-2',
          credentials
        });
      },
      deps: [AWS_PROVIDER.CREDENTIAL]
    }
  ]
})
export class AwsModule {
}
