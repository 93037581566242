<div class="about-container">
  <h4 class="tool-title">About Earthquakes&#64;GA</h4>
  <hr>
  <ul>
    <li>
      This website contains the latest earthquake information available from the National Earthquake Alerts Centre (NEAC) at Geoscience Australia (GA). Available information includes the latest earthquake location, depth and
      magnitude updates, FeltGrid updates, and the latest ShakeMap.
    </li>
    <li>
      The website has the full range of earthquakes published by NEAC’s 24x7 operations. This includes: Australian earthquakes M2.5 or greater; international earthquakes M5.0 or greater; and other smaller (but locatable)
      earthquakes reported to NEAC as having been felt in Australia.
    </li>
    <li>
      The website has an email subscription service where members of the public can request earthquake notifications for events of interest.
    </li>
    <li>
      The website hosts the Felt Report service where members of the public can report having felt an earthquake. The location of each felt reports is visible on the website and updated in real-time.
    </li>
    <li>
      The website hosts the access point where members of the public can search GA’s Earthquake Catalogue, and download earthquake event data.
    </li>
  </ul>
</div>
