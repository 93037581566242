export class Chart {
  config: any[] = [];
  colorScheme: any;
  view: [number, number] = [0, 0];
  xAxisLabel: string;
  yAxisLabel: string;
  xAxisTicks: number[] = [];

  constructor() {
  }
}

export class ChartSeries {
  name: string | number;
  value: number;
}

