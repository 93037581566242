import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FeatureJson } from '../types/feature.json.type';
import { MapService } from '../map/map.service';
import { ENVIRONMENT, ShareCommonService, SidePanelCommonService, ToolbarCommonType, ToolbarToolsCommonType } from 'flying-hellfish-common';
import { MapSaveStateType } from '../types/map.save.state.type';
import { ApplicationStateService } from '../shared/services/state/application.state.service';
import { NotificationsService } from '../tools/notifications/notifications.service';

@Component({
  templateUrl: 'main.component.html'
})
export class MainComponent implements AfterViewInit, OnDestroy {
  activeToolRight: string;
  activeToolLeft: string;
  disclaimerUrl: string = this.environment.disclaimerUrl;
  feedbackEmail: string = this.environment.feedbackEmail;
  feedbackEmailSubject: string = this.environment.feedbackEmailSubject;
  toolBarTools: ToolbarToolsCommonType = this.environment.toolbar.tools;
  toolbar: ToolbarCommonType = this.environment.toolbar;
  eventId: string;
  searchUrl: string = this.environment.serviceUrls.earthQuakeSummaryWFS;
  subscriptions: Subscription = new Subscription();

  constructor(private sidePanelCommonService: SidePanelCommonService, private activeRoute: ActivatedRoute, private http: HttpClient,
              private mapService: MapService, public shareCommonService: ShareCommonService, private applicationStateService: ApplicationStateService,
              private notificationsService: NotificationsService, @Inject(ENVIRONMENT) private environment: any) {
    this.subscriptions.add(sidePanelCommonService.leftMenuEmitter.subscribe((data) => {
      this.activeToolLeft = data.activeToolId;
    }));

    this.subscriptions.add(sidePanelCommonService.rightMenuEmitter.subscribe((data) => {
      this.activeToolRight = data.activeToolId;
    }));
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.activeRoute.url.subscribe(url => {
      if (url.length === 2 && url[0].path === 'event') {
        this.subscriptions.add(this.activeRoute.params.subscribe(params => {
          this.eventId = params['id'];
          if (this.eventId) {
            this.subscriptions.add(this.getEarthquake(this.eventId)
              .subscribe({
                next: data => {
                  const feature: any = data.features[0];
                  if (feature) {
                    this.sidePanelCommonService.mapToolClicked({
                      toolId: 'identify',
                      toolSide: 'right',
                      panelWidth: this.sidePanelCommonService.getRightPanelWidthForTool('identify'),
                      keepOpen: true
                    });
                    this.mapService.identifyEventFeatureEmitter.next(feature);
                  }
                },
                error: error => console.error(error)
              }));
          }
        }));
      } else if (url.length === 2 && url[0].path === 'zoom') {
        this.subscriptions.add(this.activeRoute.params.subscribe(params => {
          this.mapService.setZoomLevel(params['level']);
        }));
      } else if (url.length === 4 && url[0].path === 'zoom') {
        this.subscriptions.add(this.activeRoute.params.subscribe(params => {
          this.mapService.zoomToLocation(params['longitude'], params['latitude'], params['level']);
        }));
      } else if (url.length === 2 && url[0].path === 'restore') {
        this.subscriptions.add(this.activeRoute.params.subscribe(params => {
          this.subscriptions.add(this.shareCommonService.restoreApplicationState(params['restoreId']).subscribe((restoreState: MapSaveStateType) => {
            this.applicationStateService.restoreApplicationState(restoreState);
          }));
        }));
      } else if (url.length === 1 && url[0].path) {
        if (url[0].path.toLowerCase() === 'feltreport') {
          this.sidePanelCommonService.mapToolClicked({
            toolId: 'feltReport',
            toolSide: 'right',
            panelWidth: this.sidePanelCommonService.getRightPanelWidthForTool('feltReport'),
            keepOpen: true
          });
        } else if (url[0].path === 'search') {
          this.sidePanelCommonService.mapToolClicked({
            toolId: 'search',
            toolSide: 'right',
            panelWidth: this.sidePanelCommonService.getRightPanelWidthForTool('search'),
            keepOpen: true
          });
        } else if (url[0].path === 'notifications') {
          this.sidePanelCommonService.mapToolClicked({
            toolId: 'notifications',
            toolSide: 'left',
            panelWidth: this.sidePanelCommonService.getLeftPanelWidthForTool('notifications'),
            keepOpen: true
          });
        } else if (url[0].path.toLowerCase() === 'emailsubscription') {
          this.sidePanelCommonService.mapToolClicked({
            toolId: 'notifications',
            toolSide: 'left',
            panelWidth: this.sidePanelCommonService.getLeftPanelWidthForTool('notifications'),
            keepOpen: true
          });
          this.notificationsService.emailSubscriptionSubject.next();
        }
      }
    }));
  }

  // Get the earthquake for the eventId passed in.
  getEarthquake(eventId: string): Observable<FeatureJson> {
    return this.http.get<FeatureJson>(this.searchUrl + '&CQL_FILTER=event_id=' + '\'' + eventId + '\' AND display_flag=\'Y\'');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
