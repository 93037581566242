import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NotificationsComponent } from './notifications.component';
import { NotificationsService } from './notifications.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FlyingHellfishCommonModule } from 'flying-hellfish-common';
import { NotificationLinkComponent } from './notification.link.component';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [
    NotificationLinkComponent,
    NotificationsComponent
  ],
  imports: [
    SharedModule,
    BsDropdownModule.forRoot(),
    FlyingHellfishCommonModule,
    RouterLink
  ],
  exports: [
    NotificationsComponent
  ],
  providers: [
    NotificationsService
  ]
})
export class NotificationsModule {
}
