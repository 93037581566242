import { Component, Inject, OnDestroy } from '@angular/core';
import { Glossary } from '@shared/glossary';
import { RecentQuakesService } from './recent.quakes.service';
import { MapService } from '../../map/map.service';
import { AnimationCommon, DeviceCommonService, ENVIRONMENT, SidePanelCommonService } from 'flying-hellfish-common';
import { Subscription } from 'rxjs';
import { TextValueType } from '../../types/recent.quake.type';
import { AppConstants } from '../../app.constants';

@Component({
  selector: 'ga-toolbar-recent-quakes',
  templateUrl: 'recent.quakes.component.html',
  styleUrls: ['recent.quakes.component.css'],
  preserveWhitespaces: true,
  animations: [
    AnimationCommon.animationCommonEnter300Leave200
  ]
})
export class RecentQuakesComponent implements OnDestroy {
  glossary: Glossary;
  selectedQuakeDetail: any = null;
  showQuakeDetail: boolean = false;
  helpIcon: string = AppConstants.DEFAULT_HELP_ICON;
  periodList: TextValueType[] = this.environment.recentEarthquakes.periodList;
  magnitudeList: TextValueType[] = this.environment.recentEarthquakes.magnitudeList;
  regionList: string[] = this.environment.recentEarthquakes.regionList;
  subscriptions: Subscription = new Subscription();

  constructor(public recentQuakesService: RecentQuakesService, public deviceCommonService: DeviceCommonService, private sidePanelCommonService: SidePanelCommonService,
              private mapService: MapService, @Inject(ENVIRONMENT) private environment: any) {
    this.glossary = new Glossary();

    this.subscriptions.add(this.sidePanelCommonService.rightMenuEmitter.subscribe((data) => {
      // Clean up the layers on the map
      if (data.lastActiveToolId === 'recentQuakes') {
        // Hide the quake details when the panel closes
        this.showQuakeDetail = false;
        if (!mapService.getRecentQuakesLayerVisibility()) {
          this.mapService.removeStationsLayer();
          this.mapService.removeEarthQuakeLayerForFeature();
        }
      }

      if (data.activeToolId === 'recentQuakes') {
        // Get the latest list of earthquakes when the panel opens
        if (this.recentQuakesService.selectedPeriod.value === 7 && this.recentQuakesService.selectedMagnitude.value === 10) {
          this.initialiseRecentQuakes();
        } else {
          this.recentQuakesService.search();
        }
      }
    }));
  }

  // Get the latest list of earthquakes
  initialiseRecentQuakes(): void {
    this.showQuakeDetail = false;
    this.subscriptions.add(this.recentQuakesService.getRecentQuakes()
      .subscribe({
        next: data => this.recentQuakesService.features = data.features,
        error: error => this.recentQuakesService.logError(error),
        complete: () => this.recentQuakesService.showProgressBar = false
      }));
  }

  // Display detailed information and hide the recent earthquakes
  showQuakeDetails(feature: any): void {
    this.showQuakeDetail = true;
    this.selectedQuakeDetail = feature.properties;
    this.sidePanelCommonService.resetRightScroll();
  }

  // Hide the detailed information and show the recent earthquakes
  returnToRecentQuakes(): void {
    this.showQuakeDetail = false;
  }

  // Update the selected period
  updateSelectedPeriod(value: TextValueType): void {
    this.recentQuakesService.selectedPeriod = value;
    this.recentQuakesService.search();
  }

  // Update the selected magnitude
  updateSelectedMagnitude(value: TextValueType): void {
    this.recentQuakesService.selectedMagnitude = value;
    this.recentQuakesService.search();
  }

  // Update the selected region
  updateSelectedRegion(value: string): void {
    this.recentQuakesService.selectedRegion = value;
    this.recentQuakesService.search();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
