export class Glossary {
  readonly azimuth: string = 'In seismology, azimuthal gap refers to the angular distance between the two closest seismic stations that can detect an earthquake. It is defined as the minimum angle between the two lines connecting the earthquake’s epicenter to the two closest seismic stations. The maximum azimuthal gap is not a fixed value. It depends on the seismic network’s geometry and the distance between the earthquake’s epicenter and the seismic stations. The azimuthal gap is used to estimate the accuracy of the earthquake’s location and magnitude. A smaller azimuthal gap indicates a more accurate location and magnitude estimation.';
  readonly damageRadius: string = 'The damage radius is the average distance from the earthquake epicentre to the Modified Mercalli Intensity (MMI) VI isoseismal contour. Intensity values are a measure of the level of ground shaking at a specific location. The isoseismal contours outline areas which experience a similar level of shaking and are based on reports submitted to Geoscience Australia or other agencies. MMI VI is the level that masonry buildings might begin to take some damage depending on its construction characteristics. The severity of shaking experienced locally during an earthquake is affected by factors such as the depth of the earthquake, local geology and soil conditions. Building damage depends on the quality and type of construction, as well as the severity of shaking. The damage radius is approximate only, as it does not account for these factors or the uncertainty in the location of the epicentre. Damage may occur outside the damage radius';
  readonly depth: string = 'The depth (in kilometres) at which the fault hosting the earthquake begins to rupture. Earthquake depth is an estimate because these phenomena cannot be observed directly. Earthquakes are observed remotely through dispersed instruments (seismometers), scattered around the world, recording vibrations in the Earth. Mathematical methods are used to fit signals, recorded by these instruments, to physical models in order to estimate the earthquake’s depth, location (see Longitude and Latitude below), and origin time (see Date below). The depth is the least-constrained and the hardest to estimate parameter of the earthquake.';
  readonly depthUncertainty: string = 'The uncertainty of the depth of the earthquake varies from about 300 metres for the best located events, to 10\'s of kilometres for events in most parts of the world. When depth is poorly constrained by available seismic data, the seismic analyst will set the depth at a fixed value. For example, default depths of 0 or 10 km are often used in Australian areas depending on the local earthquake activity in the area.';
  readonly epicentralTime: string = 'Time of the earthquake in local time at the epicentre.';
  readonly eventId: string = 'A unique identifier that is assigned to each earthquake.';
  readonly feltRadius: string = 'The felt radius is based on the average distance from the epicentre to the Modified Mercalli Intensity (MMI) III isoseismal contour. Intensity values are a measure of the level of ground shaking at a specific location. The isoseismal contours outline areas which experience a given level of shaking or higher and are based on reports submitted to Geoscience Australia or other agencies. The amount of shaking people feel from an earthquake can be affected by factors such as the depth of the earthquake and the local geology. These factors are not taken into consideration in the calculation of the felt radii, so these radii are very approximate and should only be taken as a very rough guide to the area in which people would feel an earthquake.';
  readonly latitude: string = 'The distance North or South from the Equator measured in degrees. Positive latitudes are North, negative are South. Like depth, earthquake latitude is an estimate obtained by fitting observed (recorded) signals to physical models, using a variety of mathematical methods (see under Depth above).';
  readonly longitude: string = 'The distance East or West measured in degrees from the Prime Meridian running through Greenwich, London. Positive longitudes are East of Greenwich, negative longitudes are West (compare latitude. See also GMT). Like depth, earthquake longitude is an estimate obtained by fitting observed (recorded) signals to physical models, using a variety of mathematical methods (see under Depth above).';
  readonly magnitude: string = 'The magnitude is a way of estimating the size of an earthquake from measurements of shaking made on a seismogram. Magnitude scales are logarithmic, so a magnitude 6.0 earthquake releases about 32 times the energy of a magnitude 5.0 earthquake, which in turn releases 32 times the energy of a magnitude 4.0 earthquake. The first magnitude scale developed was the Richter scale, a local magnitude scale for Southern California. Commonly used magnitude scales nowadays include local magnitude scale for appropriate tectonic regions, ML, surface wave magnitude, MS, body wave magnitude, mb, and moment magnitude Mw. These scales all use slightly different underlying principles and methods to estimate the size of an earthquake.';
  readonly mw: string = 'The magnitude calculated from an earthquake\'s total energy (seismic moment). The seismic moment is a function of the amount of slip on a fault, the area of the fault that slips, and the average strength of the rocks that are faulted. Because MW is directly related to the energy released by an earthquake, it is a uniform means of measuring earthquake magnitude and has become the standard measure of earthquake magnitude in modern seismology.';
  readonly mww: string = 'Mww is the moment magnitude derived from a centroid moment tensor inversion of the W-phase.';
  readonly mb: string = 'The magnitude of an earthquake determined by measuring the maximum amplitude of the primary wave (P-wave) on a seismogram of the event.';
  readonly ms: string = 'The magnitude of an earthquake determined from surface waves on a seismogram recorded at distance of more than 20° [~2000 km] from the earthquake. MS magnitudes are measured from surface waves that have a period of about 20 seconds.';
  readonly mwp: string = 'A rapid approximation of moment magnitude derived from broadband P-wave recordings. Tends to underestimate moment magnitude above approximately Mw 6.0.';
  readonly mwmwp: string = 'Mwp corrected to better approximate moment magnitude for magnitudes above Mw 6.0.';
  readonly mb_idc: string = 'Body Wave magnitude, computed per CTBTO/IDC equation but modified to remove noise. mb_IDC(ga) is calculated in the spectral domain with background noise calculated in the 5 seconds prior to P and subtracted from the signal.';
  readonly mla: string = 'In Australia a local Australian magnitude (MLa) is used to represent estimated magnitude of Australian earthquakes, observed on stations within 800 km.  Where possible, Mw is calculated for Australian earthquakes above magnitude MLa 5.0. Attenuation relationships define the rate at which seismic energy decreases with increasing distance from an earthquake. We currently use Wood-Anderson coefficient with a sensitivity of 2080 and damping of 0.7 (Uhrhammer & Collins, 1990).  The attenuation relationships used for MLa cover three discrete regions as described in Gaull & Gregson (1991), Greenhalgh & Singh (1986), and Michael-Leiba & Malafant (1992) for Western Australia, South Australia, and East Australia respectively.';
  readonly originTime: string = 'Also known as the origin time, this is the UTC time at which the earthquake rupture started within the fault plane. The time of the earthquake is usually estimated with a small uncertainty of a few seconds or less.';
  readonly phases: string = 'These indicate the number of phases (different earthquake waves recorded by a seismometer) that were used in determining the earthquake location.';
  readonly rms: string = 'The root-mean-square (RMS) travel time residual. This is displayed in seconds. This parameter provides a measure of the fit of the observed earthquake wave arrival times at various seismic stations, to the theoretical arrival times assuming the location of the earthquake estimated through mathematical inversion process. Smaller numbers reflect a better fit of the observed data to the estimated earthquake location. The value is dependent on the accuracy of the global or local seismic wave velocity model used to compute the earthquake location, the quality weights assigned to the arrival time data, the accuracy with which the arrival times of the seismic waves can be read from the seismogram, and the procedure used to locate the earthquake.';
  readonly solutionPublishVersion: string = 'Version number for the estimated earthquake solution.';
  readonly solutionSource: string = 'Lists authoritative body that provided the solution for the earthquake in question. GA is Geoscience Australia.';
  readonly solutionStatus: string = 'Earthquake solution status indicates the most recent level of review of a given earthquake. See “About”, under “NEAC stages of event revision” for more information about stages of review and publication timelines. Status “Preliminary” indicates the most recent review performed is Initial Review. Status “Confirmed” indicates the most recent review performed is Interim Review. Status “Reviewed” indicates the most recent review performed is the Final Review. Earthquake solutions are typically not altered after having been marked “Reviewed”.';
  readonly solutionUpdated: string = 'Geoscience Australia publishes earthquakes to the website within different time-frames based on their location and magnitude parameters. Some earthquakes will be published within minutes of occurring and their location and magnitude estimates will be improved over the next hour and the next business day, while others might be published later in time (see “About” on the website, under “NEAC stages of event revision”, for more information about stages of review and publication timelines). This line provides the UTC time of the most recent update of the earthquake parameters estimate.';
  readonly stations: string = 'These indicate the number of seismic stations that were used to observe seismic phase (different earthquake waves recorded by a seismometer) arrival times, in order to estimate earthquake location and depth.';
  readonly stationName: string = 'The name of the geographical site that hosts the seismic instrument, commonly this is the closest locality to the location of the instrument.';
  readonly stationArrivalTime: string = 'The UTC time of observed arrival of a particular seismic wave, i.e. a seismic phase (identified under “Phase” below), as observed on a given seismic instrument.';
  readonly stationAmplitude: string = 'The amplitude of a wave used in magnitude calculation, measured on the seismic instrument “Station name”.';
  readonly stationAzimuth: string = 'Azimuth is the angular measurement in a spherical coordinate system which represents the horizontal angle from a cardinal direction, most commonly north. It is used in geophysics (e.g. seismology), navigation, astronomy, mapping etc. It is a direction of a compass bearing. For example, if the azimuth is 90 degrees, then the seismic instrument “Station Name” is due east of the estimated earthquake location.';
  readonly stationCode: string = 'A combination of four to five characters (letters and numbers) that form a unique identifier for a particular seismic instrument.';
  readonly stationDistance: string = 'Distance (in kilometers) of the seismic instrument “Station name” from the estimated earthquake location given by the earthquake’s Latitude and Longitude.';
  readonly stationPeriod: string = 'The wave period of the wave used in magnitude calculation measured on the Amplitude, as observed on the seismic instrument “station name”. ';
  readonly stationPhase: string = 'A particular seismic wave, e.g. P or S, that was observed arriving to the particular seismic instrument  (identified by “Station code”) at time “Arrival Time”.';
  readonly stationUseOfPhase: string = 'The entry indicates whether the observed Phase was used in mathematical processes to estimate the given earthquake location. If the entry reads “Defining”, then the “Phase” observed on seismic instrument “Station name” (“Station Code”) at “Arrival Time” contributed to earthquake location estimate. If the entry reads “Non-Defining”, then the Phase observed on the given seismic instrument was not used for earthquake location estimate.';
  readonly strike: string = 'Trend or bearing of the line marking the intersection of a fault plane (or other planar geologic feature) with a horizontal surface. Strike is always perpendicular (at a right angle) to dip. [Neotectonics database field]';
  readonly time: string = 'Also known as the origin time, this is the time at which the fault causing the earthquake began to rupture. The time of the earthquake is usually computed accurately with a small uncertainty of a few seconds or less.';
}
