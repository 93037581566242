import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { EmergencyInfoComponent } from './emergency.info.component';

@NgModule({
  declarations: [
    EmergencyInfoComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    EmergencyInfoComponent
  ]
})
export class EmergencyInfoModule {
}
