import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HeaderModule } from './header/header.module';
import { MapModule } from './map/map.module';
import { MapService } from './map/map.service';
import { SearchService } from './tools/search/search.service';
import { MainComponent } from './main/main.component';
import { NeotectonicsModule } from './neotectonics/neotectonics.module';
import { FeltGridPopupComponent } from './felt-grid-popup/felt.grid.popup.component';
import { ApplicationStateCommonService, DeviceCommonService, FlyingHellfishCommonModule, GeometryCommonService, SidePanelCommonService } from 'flying-hellfish-common';
import { environment } from '@environment';
import { SidePanelModule } from './side-panel/side.panel.module';
import { AppRoutingModule } from './app-routing.module';
import { ApplicationStateService } from '@shared/services/state/application.state.service';
import { ShakemapPopupComponent } from './shakemap-popup/shakemap.popup.component';
import { HelpModule } from './help/help.module';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    FeltGridPopupComponent,
    ShakemapPopupComponent
  ],
  imports: [
    SharedModule,
    HeaderModule,
    HelpModule,
    MapModule,
    SidePanelModule,
    NeotectonicsModule,
    FlyingHellfishCommonModule.forRoot(environment),
    AppRoutingModule // This comes last because routing can break if router component requirements aren't imported first
  ],
  providers: [
    ApplicationStateService,
    DeviceCommonService,
    GeometryCommonService,
    MapService,
    SearchService,
    SidePanelCommonService,
    { provide: 'Window', useValue: window },
    { provide: ApplicationStateCommonService, useExisting: ApplicationStateService },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
