import { GeocodingProvider } from '@shared/services/location/geocoding/geocoding.provider';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FlyingHellfishGeocodingResultType, GeocodeAddress } from '@shared/services/location/location.types';
import { ENVIRONMENT } from 'flying-hellfish-common';

@Injectable()
export class FlyingHellfishGeocodingProvider extends GeocodingProvider {
  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: any) {
    super();
  }

  /**
   *  Call Flying Hellfish Geocoder api to geocode an address location to longitude and latitude
   *
   * @param location - Address of location
   */
  locationTextToPosition(location: string): Observable<FlyingHellfishGeocodingResultType> {
    return this.http.get<GeocodeAddress>(`${this.environment.feltReport.geocodeAddressUrl}${encodeURIComponent(location)}`).pipe(
      map((response) => {
        if (response.error) {
          throw new Error(response?.error?.message || 'Failed to geocode address');
        }
        const lonLat: number[] = response.candidate.l.split(',').map(parseFloat);

        return {
          candidates: [{
            address: response.candidate.a,
            location: {
              x: lonLat[0],
              y: lonLat[1]
            }
          }]
        };
      }));
  }
}
