import { Observable } from 'rxjs';
import { ReverseGeocodingResultType } from '@shared/services/location/location.types';

export abstract class ReverseGeocodingProvider {
  /**
   * Reverse geocode longitude/latitude coordinates to location.
   *
   * @param longitude - the longitude to reverse
   * @param latitude - the latitude to reverse
   */
  abstract positionToLocationText(longitude: number, latitude: number): Observable<ReverseGeocodingResultType | null>;
}
