<div id="popup" class="ol-popup" #popup>
  <div>
    <a class="ol-popup-closer" (click)="closePopup()"><img src="assets/x-mark-16-white.png" alt="Close" title="Close" height="12" width="12"></a>
  </div>
  <div #popupContent id="popup-content" class="popup-content">
    <div class="popup" style="">{{feature?.feature_name}}</div>
    <table class="table table-striped" aria-label="Feature Metadata">
      <tr class="sr-only">
        <th scope="col">Property</th>
        <th scope="col">Value</th>
      </tr>
      <tbody>
      <tr>
        <td id="typeTitle" class="title">Type:</td>
        <td id="type">{{feature?.feature_type}}</td>
      </tr>
      <tr>
        <td id="lengthTitle" class="title">Length:</td>
        <td id="length">{{feature?.length}} km</td>
      </tr>
      <tr>
        <td id="displacementTitle" class="title">Displacement:</td>
        <td id="displacement">{{feature?.displacement}} m</td>
      </tr>
      </tbody>
    </table>
    <button class="btn btn-ga btn-neotect center-block" (click)="viewFeatureDetails()">View Details</button>
  </div>
</div>
