import { Inject, Injectable } from '@angular/core';
import { ToolConstants } from '../shared/tool-constants';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { ArrayUtilsCommon, BoundsCommon, DeviceCommonService, ENVIRONMENT, MapCommonService, Proj4defsCommonService, ProxifyCommonService, RunClassMethodsOutsideZone } from 'flying-hellfish-common';
import VectorLayer from 'ol/layer/Vector';
import Feature, { FeatureLike } from 'ol/Feature';
import { Circle, Fill, RegularShape, Stroke, Style, Text } from 'ol/style';
import { StyleFunction } from 'ol/style/Style';
import Overlay from 'ol/Overlay';
import { Layer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { boundingExtent, Extent } from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import { Cluster, TileWMS, XYZ } from 'ol/source';
import { transform } from 'ol/proj';
import { Circle as OlCircle, Point, Polygon } from 'ol/geom';
import { fromCircle } from 'ol/geom/Polygon';
import { Coordinate } from 'ol/coordinate';
import { Select } from 'ol/interaction';
import { Pixel } from 'ol/pixel';
import LayerGroup from 'ol/layer/Group';
import TileSource from 'ol/source/Tile';
import { KMZVectorSource } from './kmz';
import { LayerConfigType } from '../types/layer.type';
import { FeatureType } from '../types/feature.type';
import { HttpClient } from '@angular/common/http';

@Injectable()
@RunClassMethodsOutsideZone()
export class MapService extends MapCommonService {
  static feltReportVectorLayer: VectorLayer<VectorSource>;
  static feltReportFeatureCount: number;

  private earthQuakeSummaryWFSUrl: string = this.environment.serviceUrls.earthQuakeSummarySevenDaysWFS;
  private earthQuakeFeltReportsWFSUrl: string = this.environment.serviceUrls.earthQuakeFeltReportsWFS;
  private earthquakesNeotectonicFeaturesWFS: string = this.environment.serviceUrls.earthquakesNeotectonicFeaturesWFS;
  private hideRecentEarthquakesLayer: Map<string, boolean> = new Map<string, boolean>();

  searchVectorLayer: VectorLayer<VectorSource>;
  recentEarthquakesVectorLayer: VectorLayer<VectorSource>;
  feltReportInteraction: Select;
  recentEarthquakesSearchLayer: VectorLayer<VectorSource>;
  stationsVectorLayer: VectorLayer<VectorSource>;
  earthquakeVectorLayer: VectorLayer<VectorSource>;
  stateSearchVectorLayer: VectorLayer<VectorSource>;
  stateSubscriptionVectorLayer: VectorLayer<VectorSource>;
  neotectonicsVectorLayer: VectorLayer<VectorSource>;
  historicEarthquakesLayer: TileLayer<TileSource>;
  tectonicPlateBoundariesLayer: VectorLayer<KMZVectorSource>;
  australiaPolygon: Polygon;

  neotectonicsFeatureEmitter: Subject<{ feature: FeatureLike, pixel: Pixel }> = new Subject();
  onMapZoom: Subject<number> = new Subject<number>();
  identifyFeatureEmitter: ReplaySubject<any> = new ReplaySubject(1);
  identifyEventFeatureEmitter: ReplaySubject<any> = new ReplaySubject(1);
  searchFeatureEmitter: Subject<FeatureLike> = new Subject();
  featureEmitter: Subject<FeatureType> = new Subject();

  constructor(
    @Inject('baseLayerEmitter') baseLayerEmitter: BehaviorSubject<string>,
    @Inject(ENVIRONMENT) environment: any,
    deviceCommonService: DeviceCommonService,
    public proj4DefsService: Proj4defsCommonService,
    public proxifyCommonService: ProxifyCommonService,
    protected http: HttpClient
  ) {
    super(baseLayerEmitter, deviceCommonService, environment, http, proj4DefsService, proxifyCommonService);
  }

  // Return the colour of the feature
  static getEarthquakeColour(hoursSinceQuake: number): string {
    let colour: string = 'rgba(255, 225, 60, 0.8)';

    if (hoursSinceQuake <= 4) {
      colour = 'rgba(255, 0, 0, 0.8)';
    } else if (hoursSinceQuake <= 24) {
      colour = 'rgba(255, 165, 0, 0.8)';
    }

    return colour;
  }

  // Return the radius of the feature
  static getEarthquakeRadius(magnitude: number, resolution: number): number {
    const zoom: number = Math.ceil(Math.log(40075016.68557849 / 256 / resolution) / Math.log(2));
    let radius: number = 0;

    if (magnitude >= 9) {
      radius = 80;
    } else if (magnitude >= 8 && magnitude < 9) {
      radius = 70;
    } else if (magnitude >= 7 && magnitude < 8) {
      radius = 60;
    } else if (magnitude >= 6 && magnitude < 7) {
      radius = 50;
    } else if (magnitude >= 5 && magnitude < 6) {
      radius = 40;
    } else if (magnitude >= 4 && magnitude < 5) {
      radius = 30;
    } else if (magnitude >= 3 && magnitude < 4) {
      radius = 20;
    } else if (magnitude < 3) {
      radius = 10;
    }

    if (zoom >= 5) {
      radius = radius * 0.5;
    } else if (zoom >= 2) {
      radius = radius * 0.4;
    } else if (zoom >= 1) {
      radius = radius * 0.4;
    }

    return radius;
  }

  // Style to display station features
  static getStationStyle(feature: FeatureLike, resolution: number): Style[] {
    const zoom: number = Math.ceil(Math.log(40075016.68557849 / 256 / resolution) / Math.log(2));
    let radius: number = 13;
    let offset: number = 25;
    let fontSize: number = 16;

    if (zoom === 4) {
      radius = 10;
      fontSize = 12;
      offset = 20;
    } else if (zoom === 3) {
      radius = 8;
      fontSize = 10;
      offset = 18;
    }

    const styles: Style[] = [];

    styles.push(new Style({
      image: new RegularShape({
        fill: new Fill({ color: 'blue' }),
        stroke: new Stroke({ color: 'black', width: 2 }),
        points: 3,
        radius: radius,
        angle: 0
      })
    }));

    styles.push(new Style({
      text: new Text({
        text: feature.get('station_code'),
        font: 'bold ' + fontSize + 'px Helvetica Neue,Helvetica,Arial,sans-serif',
        offsetY: offset,
        fill: new Fill({ color: 'rgb(0,0,0)' })
      })
    }));
    return styles;
  }

  // Calculates the radius for cluster
  static calculateClusterInfo(): void {
    MapService.feltReportFeatureCount = 0;
    const features: Feature[] = MapService.feltReportVectorLayer.getSource().getFeatures();

    for (const feature of features) {
      feature.set('radius', 25);
    }
  }

  // Style to display a single felt report
  static getEarthquakeStyle(feature: Feature): Style {
    return new Style({
      geometry: feature.getGeometry(),
      image: new RegularShape({
        radius1: 25,
        radius2: 3,
        points: 5,
        angle: Math.PI,
        fill: new Fill({
          color: 'rgba(0, 255, 255, 0.8)'
        }),
        stroke: new Stroke({
          color: 'rgba(0, 255, 255, 0.2)',
          width: 1
        })
      })
    });
  }

  // Style for the on hover cluster
  static selectStyleFunction(feature: FeatureLike): Style[] {
    const styles: Style[] = [new Style({
      image: new Circle({
        radius: feature.get('radius'),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.01)'
        })
      })
    })];

    const originalFeatures: any = feature.get('features');
    let originalFeature: any;

    for (let i: number = originalFeatures.length - 1; i >= 0; --i) {
      originalFeature = originalFeatures[i];
      styles.push(MapService.getEarthquakeStyle(originalFeature));
    }

    return styles;
  }

  // Create the recent earthquakes layer
  createRecentQuakesLayer(url?: string): void {
    this.mapInstance.removeLayer(this.recentEarthquakesVectorLayer);
    this.recentEarthquakesVectorLayer = new VectorLayer({
      source: this.createRecentQuakesSource(url || this.earthQuakeSummaryWFSUrl),
      visible: true
    });

    // ZIndex has to be greater than the number of available layers
    this.recentEarthquakesVectorLayer.setZIndex(99);
    this.recentEarthquakesVectorLayer.set('id', 'recentQuakes');
    this.mapInstance.addLayer(this.recentEarthquakesVectorLayer);
  }

  // Create the recent earthquakes source, stacks newest events at the top when overlapping
  private createRecentQuakesSource(url: string): VectorSource {
    const source: VectorSource = new VectorSource({
      url,
      format: new GeoJSON()
    });

    source.once('change', () => {
      const features: Feature[] = source.getFeatures();

      features.sort((a, b) => {
        const hoursSinceQuake: (feature: Feature) => any = feature => feature.getProperties().hours_since_quake;

        return hoursSinceQuake(a) - hoursSinceQuake(b);
      });

      features.reverse();
      for (let i: number = 0; i < features.length; i++) {
        features[i].setStyle(this.getFeatureStyle(i));
      }
    });

    return source;
  }

  // Style feature individually and set zIndex
  getFeatureStyle(index: number): StyleFunction {
    return (feature, resolution) => {
      return new Style({
        zIndex: index,
        image: new Circle({ // Set the magnitude to 1 decimal place to match the html filter
          radius: MapService.getEarthquakeRadius(feature.get('preferred_magnitude').toFixed(1), resolution),
          fill: new Fill({
            color: MapService.getEarthquakeColour(feature.get('hours_since_quake'))
          })
        })
      });
    };
  }

  // Return the visibility of the recent quakes layer
  getRecentQuakesLayerVisibility(): boolean {
    return this.recentEarthquakesVectorLayer.getVisible();
  }

  // Show or hide the recent quakes layer
  showRecentEarthquakesLayer(toolId: string, display: boolean): void {
    this.hideRecentEarthquakesLayer.set(toolId, display);
    let show: boolean = true;

    this.hideRecentEarthquakesLayer.forEach((value => {
      if (!value) {
        show = false;
      }
    }));

    this.setRecentEarthquakesLayerVisibility(show);
  }

  // Set visibility of the recent quakes layer
  setRecentEarthquakesLayerVisibility(visible: boolean): void {
    this.mapInstance.getLayers().forEach((layer) => {
      if (layer === this.recentEarthquakesVectorLayer) {
        layer.setVisible(visible);
      }
    });
  }

  // Reset recent quakes layer to default state
  resetRecentEarthquakesLayer(): void {
    this.hideRecentEarthquakesLayer.clear();
    this.setRecentEarthquakesLayerVisibility(true);
  }

  // Displays the features on the map
  addSearchFeatureLayer(features: any): void {
    // Create the vector layer
    this.searchVectorLayer = new VectorLayer({
      source: new VectorSource({
        features: this.getTransformedCoordinatesFromFeatures(features)
      }),
      style: this.getEarthquakeStyle
    });

    this.searchVectorLayer.set('id', 'search');
    // Add the vector layer to the map
    this.mapInstance.addLayer(this.searchVectorLayer);
  }

  // Remove the search features from the map
  removeSearchFeatureLayer(): void {
    this.mapInstance.removeLayer(this.searchVectorLayer);
  }

  // Zoom to the extent containing all the features with an offset removed from the extent i.e. for panels
  zoomToFeaturesWithPadding(features: any, duration: number = 1000, padding: number[] = undefined): void {
    const bounds: BoundsCommon = this.getBoundariesForFeatures(this.getTransformedCoordinatesFromFeatures(features));
    this.zoomToBoundsWithPadding(bounds, duration, padding);
  }

  // Zoom to the extent containing all the features
  zoomToExtent(features: any): void {
    const bounds: BoundsCommon = this.getBoundariesForFeatures(this.getTransformedCoordinatesFromFeatures(features));
    const extent: Extent = boundingExtent([[bounds.minLongitude, bounds.minLatitude], [bounds.maxLongitude, bounds.maxLatitude]]);
    this.mapInstance.getView().fit(extent, {
      duration: 1000
    });
  }

  // Zoom to the extent containing all the features with an offset removed from the extent i.e. for panels
  zoomToExtentWithOffset(features: any, offset: number, maxZoom?: number): void {
    const bounds: BoundsCommon = this.getBoundariesForFeatures(this.getTransformedCoordinatesFromFeatures(features));
    const pixels: Pixel = this.mapInstance.getPixelFromCoordinate([bounds.maxLongitude, bounds.maxLatitude]);
    const offsetBounds: Coordinate = this.mapInstance.getCoordinateFromPixel([(pixels[0] + offset), pixels[1]]);

    let viewProperties: {} = {};
    if (maxZoom) {
      viewProperties = { duration: 1000, maxZoom: maxZoom };
    } else {
      viewProperties = { duration: 1000 };
    }

    const extent: Extent = boundingExtent([[bounds.minLongitude, bounds.minLatitude], [offsetBounds[0], offsetBounds[1]]]);
    this.mapInstance.getView().fit(extent, viewProperties);
  }

  // Zoom to the extent containing all the coordinates with an offset removed from the extent i.e. for panels
  zoomToExtentWithOffsetForCoordinates(coordinates: any, offset: number, duration: number = 1000, maxZoom?: number): void {
    const bounds: BoundsCommon = this.getBoundariesForCoordinates(coordinates);
    const pixels: Pixel = this.mapInstance.getPixelFromCoordinate([bounds.maxLongitude, bounds.maxLatitude]);
    const offsetBounds: Coordinate = this.mapInstance.getCoordinateFromPixel([(pixels[0] + offset), pixels[1]]);

    let viewProperties: {} = {};
    if (maxZoom) {
      viewProperties = { duration: duration, maxZoom: maxZoom };
    } else {
      viewProperties = { duration: duration };
    }

    const extent: Extent = boundingExtent([[bounds.minLongitude, bounds.minLatitude], [offsetBounds[0], offsetBounds[1]]]);
    this.mapInstance.getView().fit(extent, viewProperties);
  }

  // Zoom to the extent of the Bounds object with an offset removed from the extent i.e. for panels
  zoomToExtentWithOffsetForBounds(bounds: BoundsCommon, offset: number, duration: number = 1000, maxZoom?: number): void {
    const pixels: Pixel = this.mapInstance.getPixelFromCoordinate([bounds.maxLongitude, bounds.maxLatitude]);
    const offsetBounds: Coordinate = this.mapInstance.getCoordinateFromPixel([(pixels[0] + offset), pixels[1]]);

    let viewProperties: {} = {};
    if (maxZoom) {
      viewProperties = { duration: duration, maxZoom: maxZoom };
    } else {
      viewProperties = { duration: duration };
    }

    const extent: Extent = boundingExtent([[bounds.minLongitude, bounds.minLatitude], [offsetBounds[0], offsetBounds[1]]]);
    this.mapInstance.getView().fit(extent, viewProperties);
  }

  // Add recent quakes search layer which contains a circle on the map with the provided radius
  addRecentEarthquakesSearchLayer(latitude: number, longitude: number, radius: number): Coordinate[] {
    this.removeRecentEarthquakesSearchLayer();
    const calculatedRadius: number = radius * 1000;
    const circle: OlCircle = new OlCircle(transform([longitude, latitude], this.environment.displayProjection, this.environment.datumProjection), calculatedRadius);
    const polygon: Polygon = fromCircle(circle, 100);

    const feature: Feature = new Feature({
      geometry: circle
    });

    this.recentEarthquakesSearchLayer = new VectorLayer({
      source: new VectorSource({
        features: [feature]
      }),
      style: MapService.getDrawStyle(0.75)
    });

    this.mapInstance.addLayer(this.recentEarthquakesSearchLayer);

    const coordinates3857: Coordinate[] = polygon.getCoordinates()[0];
    const coordinates4326: Coordinate[] = [];

    for (const coordinate of coordinates3857) {
      coordinates4326.push(transform(coordinate, this.environment.datumProjection, this.environment.displayProjection));
    }

    return coordinates4326;
  }

  // Remove the recent quakes search layer from the map
  removeRecentEarthquakesSearchLayer(): void {
    this.mapInstance.removeLayer(this.recentEarthquakesSearchLayer);
  }

  // Remove the draw interaction layer from the map
  removeDrawExtentLayer(tool: string): void {
    if (this.interactions.has(tool)) {
      this.mapInstance.removeLayer(this.interactions.get(tool).layer);
      this.interactions.delete(tool);
    }
  }

  // Extract all the coordinates from the features, convert them from EPSG 4326 to 3857 and place them into an array
  getTransformedCoordinatesFromFeatures(features: any[]): Feature[] {
    return features
      .filter(feature => feature.hasOwnProperty('geometry'))
      .map(feature => {
        const newFeature: Feature = new Feature(new Point(transform([feature.geometry.coordinates[0], feature.geometry.coordinates[1]], this.environment.displayProjection, this.environment.datumProjection)));
        newFeature.setProperties({ preferred_magnitude: feature.properties.preferred_magnitude });
        newFeature.setProperties({ hours_since_quake: feature.properties.hours_since_quake });
        newFeature.setProperties({ earthquake_id: feature.properties.earthquake_id });
        return newFeature;
      });
  }

  // Get the max/min latitudes and longitudes
  getBoundariesForFeatures(coordinates: Feature[]): BoundsCommon {
    const latitudes: number[] = [];
    const longitudes: number[] = [];

    for (const coordinate of coordinates) {
      const extent: Extent = coordinate.getGeometry().getExtent();
      longitudes.push(extent[0]);
      latitudes.push(extent[1]);
    }

    // Calculate the bounding extent
    const bounds: BoundsCommon = new BoundsCommon();
    const [minLongitude, maxLongitude]: [number, number] = ArrayUtilsCommon.getMinMax(longitudes);
    const [minLatitude, maxLatitude]: [number, number] = ArrayUtilsCommon.getMinMax(latitudes);
    bounds.maxLongitude = maxLongitude;
    bounds.minLongitude = minLongitude;
    bounds.maxLatitude = maxLatitude;
    bounds.minLatitude = minLatitude;

    return bounds;
  }

  // Get the current visible base map to display in the overview
  getOverviewLayers(): Layer[] {
    let overviewLayers: Layer[] = [];

    // Set the layers for the Overview map
    for (const layer of this.mapLayers) {
      if (layer.isBaseLayer && layer.visible) {
        overviewLayers = [
          new TileLayer({
            source: new XYZ({
              url: layer.url
            })
          })
        ];
      }
    }

    return overviewLayers;
  }

  // Return the earthquake style for the feature
  getEarthquakeStyle(feature: FeatureLike, resolution: number): Style {
    return new Style({
      image: new Circle({ // Set the magnitude to 1 decimal place to match the html filter
        radius: MapService.getEarthquakeRadius(feature.get('preferred_magnitude').toFixed(1), resolution),
        fill: new Fill({
          color: MapService.getEarthquakeColour(feature.get('hours_since_quake'))
        })
      })
    });
  }

  // Return red <= 4 hours, orange <= 24 hours and yellow > 24 hours
  getEarthquakeColour(hoursSinceQuake: number): string {
    return MapService.getEarthquakeColour(hoursSinceQuake);
  }

  // Reset the map to the the initial position, zoom, and optionally duration
  setInitialMapPositionAnimated(zoomLevel: number, duration: number = 1000): void {
    this.mapInstance.getView().animate({
      center: transform([this.environment.initialMapLongitude, this.environment.initialMapLatitude],
        this.environment.displayProjection, this.environment.datumProjection),
      duration: duration,
      zoom: zoomLevel
    });
  }

  // Reset the map with animation to the the initial position, zoom, and optionally duration
  setMapPositionAnimated(latitude: number, longitude: number, zoomLevel: number, duration: number = 1000): void {
    if (zoomLevel) {
      this.mapInstance.getView().animate({
        center: transform([longitude, latitude],
          this.environment.displayProjection, this.environment.datumProjection),
        duration: duration,
        zoom: zoomLevel
      });
    } else {
      this.mapInstance.getView().animate({
        center: transform([longitude, latitude],
          this.environment.displayProjection, this.environment.datumProjection),
        duration: 1500
      });
    }
  }

  // Create the felt report layer and add an interaction for on hover over the clusters
  createFeltReportLayer(hoursSinceReport: number, opacity: number = 1, zindex: number = 99, callback?: (loaded: boolean) => void): VectorLayer<VectorSource> {
    this.removeFeltReportLayer();
    MapService.feltReportVectorLayer = new VectorLayer({
      source: new Cluster({
        distance: 40,
        source: new VectorSource({
          url: this.earthQuakeFeltReportsWFSUrl + '&CQL_FILTER=hours_since_report<=' + hoursSinceReport,
          format: new GeoJSON()
        })
      }),
      style: this.getFeltReportStyle,
      visible: true
    });

    this.mapInstance.addLayer(MapService.feltReportVectorLayer);
    MapService.feltReportVectorLayer.setZIndex(zindex);
    MapService.feltReportVectorLayer.setOpacity(opacity);
    MapService.feltReportVectorLayer.set('id', 'felt');

    // Attach a listener to the source so we know when the layer is ready
    const feltLayer: VectorLayer<VectorSource> = MapService.feltReportVectorLayer;
    // eslint-disable-next-line
    MapService.feltReportVectorLayer.getSource().once('change', function(e) {
      if (feltLayer.getSource().getState() === 'ready') {
        if (callback) {
          callback(true);
        }
      }
    });

    this.feltReportInteraction = new Select({
      layers: function(layer: Layer): boolean {
        if (layer.getProperties().id === 'felt') {
          return true;
        }
      },
      condition: function(evt: any): boolean {
        return evt.type === 'pointermove' ||
          evt.type === 'singleclick';
      },
      style: MapService.selectStyleFunction
    });

    this.mapInstance.addInteraction(this.feltReportInteraction);

    return MapService.feltReportVectorLayer;
  }

  // Remove the felt report layer from the map
  removeFeltReportLayer(): void {
    if (MapService.feltReportVectorLayer) {
      this.mapInstance.removeLayer(MapService.feltReportVectorLayer);
      this.mapInstance.removeInteraction(this.feltReportInteraction);
    }
  }

  // Sets up styles for the layer
  getFeltReportStyle(feature: FeatureLike): Style {
    MapService.calculateClusterInfo();

    let style: Style;
    const size: any = feature.get('features').length;

    if (size > 1) {
      style = new Style({
        image: new Circle({
          radius: feature.get('radius'),
          fill: new Fill({
            color: [0, 255, 255, Math.min(0.8, 0.4 + (size / MapService.feltReportFeatureCount))]
          })
        }),
        text: new Text({
          text: size.toString(),
          fill: new Fill({
            color: '#fff'
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.6)',
            width: 3
          })
        })
      });
    } else {
      const originalFeature: any = feature.get('features')[0];
      style = MapService.getEarthquakeStyle(originalFeature);
    }

    return style;
  }

  // Create the stations layer
  createStationsLayer(url: string): void {
    this.stationsVectorLayer = new VectorLayer({
      source: new VectorSource({
        url: url,
        format: new GeoJSON()
      }),
      style: MapService.getStationStyle,
      visible: true
    });

    this.mapInstance.addLayer(this.stationsVectorLayer);
    this.stationsVectorLayer.setZIndex(99);
  }

  // Remove the stations layer from the map
  removeStationsLayer(): void {
    this.mapInstance.removeLayer(this.stationsVectorLayer);
  }

  // Create a single earthquake point on the map given a feature
  createEarthQuakeLayerForFeature(feature: any): void {
    const coordinates: Feature[] = [];

    // Create a new feature with max magnitude and time property
    const newFeature: Feature = new Feature(new Point(transform([feature.longitude, feature.latitude], this.environment.displayProjection, this.environment.datumProjection)));
    newFeature.setProperties({ preferred_magnitude: feature.preferred_magnitude });
    newFeature.setProperties({ hours_since_quake: feature.hours_since_quake });
    coordinates.push(newFeature);

    this.earthquakeVectorLayer = new VectorLayer({
      source: new VectorSource({
        features: coordinates
      }),
      style: this.getEarthquakeStyle,
      visible: true
    });

    this.mapInstance.addLayer(this.earthquakeVectorLayer);
  }

  // Remove the single earthquake feature from the map
  removeEarthQuakeLayerForFeature(): void {
    this.mapInstance.removeLayer(this.earthquakeVectorLayer);
  }

  // Add recent quakes search layer which contains a circle on the map with the provided radius
  addStateLayer(bounds: string, tool: string): any {
    this.removeStateLayer(tool);

    const coordinates: string[] = bounds.split(',');
    const polygon: Coordinate[] = [];

    for (const coordinate of coordinates) {
      polygon.push(transform(coordinate.split(' ').map(Number).slice(0, 2), this.environment.displayProjection, this.environment.datumProjection));
    }

    const feature: Feature = new Feature({
      geometry: new Polygon([polygon])
    });

    if (tool === ToolConstants.SEARCH) {
      this.stateSearchVectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [feature]
        }),
        style: MapService.getDrawStyle(0.75)
      });

      this.mapInstance.addLayer(this.stateSearchVectorLayer);
    } else if (tool === ToolConstants.SUBSCRIPTION) {
      this.stateSubscriptionVectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [feature],
        }),
        style: MapService.getDrawStyle(0.75)
      });

      this.mapInstance.addLayer(this.stateSubscriptionVectorLayer);
    }

    return polygon;
  }

  // Remove the state layer from the map
  removeStateLayer(tool: string): void {
    if (tool === ToolConstants.SEARCH) {
      this.mapInstance.removeLayer(this.stateSearchVectorLayer);
    } else if (tool === ToolConstants.SUBSCRIPTION) {
      this.mapInstance.removeLayer(this.stateSubscriptionVectorLayer);
    }
  }

  // Animate zoom to a zoom level
  setZoomLevel(zoomLevel: number): void {
    this.mapInstance.getView().animate({ zoom: zoomLevel });
  }

  // Animate zoom to a lon lat and zoom level
  zoomToLocation(longitude: number, latitude: number, zoomLevel: number): void {
    this.mapInstance.getView().animate({
      center: transform([Number(longitude), Number(latitude)], this.environment.displayProjection, this.environment.datumProjection),
      zoom: zoomLevel
    });
  }

  // Create a Polygon object with Australia's coordinates
  setAustraliaPolygon(): void {
    const coordinates: string[] = this.environment.boundaries.Australia.split(',');
    const polygon: any[] = [];

    for (const coordinate of coordinates) {
      polygon.push(transform(coordinate.split(' ').map(Number).slice(0, 2), this.environment.datumProjection, this.environment.datumProjection));
    }

    this.australiaPolygon = new Polygon([polygon]);
  }

  // Check if coordinate is located in Australia
  isCoordinateInAustralia(longitude: number, latitude: number): boolean {
    return this.australiaPolygon.intersectsCoordinate([Number(longitude), Number(latitude)]);
  }

  // Add layer to the map
  addLayerToMap(layer: Layer | LayerGroup): void {
    this.mapInstance.addLayer(layer);
  }

  // Remove layer from the map
  removeLayer(layer: Layer | LayerGroup): void {
    this.mapInstance.removeLayer(layer);
  }

  // Add overlay to the map
  addOverlay(overlay: Overlay): void {
    this.mapInstance.addOverlay(overlay);
  }

  // Remove overlay from the map
  removeOverlay(overlay: Overlay): void {
    this.mapInstance.removeOverlay(overlay);
  }

  getCoordinateFromPixel(pixel: any): Coordinate {
    return this.mapInstance.getCoordinateFromPixel(pixel);
  }

  // Create the neotectonics layer from the map
  createNeotectonicsLayer(opacity: number, zindex: number, callback?: (loaded: boolean) => void): VectorLayer<VectorSource> {
    this.mapInstance.removeLayer(this.neotectonicsVectorLayer);
    this.neotectonicsVectorLayer = new VectorLayer({
      source: new VectorSource({
        url: this.earthquakesNeotectonicFeaturesWFS,
        format: new GeoJSON()
      }),
      style: new Style({
        fill: new Fill({ color: '#FF0000' }),
        stroke: new Stroke({ color: '#FF0000', width: 2 })
      }),
      visible: true
    });

    this.neotectonicsVectorLayer.set('id', 'neotectonics');
    this.neotectonicsVectorLayer.setOpacity(opacity);
    this.neotectonicsVectorLayer.setZIndex(zindex);
    // Attach a listener to the layer so we know when the layer is ready
    this.neotectonicsVectorLayer.once('postrender', () => {
      callback(true);
    });
    this.mapInstance.addLayer(this.neotectonicsVectorLayer);

    return this.neotectonicsVectorLayer;
  }

  // Remove the neotectonics layer from the map
  removeNeotectonicsLayer(): void {
    this.mapInstance.removeLayer(this.neotectonicsVectorLayer);
    this.neotectonicsFeatureEmitter.next(null);
  }

  // Change the opacity of the neotectonics layer
  changeNeotectonicsLayerOpacity(opacity: number): void {
    this.neotectonicsVectorLayer.setOpacity(opacity);
  }

  // Create the historic earthquakes layer and add it to the map
  createHistoricEarthquakesLayer(opacity: number, zindex: number, url: string, callback?: (loaded: boolean) => void): TileLayer<TileSource> {
    this.mapInstance.removeLayer(this.historicEarthquakesLayer);
    this.historicEarthquakesLayer = new TileLayer({
      source: new TileWMS({
        url: url,
        params: { LAYERS: 'earthquakes:earthquakes_ten_years', TILED: true },
        serverType: 'geoserver',
        wrapX: true
      })
    });

    // Called once after the map has finished loading tiles
    this.mapInstance.once('loadend', () => {
      callback(true);
    });

    this.historicEarthquakesLayer.setOpacity(opacity);
    this.historicEarthquakesLayer.setZIndex(zindex);
    this.mapInstance.addLayer(this.historicEarthquakesLayer);

    return this.historicEarthquakesLayer;
  }

  // Remove the historic earthquakes layer from the map
  removeHistoricEarthquakesLayer(): void {
    this.mapInstance.removeLayer(this.historicEarthquakesLayer);
  }

  // Creates the Tectonic Plate Boundaries layer
  createTectonicPlateBoundariesLayer(layerConfig: LayerConfigType, callback?: (loaded: boolean) => void): VectorLayer<VectorSource> {
    if (this.tectonicPlateBoundariesLayer) {
      this.mapInstance.removeLayer(this.tectonicPlateBoundariesLayer);
    } else {
      this.tectonicPlateBoundariesLayer = new VectorLayer({
        source: new KMZVectorSource({
          url: layerConfig.url
        })
      });
    }

    // Called once after the map has finished loading tiles
    this.mapInstance.once('loadend', () => {
      callback(true);
    });

    this.tectonicPlateBoundariesLayer.setOpacity(layerConfig.opacity / 100);
    this.tectonicPlateBoundariesLayer.setZIndex(layerConfig.zindex);
    this.mapInstance.addLayer(this.tectonicPlateBoundariesLayer);

    return this.tectonicPlateBoundariesLayer;
  }

  // Removes the Tectonic Plate Boundaries layer
  removeTectonicPlateBoundariesLayer(): void {
    this.mapInstance.removeLayer(this.tectonicPlateBoundariesLayer);
  }

  // Change the opacity of the neotectonics layer
  changeHistoricEarthquakesLayerOpacity(opacity: number): void {
    this.historicEarthquakesLayer.setOpacity(opacity);
  }

  get onMapZoom$(): Observable<number> {
    return this.onMapZoom.asObservable();
  }
}
