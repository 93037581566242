<div class="heading">
  <div>
    <h4 class="tool-title">Earthquake Details
      <ga-help-link [linkFragment]="'earthquake-details'"></ga-help-link>
    </h4>
  </div>
  <div *ngIf="showFeature">
    <ga-toolbar-share-event [feature]="feature"></ga-toolbar-share-event>
  </div>
  <div *ngIf="showFeature">
    <ga-toolbar-felt-quake [feature]="feature"></ga-toolbar-felt-quake>
  </div>
</div>
<div>
  <p *ngIf="!deviceCommonService.isMobile() && !showFeature"><em>Click on an Earthquake on the map</em></p>
  <p *ngIf="deviceCommonService.isMobile() && !showFeature"><em>Touch on an Earthquake on the map</em></p>
  <hr style="margin-top: 0">
  <div *ngIf="showFeature">
    <ga-quake-details [feature]="feature"></ga-quake-details>
  </div>
</div>
